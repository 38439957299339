// import { Button } from '@mui/material'
// import React from 'react'
// import { LandingPage_MainTitleText_FontColor } from '../Provider/Color_Provider'
// import VideoPreview from "../assets/ModalX_Video_Preview.mp4";
// import LandingPage_Logos from "../assets/LandingPage_Logos.png";
// import { selected_chat_bg_color, selected_chat_bg_color_dm } from '../constants/colors';
// import ModalX_Advantages from './LandingPage/ModalX_Advantages';
// import HowModalX_Works from './LandingPage/HowModalX_Works';
// import Features from './LandingPage/Features';
// import ModalXInAction from './LandingPage/ModalXInAction';
// import Testimonials from './LandingPage/Testimonials';
// import CaseStudies from './LandingPage/CaseStudies';

// const LandingPageContents = () => {
//     const theme = localStorage.getItem('theme')
//   return (
//     <div>
//         <div style={{maxWidth:'',fontSize:'80px',textAlign:'center',color:theme === 'dark'?'#F6BE6B':'',marginTop:'166px'}}>
//             <p style={{}}>
//                 Business Growth,
//             </p>
//             <p >
//                 Without the Headaches
//             </p>
//         </div>
//         <p style={{margin:'auto',textAlign:'center',fontSize:'18px',color:'#FFFFFF'}}>Your AI-powered Growth Engine, Made Simple</p>
//         <div style={{textAlign:'center',width:'370px',display:'flex',justifyContent:'space-between',margin:'auto',marginTop:'30px'}}>
//             <Button style={{width:'161px',height:'40px',border:`1px solid #F6BE6A`,color:'#F6BE6A'}} sx={{textTransform:'none'}}>Request demo</Button>
//             <Button style={{width:'173px',height:'40px',backgroundColor:'#F6BE6A',color:'#17182C',fontWeight:'600'}} sx={{textTransform:'none'}}>Get started free</Button>
//         </div>
//         <div style={{maxWidth:'1200px',maxHeight:'675px',margin:'120px auto 0 auto',borderRadius:'10px',overflow:'hidden'}}>
//             <video
//                 id="introVideo"
//                 width="100%"
//                 controls
//                 autoPlay
//             >
//                 <source src={VideoPreview} type="video/mp4" />
//                 Your browser does not support the video tag.
//             </video>
//         </div>
//         <div style={{height:'210px',margin:'120px auto',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',backgroundColor:'#1d213c'}}>
//             <p style={{textAlign:'center',fontSize:'20px'}}>Be among the first to experience our innovative solutions designed to drive success and creativity</p>
//             <div style={{overflow:'hidden'}}>
//                 <img src={LandingPage_Logos} style={{overflow:'hidden'}}/>
//             </div>
//         </div>
//         <ModalX_Advantages />
//         <HowModalX_Works />
//         <Features />
//         <ModalXInAction />
//         <Testimonials />
//         <CaseStudies />
//     </div>
//   )
// }

// export default LandingPageContents

import React ,{useState,useEffect}from "react";
import { Box, Button, Typography,useMediaQuery } from "@mui/material";
// import VideoPreview from "../assets/ModalX_Video_Preview.mp4";
// import LandingPage_Video from "../assets/ModalX_landingPage_Video.mp4";
import LandingPage_Video from "../assets/UpdatedModalxLandingPageVideo.mp4";
import LandingPage_Logos from "../assets/LandingPage_Logos.png";
import ModalX_Advantages from "./LandingPage/ModalX_Advantages";
import Features from "./LandingPage/Features";
import ModalXInAction from "./LandingPage/ModalXInAction";
import Testimonials from "./LandingPage/Testimonials";
import CaseStudies from "./LandingPage/CaseStudies";
import Capabilities from "./LandingPage/Capabilities";
import RequestDemoNearFooter from "./LandingPage/RequestDemoNearFooter";
import HowItWorks from "./LandingPage/HowItWorks";
import Logo_1 from "../assets/LandingPage_Items/Logo_1.png";
import Logo_2 from "../assets/LandingPage_Items/Logo_2.png";
import Logo_3 from "../assets/LandingPage_Items/Logo_3.png";
import Logo_4 from "../assets/LandingPage_Items/Logo_4.png";
import Logo_5 from "../assets/LandingPage_Items/Logo_5.png";
import Logo_6 from "../assets/LandingPage_Items/Logo_6.png";
import Logo_7 from "../assets/LandingPage_Items/Logo_7.png";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { ButtonClick  } from "../config/analytics";
import  Landingpage_HeroImage  from "../assets/Landingpage_HeroImage.png";
import videoThumbnail from "../assets/LandingPage_Items/videoThumnail/VideoThumbnail.png";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const LandingPageContents = () => {
  const theme = localStorage.getItem("theme");
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const isBelow750 = useMediaQuery("(max-width:991px)");
  const logos = [Logo_1, Logo_2, Logo_3, Logo_4, Logo_5, Logo_6, Logo_7];
  
  const handleRedirect = (link) => {
    window.open(link, '_blank'); // Opens the link in a new tab
  };
  const {setShowSignUpModal,showSignUpModal,showLoginModal, setMenuOpen} =useAuth()
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const navigate = useNavigate();
  const handleGetStarted = () =>{

    // if (isLoggedIn){
    //   navigate('/workspace') 
    // }
    // else {
    //   setMenuOpen(true)
    //   setShowSignUpModal(true) 

    // }
    navigate('/workspace');   
  }

  
  return (
    <Box sx={{}}>
      <Box
        sx={{
          fontSize: "80px",
          textAlign: isBelow750?'center':"start",
          color: theme === "dark" ? "#F6BE6B" : "",
          marginTop: isBelow991?'73px':"90px",
          background:
            "linear-gradient(90deg, #F6BE6B 0%, rgba(246, 190, 107, 0.60) 100%)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text", 
          WebkitTextFillColor: "transparent",
          marginLeft:isBelow750?'':'134px',
        }}
      >
        <Typography variant="h1" component="p" sx={{fontFamily:"Inter",fontSize:isBelow991?'33px':'80px', fontStyle:"normal", fontWeight:500,  lineHeight:"100%", letterSpacing:"-2.4px"  }}>
          {/* Business Growth, */}
          World’s Most Capable AI
        </Typography>
        {/* <Typography variant="h1" component="p" sx={{fontFamily:"Inter",fontSize:isBelow991?'33px':'64px', fontStyle:"normal", fontWeight:500,  lineHeight:"100%", letterSpacing:"-2.4px"  }}>
          Generative & AI Assistant Platform
        </Typography> */}
      </Box>
      <Typography
        sx={{
          fontFamily:"Inter",
          margin: "27px auto",
          textAlign: isBelow750?'center':"start",
          fontSize: isBelow991?'14px':"18px",
          color: "rgba(255,255,255,0.8)",
          padding:isBelow991? "0px 10px":"0px",
          marginLeft:isBelow750?'':'134px',
          fontWeight:500,
          letterSpacing:"0.1px",
          opacity:0.8
        }}
      >
        {/* All the best AI tools in one place to make your business grow faster */}
        Simplified, Fast and affordable, with unlimited tasks. Powered by best-in-class AI tools.
      </Typography>
      <Box
        sx={{
          textAlign: "start",
          width: isBelow750? isLoggedIn ? "297px" : '235px' : isLoggedIn ? "370px" : "296px",
          display: "flex",
          justifyContent: "space-between",
          margin: isBelow750 ? "auto" : undefined,
          marginTop: "30px",
          marginLeft:isBelow750 ? undefined : '134px',
        }}
      >
        <Button  
          // onClick={() => handleRedirect('mailto:noreply@ModalX.ai')}
          onClick={() =>{  ButtonClick("Request Demo Button Click", "Request Demo");
          window.open('https://calendly.com/makarand-modalx', '_blank')}}
          variant="outlined"
          sx={{
            width: isBelow750?'119px':"145px",
            height: "40px",
            borderColor: "#F6BE6A",
            color: "#F6BE6A",
            textTransform: "none",
            fontSize: isBelow991?'14px':"16px",
            "&:hover": {
              borderColor: "#F6BE6A",
              backgroundColor: "transparent", // No border color changes on hover
            },
            fontFamily: "Inter",
            fontStyle:"normal",
            fontWeight:600,
            lineHeight:'20px',
            letterSpacing:"0.1px",
            padding: isBelow750 ? "0px" : "10px",
            borderRadius:"8px",
            flexWrap:"nowrap",
          }}
        >
          Request demo
        </Button>

        <Button  
        onClick={handleGetStarted}
       
          variant="contained"
          sx={{
            width: isBelow750? isLoggedIn? "162px" :'101px' : "190",
            height: "40px",
            backgroundColor: "#F6BE6A",
            color: "#17182C",
            fontWeight: "600",
            textTransform: "none",
            fontSize: isBelow991?'14px':"16px",
            "&:hover": {
              backgroundColor: "#ffd9aa", // Changes background color on hover
            },
            fontFamily: "Inter, sans-serif",
            borderRadius:"8px"
          }}
        >
          <div style={{display:"flex", flexDirection:"row", flexWrap:"nowrap", flexShrink:0, textAlign:"center", alignItems:"center" }}>
          {isLoggedIn? "Go to workspace" : "Try now"}
           <ArrowForwardIcon width={"18px"} height={"18px"} sx={{marginLeft:"8px", width:"18px", height:"18px"}}/>
           </div>
        </Button>
      </Box>
      <Box sx={{marginTop:isBelow991?'':'-23%'}}>
          <img src={Landingpage_HeroImage} style={{width:'100%',objectFit: 'cover'}}/>
      </Box>
      <Box
        sx={{
          height: "210px",
          margin: isBelow991?'0px auto':"0px auto",
          display: "flex",
          flexDirection: "column",
          // justifyContent: 'center',
          // alignItems: 'center',
          backgroundColor: "#171A2D",
          // bgcolor : 'blue'
        }}
      >
        <Box
          sx={{
            width: "100%",
            // height: "26px",
            marginTop: "60px",
            padding:"0px 20px",
            marginBottom: "40px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: 400,
              lineHeight: "26px",
              textAlign: "center",
              fontSize: isBelow991?'14px':"20px",
              color: "#FFFFFF",
              opacity: "80%",
            }}
          >
            Be among the first to experience our innovative solutions designed
            to drive success and creativity
          </Typography>
        </Box>

        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            width: "85%",
            height: "24px", // Adjust height as needed
            bgcolor: "#171A2D", // Background color for seamless integration
            margin:'0 auto',
          }}
        >
          {/* Blurred edges */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "10%", // Padding width
              height: "100%",
              zIndex: 1,
              background: "linear-gradient(to right, #171A2D, rgba(23, 26, 45, 0))",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "10%", // Padding width
              height: "100%",
              zIndex: 1,
              background: "linear-gradient(to left, #171A2D, rgba(23, 26, 45, 0))",
            }}
          />

          {/* Scrolling logos */}
          <Box
            sx={{
              display: "flex",
              padding: "0 10%", // Match padding with blurred edges
              whiteSpace: "nowrap",
              columnGap:"72px",
              animation: `scrollLogos ${logos.length * 1.5}s linear infinite`,
              "@keyframes scrollLogos": {
                "0%": { transform: "translateX(0)" },
                "100%": { transform: `translateX(-${logos.length * 72}px)` }, // Ensure seamless loop
              },
            }}
          >
            {/* Logos displayed twice for continuous looping */}
            {logos.concat(logos).map((logo, index) => (
              <Box
                key={index}
                component="img"
                src={logo}
                alt={`Logo ${index + 1}`}
                sx={{
                  height: "24px", // Adjust logo size
                  margin: "0px", // Spacing between logos
                  display: "inline-block",
                }}
              />
            ))}
          </Box>
        </Box>


      </Box>
      <Box sx={{marginTop: "80px"}}>
        {/* <p style={{ textAlign: "center", color: "#F6BE6B" }}>ModalX in Action</p> */}
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            textAlign: "center",
            color: "#F6BE6B",
            "@media (max-width: 991px)": {
              fontSize: "12px",
            },
          }}
        >
          ModalX in Action
        </Typography>
        <div
          style={{
            maxWidth: "1072px",
            margin: "auto",
            textAlign: "center",
            marginTop: "48px",
          }}
        >
          {/* <p style={{ fontSize: "48px", marginTop: "80px" }}>
            The AI toolkit for every business need
          </p> */}

          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "48px",
              fontWeight: 500,
              lineHeight: "57.6px",
              textAlign: "center",
              letterSpacing: "-0.32",
              color: "#FFFFFF",
              opacity: "80%",
              "@media (max-width: 991px)": {
                fontFamily: "Inter",
                fontSize: "28px",
                fontWeight: 500,
                lineHeight: "33.6px",
                textAlign: "center",
                letterSpacing: "-0.32",
                color: "#FFFFFF",
                opacity: "80%",
                minWidth: "358px",
                margin: "auto",
                padding : '0px 10px 0px 10px'
              },
            }}
          >
            From Idea to Output in Seconds
          </Typography>
        </div>
        <Box
          sx={{
            maxWidth: "1200px",
            maxHeight: "675px",
            margin: isBelow991?'48px 16px 0 16px':"120px auto 0 auto",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <video id="introVideo" width="100%" controls poster={videoThumbnail}>
            <source src={LandingPage_Video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      </Box>
      <Features />
      <ModalX_Advantages />
      <HowItWorks />
      <ModalXInAction />
      <CaseStudies />
      <Capabilities />
      <Testimonials />
      {/* <PricePage callPlace={"landing"}/> */}
      <RequestDemoNearFooter />

    </Box>
  );
};

export default LandingPageContents;
