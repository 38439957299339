import sourceUrl from "../../../../api/Source.api";
const token = localStorage.getItem("token");
const isLoggedIn = localStorage.getItem('isLoggedIn');

const fetchLanguages = async (
  setGenVideoLanguage,
  setGenVideoAudioSettingsLoading
) => {
  try {
    const response = await fetch(`${sourceUrl}/video/languages`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...(isLoggedIn ? { Authorization: `Bearer ${token}` } : {}),
        //   'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch audio languages");
    }
    const data = await response.json();
    //   console.log(data.data.data, '===================Data================')
    setGenVideoLanguage(data.data.data);
    setGenVideoAudioSettingsLoading(false);
  } catch (error) {
    console.error(error);
    setGenVideoAudioSettingsLoading(false);
  }
};

const fetchVoices = async (setGenVideoVoice, setGenVideoVoicesLoading) => {
  try {
    const response = await fetch(`${sourceUrl}/video/voices`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...(isLoggedIn ? { Authorization: `Bearer ${token}` } : {}),
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch audio languages");
    }
    const data = await response.json();
    //   console.log(data.data.data, '===================Data================')
    setGenVideoVoice(data.data.data);
    setGenVideoVoicesLoading(false);
  } catch (error) {
    console.error(error);
    setGenVideoVoicesLoading(false);
  }
};

const fetchCaptionFont = async (setCaptionFontForVideoGen) => {
  try {
    const response = await fetch(`${sourceUrl}/video/fonts`, {
      method: "GET",
      credentials: "include",
      headers: {
        ...(isLoggedIn ? { Authorization: `Bearer ${token}` } : {}),
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch caption font");
    }
    const data = await response.json();
    // console.log(data.data, '===================Data================')
    setCaptionFontForVideoGen(data.data);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const VideoGenAPISettings = async (
  setGenVideoLanguage,
  setGenVideoAudioSettingsLoading,
  setGenVideoVoice,
  setGenVideoVoicesLoading,
  setCaptionFontForVideoGen
) => {
  fetchLanguages(setGenVideoLanguage, setGenVideoAudioSettingsLoading);
  fetchVoices(setGenVideoVoice, setGenVideoVoicesLoading);
  fetchCaptionFont(setCaptionFontForVideoGen);
};

export { fetchLanguages, fetchVoices, VideoGenAPISettings, fetchCaptionFont };
