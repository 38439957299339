import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Dialog,
  Typography,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CopyIcon from "../../assets/OfferImages/copy.png";
import boxImage from "../../assets/OfferImages/boxImage.png";
import FlowerImage from "../../assets/OfferImages/FlowerImage.png";
import HalfFlower from "../../assets/OfferImages/HalfFlower.png";
import TreeImage from "../../assets/OfferImages/TreeImage.png";
import CommonToast from "./CommonToast";
import sourceUrl from "../../api/Source.api";
import { useMediaQuery } from "react-responsive";

const OfferModal = ({ pageName }) => {
  const [ShowDiscountCard, setShowDiscountCard] = useState(true);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(null);
  const [iconType, setIconType] = useState(null);
  const [bgColor, setBgColor] = useState("#4caf50");
  const [isLoading, setIsLoading] = useState(false);
  const textRef = useRef(null);
  const isBelow992 = useMediaQuery({ maxWidth: 992 });
  const token = localStorage.getItem("token");
  const offerEndDate = '2025-01-04T23:59:59';

  const handleCloseModal = () => {
    setShowDiscountCard(false);
  };

  const handleCopy = () => {
    const text = "MODALX50";
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setOpen(true);
        setContent("Copied");
        setIconType("success");
      })
      .catch((err) => {
        setOpen(true);
        setContent("Error While Copy");
        setIconType("error");
      });
  };

  const onClose = () => {
    setOpen(false);
  };

  const calculateTimeLeft = () => {
    const now = new Date();
    const endDate = new Date(offerEndDate); 
    const difference = endDate - now;

    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      return null; 
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on unmount
  }, [offerEndDate]);

  const handleAddCredit = async () => {
    setIsLoading(true); // Show loading modal

    const body = {
      priceId: "price_1Q51ELLIN2khABjKiCD5gFG2", // Example price ID
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add your authorization token if required
    };

    try {
      const response = await fetch(
        `${sourceUrl}/payment/createCheckoutSession`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        }
      );

      const result = await response.json();

      if (response.ok && result.status) {
        const redirectUrl = result.data.url;
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          console.error("Redirect URL not found in the response.");
          setIsLoading(false); // Hide loading modal if an error occurs
        }
      } else {
        console.error(
          "Error creating checkout session:",
          result.message || "Unknown error"
        );
        setIsLoading(false); // Hide loading modal if an error occurs
      }
    } catch (error) {
      alert("Create checkout session failed");
      console.error("Error:", error);
      setIsLoading(false); // Hide loading modal if an error occurs
    }
  };

  const HandleUpgradeButtonClick = () => {
    if (token) {
      handleAddCredit();
    } else {
      setContent("Please log in or sign up to continue upgrading a plan.");
      setIconType("error");
      setBgColor("#282D51");
      setOpen(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: ShowDiscountCard ? "flex" : "none",
          position: "relative",
          flexDirection: isBelow992 ? "column" : "row",
          width: pageName === "landing" ? (isBelow992 ? "100%" : "100%") : "100%",
          justifyContent: "space-between",
          // background: "linear-gradient(90deg, #AE0626 0%, #BD394C 100%)",
          background: "linear-gradient(90deg, #B62D42 0%, #BD394C 50%, #C4475D 100%)",
          borderRadius: pageName === "workspace" ? "16px" : "0px",
          zIndex:110,
          padding: isBelow992
            ? "12px"
            : pageName === "workspace"
            ? "12px 20px"
            : "20px",
          rowGap: isBelow992 && "12px",
        }}
      >
        <Box
          sx={{
            position: "relative",
            zIndex:90,
            display: "flex",
            alignItems: "center",
            justifyContent:  "center",
            padding: pageName === "landing" && isBelow992 && "0px 8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isBelow992
                ? "column"
                : pageName === "workspace"
                ? "column"
                : "row",
              columnGap: "12px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", position: "relative", zIndex:200, margin:"0px", padding:"0px" }}>
              <Typography
                ref={textRef}
                sx={{
                  fontFamily: "Inter",
                  fontSize: isBelow992 ? pageName === "workspace" ? "16px" : "16px" :  "20px",
                  fontWeight: 700,
                  lineHeight: "normal",
                  color: "rgba(255, 255, 255, 0.80)",
                  textTransform: "none",
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                Unbox 50% Savings on ModalX Pro!
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", position: "relative", zIndex:200, margin:"0px", padding:"0px" }}>
              <Typography
                ref={textRef}
                sx={{
                  fontFamily: "Inter",
                  fontSize: isBelow992 ? "12px" : "16px",
                  fontWeight: 400,
                  lineHeight: "normal",
                  color: "rgba(255, 214, 154, 0.90)",
                  textTransform: "none",
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                Get unlimited outputs and more. Limited time only
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              position: "relative",
              zIndex:200,
              display: isBelow992
                ? pageName === "landing"
                  ? "none"
                  : "flex"
                : "none",
              width: "28px",
              height: "28px",
              alignSelf: "center",
              margin:"0px 0px 0px 8px"
            }}
          >
            <CloseIcon
              onClick={handleCloseModal}
              aria-label="close"
              sx={{
                gap: "0px",
                opacity: "0px",
                color: "#FFFFFF",
                display: "flex",
                alignSelf: "center",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            />
          </Box>
        </Box>

        {/* <Box
          sx={{
            position: "absolute",
            zIndex: 100,
            left: pageName === "landing" ? "60%" : "38%",
            top: 0,
            display: isBelow992 ? "none" : "flex",
          }}
        >
          <Box>
            <img
              src={TreeImage}
              alt="loading"
              style={{ width: "36px", height: "36px" }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            position: "absolute",
            zIndex: 100,
            left: pageName === "landing" ? "70%" : "45%",
            top: 20,
            display: isBelow992 ? "none" : "flex",
          }}
        >
          <img
            src={FlowerImage}
            alt="loading"
            style={{ width: "36px", height: "36px" }}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            zIndex: 100,
            left: pageName === "landing" ? "50%" : "55%",
            top: 5,
            display: isBelow992 ? "none" : "flex",
          }}
        >
          <Box>
            <img
              src={boxImage}
              alt="loading"
              style={{ width: "36px", height: "36px" }}
            />
          </Box>
        </Box> */}
        {
            pageName === "landing" && timeLeft ?
            <Box
            sx={{
              position:"relative",
              display: "flex",
              flexDirection:"column",
              alignSelf:"center",
              zIndex:200
            }}
            >
               <Typography
                ref={textRef}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "11px",
                  fontWeight: 500,
                  lineHeight: "normal",
                  color: "rgba(255, 255, 255, 0.80)",
                  textTransform: "none",
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                }}
              >Offer ends in
              </Typography>
              <Box sx={{display:"flex"}}>
                <Box sx={{width:"42px"}}>
                <Typography
                ref={textRef}
                sx={{
                  fontFamily: "Inter",
                  fontSize: isBelow992 ? pageName === "workspace" ? "16px" : "16px" :  "20px",
                  fontWeight: 700,
                  lineHeight: "normal",
                  color: "rgba(255, 255, 255, 0.80)",
                  textTransform: "none",
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {timeLeft.days}
              </Typography>
              <Typography
                ref={textRef}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "11px",
                  fontWeight: 500,
                  lineHeight: "normal",
                  color: "rgba(255, 255, 255, 0.80)",
                  textTransform: "none",
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                }}
               >Days</Typography>
             
                </Box>
                <Box>
                <Typography
                ref={textRef}
                sx={{
                  fontFamily: "Inter",
                  fontSize: isBelow992 ? pageName === "workspace" ? "16px" : "16px" :  "20px",
                  fontWeight: 700,
                  lineHeight: "normal",
                  color: "rgba(255, 255, 255, 0.80)",
                  textTransform: "none",
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                :
              </Typography>
                </Box>
                <Box sx={{width:"42px"}}>
                <Typography
                ref={textRef}
                sx={{
                  fontFamily: "Inter",
                  fontSize: isBelow992 ? pageName === "workspace" ? "16px" : "16px" :  "20px",
                  fontWeight: 700,
                  lineHeight: "normal",
                  color: "rgba(255, 255, 255, 0.80)",
                  textTransform: "none",
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {timeLeft.hours}
              </Typography>
              <Typography
                ref={textRef}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "11px",
                  fontWeight: 500,
                  lineHeight: "normal",
                  color: "rgba(255, 255, 255, 0.80)",
                  textTransform: "none",
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                }}
               >Hours</Typography>
             
                </Box>
                <Box>
                <Typography
                ref={textRef}
                sx={{
                  fontFamily: "Inter",
                  fontSize: isBelow992 ? pageName === "workspace" ? "16px" : "16px" :  "20px",
                  fontWeight: 700,
                  lineHeight: "normal",
                  color: "rgba(255, 255, 255, 0.80)",
                  textTransform: "none",
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                :
              </Typography>
                </Box>
                <Box sx={{width:"42px"}}>
                <Typography
                ref={textRef}
                sx={{
                  fontFamily: "Inter",
                  fontSize: isBelow992 ? pageName === "workspace" ? "16px" : "16px" :  "20px",
                  fontWeight: 700,
                  lineHeight: "normal",
                  color: "rgba(255, 255, 255, 0.80)",
                  textTransform: "none",
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {timeLeft.minutes}
              </Typography>
              <Typography
                ref={textRef}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "11px",
                  fontWeight: 500,
                  lineHeight: "normal",
                  color: "rgba(255, 255, 255, 0.80)",
                  textTransform: "none",
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                }}
               >Minutes</Typography>
             
                </Box>
                <Box>
                <Typography
                ref={textRef}
                sx={{
                  fontFamily: "Inter",
                  fontSize: isBelow992 ? pageName === "workspace" ? "16px" : "16px" :  "20px",
                  fontWeight: 700,
                  lineHeight: "normal",
                  color: "rgba(255, 255, 255, 0.80)",
                  textTransform: "none",
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                :
              </Typography>
                </Box>
                <Box sx={{width:"42px"}}>
                <Typography
                ref={textRef}
                sx={{
                  fontFamily: "Inter",
                  fontSize: isBelow992 ? pageName === "workspace" ? "16px" : "16px" :  "20px",
                  fontWeight: 700,
                  lineHeight: "normal",
                  color: "rgba(255, 255, 255, 0.80)",
                  textTransform: "none",
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {timeLeft.seconds}
              </Typography>
              <Typography
                ref={textRef}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "11px",
                  fontWeight: 500,
                  lineHeight: "normal",
                  color: "rgba(255, 255, 255, 0.80)",
                  textTransform: "none",
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                }}
               >Seconds</Typography>
             
                </Box>
              </Box>
            </Box>
             : null
        }
      

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            // columnGap: "16px",
            justifyContent: isBelow992 && "center",
            alignSelf:"center"
          }}
        >
          <Box
            sx={{
              position: "relative",
              zIndex:200,
              height: "40px",
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
              justifyContent: "space-evenly",
              bgcolor: "rgba(235, 69, 93, 0.80)",
              borderRadius: "16px",
              columnGap: "12px",
              padding: "8px 8px",
            }}
          >
            <Typography
              ref={textRef}
              sx={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "20px",
                color: "rgba(255, 255, 255, 0.80)",
                textTransform: "none",
                textAlign: "center",
                margin: "0px",
                padding: "0px",
              }}
            >
              MODALX50
            </Typography>
            <Box
              onClick={handleCopy}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "#AE0727",
                borderRadius: "50%",
                cursor: "pointer",
                width: "32px",
                height: "32px",
                flexShrink: 0,
                "&:hover": {
                  bgcolor: "#C8082C",
                },
              }}
            >
              <img
                src={CopyIcon}
                alt="loading"
                style={{ width: "24px", height: "24px" }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              position: "relative",
              zIndex:200,
              display: "flex",
              alignItems: "center",
              width: pageName === "landing" ? "145px" : "150px",
              marginLeft:"16px",
              flexShrink:0,
            }}
          >
            <Button
              onClick={HandleUpgradeButtonClick}
              sx={{
                height: "36px",
                textTransform: "none",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 600,
                textAlign: "center",
                bgcolor: "#FFFFFF",
                color: "#17182C",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                },
              }}
            >
              {pageName === "landing" ? "Get ModalX Pro" : "Upgrade now"}
            </Button>
          </Box>

          <Box
            sx={{
              display:
                pageName === "landing" ? "none" : isBelow992 ? "none" : "flex",
              width: "28px",
              height: "28px",
              alignSelf: "center",
              flexShrink:0,
            }}
          >
            <CloseIcon
              onClick={handleCloseModal}
              aria-label="close"
              sx={{
                gap: "0px",
                opacity: "0px",
                color: "#FFFFFF",
                display: "flex",
                alignSelf: "center",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            />
          </Box>
        </Box>
      </Box>

      <CommonToast
        open={open}
        onClose={onClose}
        content={content}
        iconType={iconType}
        bgColor={bgColor}
      />
      <Dialog
        open={isLoading}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{ style: { padding: "20px", textAlign: "center" } }}
      >
        <DialogTitle>{"Processing..."}</DialogTitle>
        <DialogContent>
          <CircularProgress />
          <Typography variant="h6" style={{ marginTop: "15px" }}>
            Wait!.. Do not refresh the screen.
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OfferModal;
