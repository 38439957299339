import React, { useState } from "react";
import { Modal, CloseButton } from "react-bootstrap";
import { Button } from "@mui/material";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import {
  button_bg_color_for_New_Project,
  settingsModal_bg_color_dm,
} from "../../../constants/colors";

const VideoTutorialModal = ({ Title, videoSrc }) => {
  const theme = localStorage.getItem("theme");
  const { setShowTutorialModal, showTutorialModal } = useWorkspace();
  const handleClose = () => setShowTutorialModal(false);

  return (
    <div>
      <Modal show={showTutorialModal} onHide={handleClose} centered size="lg">
        <Modal.Header
          style={{ backgroundColor: "dark" ? settingsModal_bg_color_dm : "" }}
        >
          <Modal.Title>{`How to ${Title} on ModalX`}</Modal.Title>
          <CloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            backgroundColor: "dark" ? settingsModal_bg_color_dm : "",
          }}
        >
          <video id="introVideo" width="100%" controls autoPlay muted>
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "dark" ? settingsModal_bg_color_dm : "",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              backgroundColor:
                theme === "dark" ? button_bg_color_for_New_Project : "",
              "&:hover": {
                backgroundColor: "#FFD9AA",
              },
              color: "#17182C",
              fontSize: "16px",
              fontWeight: "600",
              textTransform: "none",
              fontFamily: "Inter, sans-serif",
            }}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VideoTutorialModal;
