import React, { useEffect, useState, useRef } from 'react'
import Sidebar from '../components/workspace/sideBar/Sidebar';
import WebTrackHome from '../components/workspace/sideBar/Track_Website/WebTrackHome';
import CreditRechargeModal from '../components/CreditRechargeModal';
import AllDelete from './AllDelete';
import LogOut from './LogOut';
import CommonToast from '../components/Common_Components/CommonToast';
import { Navigate, useNavigate } from "react-router-dom";
import { useWorkspace } from '../contexts/WorkspaceContext';
import axios from 'axios';
import sourceUrl from '../api/Source.api';
import { fetchAllHistory } from '../contexts/WorkspaceUtils';

const WorkSpaceTrack = () => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const token = localStorage.getItem('token');
    const [showupdate, setShowUpdate] = useState(false)


    const {
        Today,
        iswebTrackClicked,
        setYesterday,
        setThisWeek,
        setThisMonth,
        setPrevMonth,
        setToday,
        setCurrentHistoryId,
        currentWebsiteLinkedId
      } = useWorkspace();
    
      useEffect(() => {
          fetchAllHistory(Today, setToday, setYesterday, setThisWeek, setThisMonth, setPrevMonth, setCurrentHistoryId)
      }, [])



    return (
        isLoggedIn ? 
        <div style={{ display: 'flex', width: '100vw', height: '100vh', overflow: 'hidden' }}>
          <Sidebar />
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <WebTrackHome />
            <CreditRechargeModal />
            <AllDelete/>
            <LogOut/>
            <CommonToast />
          </div>
               {/* Fixed Button */}
        </div> 
        : <Navigate to={"/"} /> 
      );
}

export { WorkSpaceTrack}