import React from "react";
import { Box, Typography } from "@mui/material";
import Web from "../../../../assets/web-plus.png";
import web_cachedIcon from "../../../../assets/web_cachedIcon.png";
import { useMediaQuery } from "react-responsive";

const MessageForTrackWebsiteEmpty = () => {
  const isUnder992px = useMediaQuery({ maxWidth:992});
  return (
    <div>
      <Box sx={{ margin: "0px 0px", width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "100px",
            marginBottom: "100px",
            padding:  isUnder992px ? "0px 0px" : "0px 78px",
          }}
        >
          <Box
            sx={{
              maxWidth:  "320px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <img src={web_cachedIcon} alt="web" style={{width:"60px", height:"60px"}}/>
            </Box>

            <Box>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "18.56%",
                  textAlign: "center",
                  color: "#FFFFFF",
                  marginTop: 3,
                }}
              >
                No updates yet
              </Typography>
            </Box>

            <Box>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle:"normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  color: "#FFFFFF",
                  marginTop: 2,
                  opacity: 0.8,
                  textAlign: "center",
                  padding:'0px',
                //   backgroundColor:"red"
                }}
              >
                ModalX is tracking this webpage and will notify you when there
                is an update. You will be able to view all webpage updates here.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default MessageForTrackWebsiteEmpty;
