import React, {useEffect, useRef, useState} from 'react'
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import Book from "../../../../assets/HelpButtons/Book.png";
import FAQ from "../../../../assets/HelpButtons/FAQ.png";
import Report from "../../../../assets/HelpButtons/Report.png";
import Request from "../../../../assets/HelpButtons/Request.png";
import I_icon from "../../../../assets/HelpButtons/I_icon.png";
import error from "../../../../assets/HelpButtons/error.png";
import CloseIcon from "@mui/icons-material/Close";
import completeImage from "../../../../assets/HelpButtons/success.png";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser"; // Import EmailJS
import sourceUrl from "../../../../api/Source.api";
import { Box, Typography, Tooltip,useMediaQuery,CardMedia,Dialog,Button, TextField,} from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

const HelpButtonMain = () => {
  const helpButtonsRef = useRef()
  const toggleButtonRef = useRef()
  const [showHelpButton, setShowHelpButton] = useState (false);
  const isBelow991 = useMediaQuery ("(max-width:991px)")

  const toggleHelpButton = () => {
    setShowHelpButton((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    // Check if the click is outside both the toggle button and the modal
    if (
      toggleButtonRef.current &&
      helpButtonsRef.current &&
      !toggleButtonRef.current.contains(event.target) &&
      !helpButtonsRef.current.contains(event.target)
    ) {
      setShowHelpButton(false); // Close the modal if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
return (
  <Box>
  <Box
  ref={helpButtonsRef}
  sx={{
    display: showHelpButton ? "block" : "none",
    right:0,
    marginRight:"3%",
    position: "fixed",
    zIndex: 1100,
    bottom: {
      xs: isBelow991 ? "4%" : "7%", // Extra-small screens (0px to 600px)
      sm: isBelow991 ? "4%" : "7%", // Small screens (600px to 960px)
      md: isBelow991 ? "4%" : "7%", // Medium screens (960px to 1280px)
      lg: isBelow991 ? "4%" : "7%", // Large screens (1280px to 1920px)
      xl: isBelow991 ? "4%" : "7%", // Extra-large screens (1920px and up)
    },
  }}
>
  <HelpButtons />
</Box>
<Tooltip
title={showHelpButton ? null : "Help"}
componentsProps={{
tooltip: {
  sx: {
    bgcolor: "#E6E0E9",
    color: "#17182C",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    padding: "4px 8px",
    borderRadius: "4px",
    maxWidth: "104px",
    textAlign: "left",
  },
},
}}
>
<Box
ref={toggleButtonRef}
onClick={toggleHelpButton}
sx={{
  position: 'fixed',
  bottom: {
    xs: isBelow991 ? "8px"  :'12px', // Adjusted for small screens
    sm: isBelow991 ? "8px"  :'12px',
    md: isBelow991 ? "8px"  :'12px',
    lg: isBelow991 ? "8px"  :'12px',
    xl: isBelow991 ? "8px"  :'12px',
  },
  right: '12px', // Positioned away from the right edge
  zIndex: 1100, // High z-index to ensure visibility
  width: isBelow991 ? '24px' : "96px",
  height: isBelow991 ? '24px' : "34px",
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: isBelow991 ? '50%' : "100px",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  gap:"2px",
  bgcolor: showHelpButton ? "#25294C" :"transparent", 
  padding:isBelow991 ? "0px 0px 3px 0px" : "0px 0px 0px 3px",
  '&:hover':{
    bgcolor: showHelpButton ? "#25294C" : "#1F233E",
  }
}}
>
<Typography sx={{display: isBelow991 ? "none":"flex", flexShrink:0 ,fontFamily:"Inter", fontSize:"12px", fontStyle:"normal", fontWeight:400, lineHeight:"normal", letterSpacing:"0.5px", color:"#CAC4D0"}}>
Need help
</Typography>
<Box>
<QuestionMarkIcon  sx={{ width:"16px", height:"14px", paddingBottom: !isBelow991 && "2px" , color:"rgba(202, 196, 208, 1)"}}/>
</Box>
</Box>
</Tooltip>
</Box>
)
}
  
function HelpButtons() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const userEmail = localStorage.getItem("userEmail");
  const { showHelpButton, setShowHelpButton } = useWorkspace();
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [errorShowModal, setErrorShowModal] = useState(false);
  const [modalTitles, setModalTitles] = useState({
    title1: "",
    title2: "",
    title3: "",
    title4: "",
    title5: "",
    title6: "",
    title7: "",
    title8: "",
    title9: "",
    title10: "",
    title11: "",

  });

  const handleShowModal = (type) => {
    const titles = {
      report: {
        title1: "Report an issue or send feedback",
        title2: "What issue did you face while using ModalX?",
        title3: "Briefly describe the issue",
        title4:
          "ModalX may email you for more information or updates about the issue.",
        title5: "Submit feedback",
        title6: "Report an issue or send feedback",
        title7: "Feedback sent",
        title8:
          "We have noted your issue. Our team will work on a fix soon. Thank you for providing valuable feedback.",
        title9: 'Your feedback could not be sent',
        title10: 'An error occurred while submitting your feedback. Please try submitting it again.',
        title11 : 'Retry submitting feedback'

        
      },
      request: {
        title1: "Request a feature",
        title2: "What feature would you like to see on ModalX?",
        title3: "Briefly describe the feature",
        title4: "ModalX may email you for more information or updates.",
        title5: "Submit request",
        title6: "Request a feature",
        title7: "Feature request sent",
        title8:
          "We have noted your request. Thank you for your valuable suggestion. We will keep you posted on new features and updates.",
        title9: 'Your feature request could not be sent',
        title10: 'An error occurred while sending your feature request. Please try sending it again.',
        title11 : 'Retry sending feature request'
      },
    };
    setModalTitles(titles[type]);
    setShowModal(true);
  };

  const handleFaqs = () => {
    navigate("/faq");
  };
  const handleTerms = () => {
    navigate("/privacypolicy");
  };
  const handleBookCall = () => {
    window.open("https://calendly.com/makarand-modalx", "_blank");
  };

  const buttonsData = [
    { id: 1, title: "FAQs", icon: FAQ, type: "", click: handleFaqs },
    {
      id: 2,
      title: "Terms & Privacy",
      icon: FAQ,
      type: "",
      click: handleTerms,
    },
    {
      id: 3,
      title: "Book a call with us",
      icon: Book,
      type: "",
      click: handleBookCall,
    },
    {
      id: 4,
      title: "Report an issue",
      icon: Report,
      type: "report",
      click: "",
    },
    {
      id: 5,
      title: "Request a feature",
      icon: Request,
      type: "request",
      click: "",
    },
  ];

  const handleShowModal2 = () => {
    setShowModal(false);
    setShowModal2(true);
  };

//   setShowModal
  const closeModal = () => {
    setShowModal(false);
    setErrorShowModal(false)
  };
  const closeModal2 = () => {
    setShowModal2(false);
  };
  const handleError =() =>{
    setShowModal2(false);
    setErrorShowModal(true);
    setShowModal(false);

  }
  const closeError =() =>{
    setErrorShowModal(false);
  }

  function removeAfterAtSymbol(userEmail) {
    // Use split to divide the string at the '@' symbol and return the first part
    return userEmail?.split('@')[0];
  }
  
  const name = removeAfterAtSymbol(userEmail);

  const [feedback, setFeedback] = useState("");
  let lenFeedback = feedback.length;
  // const sendEmail = () => {
  //   const emailParams = {
  //     from_name: name,
  //     from_email: userEmail,
  //     message: feedback, // Feedback from TextField
  //     to_email: "indrajeetshanu01@gmail.com", // Recipient email
  //   };
  //   // BZ4Qa-Oade5qgfLli
  //   emailjs
  //     .send(
  //       "service_pvwzb2g",
  //       "template_3s62x2g",
  //       emailParams,
  //       "BZ4Qa-Oade5qgfLli"
  //     )
  //     .then(
  //       (response) => {
  //         console.log(
  //           "Email sent successfully!",
  //           response.status,
  //           response.text
  //         );
  //         handleShowModal2();
  //       },
  //       (error) => {
  //         console.error("Failed to send email:", error);
  //         handleError()
  //       }
  //     );
  //   setFeedback("");
  // };

  // const [feedback, setFeedback] = useState('');

  // Retrieve token from localStorage or replace with a dynamic token
  
  const handleChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = async () => {
    if (!feedback) {
      return; // Prevent submission if feedback is empty
    }

    try {
      const response = await fetch(`${sourceUrl}/support/contactus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
        },
        body: JSON.stringify({

          email: userEmail,  // replace with dynamic data
          message: feedback,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message)
        setFeedback('');  // Clear the feedback field after successful submission
      } else {
        // You can handle error in some other way if needed
        console.error('Something went wrong:', data.error || 'Error occurred');
      }
    } catch (error) {
      // Handle any network errors
      console.error('Error submitting feedback:', error);
    }
  };
  

  return (
    <>
      <Box
        sx={{
          width: "200px",
          // height: "255px",
          // height: "170px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          border: "2px solid rgba(255, 255, 255, 0.1)",
          borderRadius: "4px",
          bgcolor: "#1F233E",
        }}
      >
        {buttonsData.map(({ id, title, icon, type, click }) => (
          <Box
            key={id}
            onClick={() => {
              if (click) click(); // Call the click function if it exists
              if (type) handleShowModal(type); // Show the modal if a type is present
            }}
            sx={{
              marginBottom: "2px",
              width: "192px",
              height: "44px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              bgcolor: "transparent", // Default background color
              transition: "background-color 0.3s ease", // Smooth transition
              "&:hover": {
                bgcolor: "#2F355F", // Change this color as needed
                borderRadius: "4px",
              },
            }}
          >
            <CardMedia
              component="img"
              image={icon}
              alt={title}
              sx={{ width: "20px", height: "20px", marginLeft: "16px" }}
            />
            <Typography
              sx={{
                marginLeft: "12px",
                fontSize: "14px", 
                fontWeight: 400,
                textAlign: "left",
              }}
            >
              {title}
            </Typography>
          </Box>
        ))}

        <Dialog
          open={showModal}
          onClose={closeModal}
          maxWidth="lg"
          fullWidth
          PaperProps={{
            sx: {
              width: "720px",
              //   height: "615px",
              borderRadius: "8px",
              //   border: "1px solid #CAC4D0",
              bgcolor: "#282D51",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "68px",
              padding: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 600,
                color: "#FFFFFF",
              }}
            >
              {modalTitles.title1}
            </Typography>
            <CloseIcon
              onClick={closeModal}
              sx={{
                cursor: "pointer",
                color: "#FFFFFF",
              }}
            />
          </Box>

          <Box sx={{ margin: "40px 0px 0px 40px" }}>
            <Typography sx={{ fontSize: "14px", color: "#FFFFFF" }}>
              {modalTitles.title2}
            </Typography>
          </Box>

          <TextField
            value={feedback}
            // onChange={(e) => setFeedback(e.target.value)} // Store user input in state
            onChange={handleChange}
            variant="standard" // Prevents the default outlined border
            placeholder={modalTitles.title3}
            InputProps={{
              disableUnderline: true,
              sx: {
                padding: "10px", // Padding inside the text field
                "&::placeholder": {
                  color: "rgba(255, 255, 255, 0.8)", // Custom color for placeholder
                  fontSize: "16px", // Optional: Adjust font size
                },
              },
            }}
            sx={{
              margin: "20px 40px 0px 40px",
              height: "112px",
              border: "1px solid white",
              borderRadius: "4px",
              bgcolor: "#232746",
              "& .MuiInputBase-input": {
                color: "#FFFFFF",
              },
            }}
          />
          <Box
            sx={{
              margin: "40px 40px 0px 40px",

              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "15px",
                height: "15px",
              }}
            >
              <CardMedia component="img" image={I_icon} />
            </Box>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 400,
                textAlign: "left",
                marginLeft: "8px",
                color: "#FFFFFF",
              }}
            >
              {" "}
              {modalTitles.title4}
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
              width: "100%",
              height: "72px",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Box
              sx={{
                minWidth: "290px",
                height: "40px",
                marginRight: "16px",
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-between",
                marginRight: "40px",
              }}
            >
              <Button
                onClick={closeModal}
                sx={{
                  width: "93px",
                  height: "40px",
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                  bgcolor: "#FFFFFF",
                  color: "#17182C",
                  "&:hover": {
                    backgroundColor: "#CDCFDF",
                  },
                }}
              >
                Close
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={lenFeedback === 0} // Disable the button if feedback is empty
                sx={{
                  width: "181px",
                  height: "40px",
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                  bgcolor: lenFeedback
                    ? "#F6BE6B"
                    : "rgba(230, 224, 233, 0.12) ",
                  color: "#17182C",
                  fontWeight: "600",
                  // height: "40px",
                  fontFamily: "Inter, sans-serif",
                  border: "none",
                  "&:hover": {
                    bgcolor: "#ffd9aa",
                    border: "none",
                  },
                  "&:disabled": {
                    bgcolor: "rgba(230, 224, 233, 0.12)", // Explicitly set background when disabled
                    color: "#E6E0E9", // Explicitly set text color when disabled
                  },
                }}
              >
                {modalTitles.title5}
              </Button>
            </Box>
          </Box>
        </Dialog>
        <Dialog open={showModal2} close={handleShowModal2}>
          <Box
            sx={{
              maxWidth: "480px",
              bgcolor: "#282D51",
            }}
          >
            <Box
              sx={{
                maxWidth: "480px",
                height: "68px",
                // bgcolor : 'red',
                borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "440px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "0px 20px 0px 20px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 600,
                    textAlign: "left",
                    color: "#FFFFFF",
                  }}
                >
                  {modalTitles.title6}
                </Typography>
                <Box
                  onClick={closeModal2}
                  sx={{
                    width: "16px",
                    height: "16px",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  <CloseIcon />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "40px",
                height: "40px",
                // bgcolor: "#2F355F",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                marginLeft: "9px",
                margin: "40px 0px 0px 40px",
              }}
            >
              <CardMedia component="img" image={completeImage} alt="loading" style={{width:"40px", height:"40px"}}/>
            </Box>

            <Box
              sx={{
                // width: "200px",
                margin: "20px 0px 0px 40px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: 600,
                  textAlign: "left",
                  color: "#FFFFFF",
                }}
              >
                {modalTitles.title7}
              </Typography>
            </Box>
            <Box
              sx={{
                margin: "20px 40px 0px 40px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "400px",
                  textAlign: "left",
                  color: "#FFFFFF",
                }}
              >
                {modalTitles.title8}
              </Typography>
            </Box>
            <Box
              sx={{
                margin: "20px 40px 40px 40px",
              }}
            >
              <Button
                // onClick={() => navigate("/workspace")}
                onClick={closeModal2}
                variant="contained"
                sx={{
                  width: "400px",
                  height: "40px",
                  bgcolor: "#F6BE6B",
                  borderRadius: "4px",
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  textAlign: "center",
                  fontWeight: 600,
                  color: " #17182C",
                  "&:hover": {
                    bgcolor: "#FFD9AA",
                  },
                }}
              >
                Back to workspace
              </Button>
            </Box>
          </Box>
        </Dialog>
        <Dialog open={errorShowModal} close={handleError}>
          <Box
            sx={{
              maxWidth: "480px",
              bgcolor: "#282D51",
            }}
          >
            <Box
              sx={{
                maxWidth: "480px",
                height: "68px",
                // bgcolor : 'red',
                borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "440px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "0px 20px 0px 20px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 600,
                    textAlign: "left",
                    color: "#FFFFFF",
                  }}
                >
                  {modalTitles.title6}
                </Typography>
                <Box
                  onClick={closeError}
                  sx={{
                    width: "16px",
                    height: "16px",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  <CloseIcon />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "40px",
                height: "40px",
                // bgcolor: "#2F355F",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                marginLeft: "9px",
                margin: "40px 0px 0px 40px",
              }}
            >
              <CardMedia component="img" image={error} alt="loading" style={{width:"40px", height:"40px"}}/>
            </Box>

            <Box
              sx={{
                // width: "200px",
                margin: "20px 0px 0px 40px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: 600,
                  textAlign: "left",
                  color: "#FFFFFF",
                }}
              >
                {modalTitles.title9}
              </Typography>
            </Box>
            <Box
              sx={{
                margin: "20px 40px 0px 40px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "400px",
                  textAlign: "left",
                  color: "#FFFFFF",
                }}
              >
                {modalTitles.title10}
              </Typography>
            </Box>
            <Box
              sx={{
                margin: "20px 40px 40px 40px",
              }}
            >
              <Button
                // onClick={() => navigate("/workspace")}
                // onClick={closeModal2}
                onClick={() => setShowModal(true)}
                variant="contained"
                sx={{
                  width: "400px",
                  height: "40px",
                  bgcolor: "#F6BE6B",
                  borderRadius: "4px",
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  textAlign: "center",
                  fontWeight: 600,
                  color: " #17182C",
                  "&:hover": {
                    bgcolor: "#FFD9AA",
                  },
                }}
              >
                {modalTitles.title11}
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
    </>
  );
}

export default HelpButtonMain;
