import React, {useState} from "react";
import HomeCarousel from "../components/HomeCarousel";
import { Card, Col, Row } from "react-bootstrap";
// import ToolSelector from "../components/ToolSelector";
import PageLayout from "../components/navFoot/PageLayout";
import { useNavigate } from "react-router-dom";
import Login from "../components/navFoot/Login";
import LandingPageContents from "../components/LandingPageContents";
import { Application_bg_color_dm } from "../Provider/Color_Provider";
import { darkTheme_bg_color } from "../constants/colors";
// import OfferModal from "../components/Common_Components/OfferModal";


function Home({ showLogin = false }) {
  const navigate = useNavigate();
  const Flow_Gif = require("../assets/FLOW.gif")
  const ModalX_Globe = require("../assets/ModalX_Globe.gif")
  const isLoggedIn  = localStorage.getItem('isLoggedIn');
  // const [pageName, setPageName] = useState("landing")

  function howItWorks() {
    const cardContent = [
      {
        title: "1. Tell us your vision!",
        description:
          "Whether it's crafting a presentation or whipping up an eye-catching image or video, we're all ears.",
      },
      {
        title: "2. Got files?",
        description:
          "We're ready for 'em! Upload your references in any format you fancy. We're versatile like that!",
      },
      {
        title: "3. Check it out, then take it home!",
        description: "Review your content with a keen eye, then download it pronto. It's that easy!",
      },
    ];
    return (
      <>
        
        <h2 className="mt-5 mb-3">How it works</h2>
        <Row>
          {cardContent.map((card, index) => (
            <Col key={index} xs={12} sm={12} md={6} lg={4} className="py-2">
              <Card
                variant="link"
                onClick={() => navigate("/landing")}
                style={{
                  height: "100%",
                  cursor: "pointer",
                }}
              >
                <Card.Body style={{ textAlign: "left" }}>
                  <i className={`bi bi-arrow-right fs-2`}></i>
                  <Card.Title>
                    <h5 className="card-title">{card.title}</h5>
                  </Card.Title>
                  <Card.Text style={{ color : "gray" }} className="card-body">
                    {card.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <style>
        {`
          
          @media (max-width: 500px) {
            .mt-5 mb-3 h2{
              font-size: 1rem !important;
            }
            .card-title {
              font-size: 1rem !important;
            }
            .card-body {
              font-size: .9rem !important;
            }
          }
        `}
      </style>
      </>
    );
  }

  // const popularTools = ["Text", "Video", "PPT"];

  return (
    <div style={{backgroundColor:darkTheme_bg_color,fontFamily: 'Inter, sans-serif',}}>
      <PageLayout showLogin={showLogin}>
        <div style={{ display:"flex", justifyContent:"center"}}>
        {/* <OfferModal pageName = {pageName}/> */}
        </div>
        <div>
          <LandingPageContents />
        </div>
      
      {/* <HomeCarousel /> */}
      {/* <div className="text-center my-4" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
        <img
          src={Flow_Gif}
          alt="Example GIF"
          style={{ width: "100%",height: "auto",position:'relative' }}
        />
        <img src={ModalX_Globe} style={{width:'16%',position:'absolute',borderRadius:'50%'}}/>
      </div> */}
      {/* {howItWorks()} */}
      {/* <h2 className="mt-5 mb-3">Popular Tools</h2> */}
      {/* <ToolSelector selectedTools={popularTools} /> */}
      </PageLayout>
    </div>
    
  );
}

export default Home;
