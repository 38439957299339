import React from "react";
import { Box, useMediaQuery } from "@mui/system";
import { Image, Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-h5-audio-player";

  // Styled-components for custom audio player styles
const AudioContainer = styled.div`
position: relative;
display: inline-block;
width: 100%;
&:hover .download-button,
&:hover .large-preview-button,
&:hover .share-button {
  display: flex;
}
`;

const ResponsiveDocumentViewer = styled.iframe`
width: 100%;
height: ${({ height }) => height || "100%"};
border: none;
`;

const ImageContainer = styled.div`
position: relative;
display: inline-block;
width: 350px;
border:"1px solid red
border-radius: 4px; /* Add border-radius */
&:hover .download-button,
&:hover .large-preview-button,
&:hover .share-button {
  display: flex;
}
`;

const CustomAudioPlayer = styled(ReactAudioPlayer)`
.rhap_container {
  background-color: transparent !important;
  border-radius: 10px;
}

.rhap_progress-bar {
  background: #626473;
  border-radius: 10px;
}

.rhap_progress-indicator {
  background: #ffffff;
  border-radius: 50%;
}

.rhap_progress-filled,
.rhap_volume-filled {
  background: #ffffff !important;
  border-radius: 10px;
}

.rhap_time {
  color: #007bff;
}

.rhap_controls-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rhap_repeat-button {
  display: none !important;
}
.rhap_progress-section {
  margin: 0% 10%;
}
.rhap_download-progress {
  background-color: #626473;
}
.rhap_main-controls-button,
.rhap_volume-button,
.rhap_time {
  color: #ffffff;
}
.rhap_volume-indicator {
  background-color: #ffffff;
}
.rhap_volume-bar {
  background: #626473;
}
.rhap_volume-bar {
  background-color: #ffffff;
}
`;

const PreviewMediaModalForUploadedFiles = ({
  url,
  showModal,
  handleCloseModal,
}) => {
  const mobileView = useMediaQuery("(max-width:501px)");

  const videoTest = /\.(mp4|mov)$/i;
  const audioTest = /\.(mp3)$/i;
  const imageTest = /\.(jpg|jpeg|png)$/i;
  const pdfTest = /\.(pdf)$/i;

  switch (showModal) {
    case videoTest.test(url):
      return (
        <>
          {showModal && (
            <Modal
              show={showModal}
              onHide={handleCloseModal}
              style={{ backgroundColor: "" }}
            >
               <Modal.Header closeButton style={{ backgroundColor: "#282D51" }}>
                <Modal.Title
                  style={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                >
                  Video Preview
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ backgroundColor: "#282D51" }}>
                <ReactPlayer
                  url={url}
                  controls
                  width="100%"
                  height="100%"
                  style={{ borderRadius: "8px" }}
                />
              </Modal.Body>
            </Modal>
          )}
        </>
      );
    case audioTest.test(url):
      return (
        <AudioContainer>
          <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Audio Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CustomAudioPlayer
                src={url}
                autoPlay={false}
                controls
                style={{
                  maxWidth: "90%",
                  width: "100%",
                  backgroundColor: "transparent",
                  borderRadius: "20px",
                }}
              />
            </Modal.Body>
            <Modal.Footer>
            {/* <DownloadButtonInsideModal
                  href={url}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  className="download-button"
                  title="Download"
                  style={{display:'flex',alignItems:'center'}}
                >
                  <i className="bi bi-cloud-arrow-down"></i><span style={{fontSize:'1rem',marginLeft:'10px'}}> Download</span>
              </DownloadButtonInsideModal> */}
            </Modal.Footer>
          </Modal>
          {/* ShareButton and other buttons */}
        </AudioContainer>
      );
    case imageTest.test(url):
      return (
        <ImageContainer>
          {mobileView ? 
          (
            <Modal
              show={showModal}
              onHide={handleCloseModal}
              style={{
                backgroundColor: "#171A2D",
                width: "100%",
                border: "none",
              }}
              size="lg"
            >
              <Modal.Body
                style={{
                  backgroundColor: "#171A2D",
                  padding: 0, // Remove padding around the body
                  margin: -8, // Ensure no margin between Modal and Modal.Body
                  height: 160,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  onClick={handleCloseModal}
                  sx={{
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <CloseIcon />
                </Box>
              </Modal.Body>
              <Modal.Body
                style={{
                  backgroundColor: "#171A2D",
                  padding: 0, // Remove padding around the body
                  margin: -8, // Ensure no margin between Modal and Modal.Body
                }}
              >
                <Image
                  src={url}
                  style={{
                    // borderRadius: "8px",
                    width: "100%", // Ensure the image takes up 100% width of its container
                    height: "auto", // Maintain aspect ratio of the image
                  }}
                  alt="Image Preview"
                  fluid
                />
              </Modal.Body>
            </Modal>
          ) : 
          (
            <Modal
              show={showModal}
              onHide={handleCloseModal}
              style={{ backgroundColor: "" }}
            >
              <Modal.Header closeButton style={{ backgroundColor: "#282D51" }}>
                <Modal.Title
                  style={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                >
                  Image Preview
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ backgroundColor: "#282D51" }}>
                <Image
                  src={url}
                  style={{ borderRadius: "8px" }}
                  alt="Image Preview"
                  fluid
                />
              </Modal.Body>
            </Modal>
          )
           }
        </ImageContainer>
      );
    case pdfTest.test(url):
      return (         
          <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>PDF Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ResponsiveDocumentViewer
                src={url}
                height="500px"
                borderRadius="8px"
                width="100%"
              />
            </Modal.Body>
            <Modal.Footer>
              </Modal.Footer>
          </Modal>
      );
    default:
      return null;
  }
};

export default PreviewMediaModalForUploadedFiles;
