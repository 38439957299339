// import React, { useEffect, useState } from 'react'
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import axios from "axios";
// import { ListGroup } from 'react-bootstrap';

// const ViewThreadFiles = () => {
//     const {threadId} = useWorkspace()
//     const id = threadId ;
//     const token = localStorage.getItem('token');
//     const [files,setFiles] = useState([]);
//     const fetchHistoryFiles = async() => {
//         try {
//             // const res = await axios.get(`https://modalx.ai/threads/${id}/listFile`,{
//             //     headers:{
//             //         Authorization:`Bearer ${token}`
//             //     }
//             // });
//             const res = await axios.get(`https://modalx.ai/chat/${id}/history`, {
//               headers: {
//                 Authorization: `Bearer ${token}`
//               }
//             });
//             setFiles(res.data.data.input_links);
//             // console.log("Inside_thread_files",res.data.data.input_links);
//         } catch (error) {
//             console.log(error);
//         }
//     }
//     console.log("Inside_thread_files",files);
//     // console.log("Inside_thread_files",id);
//     useEffect(() => {
//         fetchHistoryFiles()
    
//     }, [id])
    
//   return (
//     <div>
//     <p style={{fontSize:'.95rem',textDecoration:'underline'}}>Uploaded Files & Links</p>
//     <ul style={{ listStyleType: 'disc', paddingInlineStart: '1em' }}>
//         {files.map((file, index) => (
//             <li key={index} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={file}>
//                 {file.length > 20 ? `${file.substring(0, 20)}...` : file}
//             </li>
//         ))}
//     </ul>
// </div>
//   )
// }

// export default ViewThreadFiles


import React, { useEffect, useState,useRef } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import sourceUrl from "../../../api/Source.api";
import Youtube_Icon from "../../../assets/File_Icon/youtube_icon.png";
import Xlsx_Icon from "../../../assets/File_Icon/xlsx_icon.png";
import Pdf_Icon from "../../../assets/File_Icon/pdf_icon.png";
import {  Box, CardMedia, IconButton, makeStyles, Tooltip } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReplayIcon from '@mui/icons-material/Replay';
import RemoveFileModal from './RemoveFileModal';
import progressIndicator from '../../../assets/progressIndicator.png'
import pngIcon from "../../../assets/File_Icon/pngIcon.png";
import movIcon from "../../../assets/File_Icon/movIcon.png";
import mp4Icon from "../../../assets/File_Icon/mp4Icon.png";
import word_logo from "../../../assets/File_Icon/word_logo.png";
import mp3_logo from "../../../assets/File_Icon/mp3_logo.png";
import powerpoint_logo from "../../../assets/File_Icon/powerpoint_logo.png";
import imageIcon from "../../../assets/File_Icon/image_icon.png";
import jpgIcon from "../../../assets/File_Icon/jpgIcon.png"
import CompleteIcon from '@mui/icons-material/CheckCircle';
import completeIcon from '../../../assets/greencheckIcon.png'
import CircularProgress from './ProcessingIndicator/CircularProgress';
import { DeleteUploadFileFromS3, FetchHistoryOfThreadId } from '../../../contexts/WorkspaceUtils';
import { FileUpload, trackFileUpload } from '../../../config/analytics';
import * as Sentry from "@sentry/react";
import PreviewMediaModalForUploadedFiles from './PreviewMediaModalForUploadedFiles';
import deleteIcon from "../../../assets/DeleteIcons.png";
import { saveAs } from 'file-saver';




const ViewThreadFiles = () => {
    const { threadId, historyFiles, threadFiles, setThreadFiles, InvokeMatrixApi, uploadFiles, setWaitThread,upload_FilesOnly, setCurrentThread,setHistoryFiles} = useWorkspace();
    const [progressStatusChecking, setProgressStatusChecking] = useState({});
    const [filesLen, setFilesLen] = useState(0);
    const [toggleRemoveFile, setToggleRemoveFile] = useState(false)
    const [clickIndex , setClickIndex] = useState(null)
      const [completedFiles, setCompletedFiles] = useState({});
    const [showCompleteIcon, setShowCompleteIcon] = useState({});
    const [isDragging, setIsDragging] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [notificationToastMessage, setNotificationToastMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState(null);
    const [showModalUploadFile, setShowModalUploadFile] = useState(false);
    const email = localStorage.getItem("userEmail");
    const token = localStorage.getItem('token');

    const id = threadId && parseInt(threadId.replace("thread-", ""), 10);

    // Ensure both historyFiles and threadFiles are arrays
    const files = [...(threadFiles ? [...threadFiles].reverse() : []), ...(historyFiles || [])];

    const renderIcons = () => {
        
    }

    const handleFileChange = async (event) => {
      try {
        setWaitThread(true);
        const files = Array.from(event.target.files); // Convert FileList to Array
        const existingFileNames = threadFiles.map((file) => file.name);
  
        // Filter out files that already exist in threadFiles by their names
        const newFiles = files.filter(
          (file) => !existingFileNames.includes(file.name)
        );
  
        // Clear the file input after selecting files
        event.target.value = "";
  
        // Map files to upload promises
        const uploadPromises = newFiles.map(async (file) => {
          setLoading(true); // Show loader
  
          // Map each file to the prescribed JSON format and mark as "uploading"
          const fileToUpdate = {
            type: "file",
            file: file,
            name: file.name,
            status: "uploading", // Mark as "uploading" initially
            fileId: null,
            url: null, // Add a placeholder for the URL
          };
  
          // Update threadFiles with the new file object
          setThreadFiles((prevThreadFiles) => [...prevThreadFiles, fileToUpdate]);
  
          // Upload the file and return fileId
          const response = await upload_FilesOnly(fileToUpdate);
          const fileId = response.data.id;
          const url = response.data.url;
  
          // console.log('here is the url that sent before check kkkkkkkkkkkkkkkkkkkkkkkkk',response)
          // After uploading file event triggred for file uploaded
  
          const fileType = files[0].type;
          const fileCount = threadFiles.length + 1;
          const file_method = "Click to Upload";
          trackFileUpload ("file_upload", fileType, fileCount, file_method);
          FileUpload(fileType, fileCount);
  
          // Update the file object with uploaded state or upload failed
          setThreadFiles((prevThreadFiles) =>
            prevThreadFiles.map((prevFile) =>
              prevFile.name === file.name
                ? {
                    ...prevFile,
                    status: response.data.status, // Update status
                    fileId: fileId, // Update fileId
                    url: url, // Update URL
                  }
                : prevFile
            )
          );
  
          // Start checking progress status
          setProgressStatusChecking((prev) => ({ ...prev, [url]: true }));
  
          // Poll progress status every 3 seconds
          const interval = setInterval(async () => {
            // const progressStatus = await checkUrlStatus(url);
            const status_resp = await fetch(`${sourceUrl}/status`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({ threadId, url }),
            });
            const responseData = await status_resp.json();
            if (
              status_resp.status === 200 &&
              responseData.data.status === "completed"
            ) {
              // ==========================Invoke matrix api ===================================================
              InvokeMatrixApi(
                responseData,
                status_resp,
                "POST",
                "status",
                { threadId, url },
                email
              );
              // ===============================================================================================
              setThreadFiles((prevThreadFiles) =>
                prevThreadFiles.map((prevFile) =>
                  prevFile.name === file.name
                    ? {
                        ...prevFile,
                        status: "completed", // Update status
                        fileId: fileId, // Update fileId
                        url: url, // Update URL
                      }
                    : prevFile
                )
              );
  
              setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
              clearInterval(interval); // Stop interval
              setFileUploaded (true);
              setNotificationToastMessage("File uploaded successfully");
            } else {
              if (
                status_resp.status === 200 &&
                responseData.data.status === "failed"
              ) {
                setThreadFiles((prevThreadFiles) =>
                  prevThreadFiles.map((prevFile) =>
                    prevFile.name === file.name
                      ? {
                          ...prevFile,
                          status: "failed",
                          fileId: "", // Update fileId
                          url: url, // Update URL
                        }
                      : prevFile
                  )
                );
                // ==========================Invoke matrix api ===================================================
                InvokeMatrixApi(
                  responseData,
                  status_resp,
                  "POST",
                  "status",
                  { threadId, url },
                  email
                );
                // ===============================================================================================
                setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
                clearInterval(interval); // Stop interval
              } else if (status_resp.status === 500) {
                setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
                clearInterval(interval); // Stop interval
              }
              // setFileUploaded(true);
              // setNotificationToastMessage('There was an error while uploading the file. Please try again')
            }
          }, 3000);
  
          // Return updated file object with fileId
          return fileId
            ? { ...fileToUpdate, status: "uploaded", fileId: fileId }
            : { ...fileToUpdate, status: "failed" };
        });
  
        // Wait for all uploads to finish
        await Promise.all(uploadPromises);
  
        setLoading(false); // Hide loader
        setWaitThread(false);
        // triggredFileUploadEvent(event)
      } catch (error) {
        Sentry.captureException(error);
        console.error("Error while handling file change:", error);
        setFileUploaded(true);
        setNotificationToastMessage(
          "There was an error while uploading the file. Please try again"
        );
      }
    };

    const handleCloseModal = () =>{
      setShowModalUploadFile(false)
    }

      const handlePdfDownload = (url) => {
        fetch(url)
          .then(response => response.blob()) // Get the file as a blob
          .then(blob => {
            const fileName = url.split('/').pop(); // Extract filename from the URL
            saveAs(blob, fileName); // Trigger the download
          })
          .catch(error => console.error('Download failed:', error));
      };

    const checkFileExtension = url => url.endsWith('.docx') || url.endsWith('.xlsx') ? true : false;

    const handleuploadedFileClick = (file) =>{
      console.log(file, 'file')
      const fileExtension = checkFileExtension(file.input_file_url ? file.input_file_url : file?.url )
      if(fileExtension){
        handlePdfDownload(file.input_file_url ? file.input_file_url : file?.url)
      }else{
        if(file?.input_file_url){
          setUrl(file?.input_file_url);
        }else{
          setUrl(file?.url);
        }
        setShowModalUploadFile(true)
      }
    }

    // useEffect(() => {
    //     const intervals = files.map((file) => {
    //         if (file.status === "uploading" || file.status === "processing") {
    //             setProgressStatusChecking((prev) => ({ ...prev, [file.url]: true }));

    //             const interval = setInterval(async () => {
    //                 try {
    //                     const status_resp = await fetch(`${sourceUrl}/status`, {
    //                         method: "POST",
    //                         headers: {
    //                             "Content-Type": "application/json",
    //                             "Authorization": `Bearer ${token}`
    //                         },
    //                         body: JSON.stringify({ threadId, url: file.url }),
    //                     });
    //                     const responseData = await status_resp.json();

    //                     if (status_resp.status === 200 && responseData.data.status === "completed") {
    //                         InvokeMatrixApi(responseData, status_resp, 'POST', 'status', { threadId, url: file.url });

    //                         setThreadFiles((prevThreadFiles) =>
    //                             prevThreadFiles.map((prevFile) =>
    //                                 prevFile.name === file.name
    //                                     ? { ...prevFile, status: "completed" }
    //                                     : prevFile
    //                             )
    //                         );

    //                         setProgressStatusChecking((prev) => ({ ...prev, [file.url]: false }));
    //                         clearInterval(interval);
    //                     } else if (status_resp.status === 200 && responseData.data.status === "failed") {
    //                         setThreadFiles((prevThreadFiles) =>
    //                             prevThreadFiles.map((prevFile) =>
    //                                 prevFile.name === file.name
    //                                     ? { ...prevFile, status: "failed" }
    //                                     : prevFile
    //                             )
    //                         );

    //                         setProgressStatusChecking((prev) => ({ ...prev, [file.url]: false }));
    //                         clearInterval(interval);
    //                     } else if (status_resp.status === 500) {
    //                         setProgressStatusChecking((prev) => ({ ...prev, [file.url]: false }));
    //                         clearInterval(interval);
    //                     }
    //                 } catch (error) {
    //                     console.error("Error checking file status:", error);
    //                     setProgressStatusChecking((prev) => ({ ...prev, [file.url]: false }));
    //                     clearInterval(interval);
    //                 }
    //             }, 3000);

    //             return interval;
    //         }

    //         return null;
    //     });

    //     // Cleanup intervals on unmount
    //     return () => {
    //         intervals.forEach((interval) => {
    //             if (interval) clearInterval(interval);
    //         });
    //     };
    // }, [files, threadId, token, setThreadFiles, InvokeMatrixApi]);
 
    useEffect(() => {
        const intervals = files?.map((file) => {
            if (file.status === "uploading" || file.status === "processing") {
                const interval = setInterval(async () => {
                    try {
                        // Validate that threadId and file.url are not undefined or null
                        if (!threadId || !file.url) {
                            console.error("Invalid data: threadId or file URL is missing.");
                            clearInterval(interval); // Stop if the data is invalid
                            return;
                        }
    
                        // Log the request body for debugging
                        const requestBody = JSON.stringify({ threadId, url: file.url });
                        console.log("Request Body:", requestBody);
    
                        // Fetch request to check file status
                        const status_resp = await fetch(`${sourceUrl}/status`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${token}`
                            },
                            body: requestBody,
                        });
    
                        // Handle response
                        if (!status_resp.ok) {
                            console.error(`Failed with status: ${status_resp.status}`);
                            return;
                        }
    
                        const responseData = await status_resp.json();
                        console.log("Response Data:", responseData);
    
                        // Handle different statuses
                        if (responseData.data.status === "completed") {
                            setShowCompleteIcon((prev) => ({ ...prev, [file.url]: true })); // Show complete icon
                            setTimeout(() => {
                                setShowCompleteIcon((prev) => ({ ...prev, [file.url]: false })); // Hide after 2 seconds
                            }, 2000);
    
                            setThreadFiles((prevThreadFiles) =>
                                prevThreadFiles.map((prevFile) =>
                                    prevFile.name === file.name
                                        ? { ...prevFile, status: "completed" }
                                        : prevFile
                                )
                            );
                            clearInterval(interval); // Clear interval after completion
                        } else if (responseData.data.status === "failed") {
                            setThreadFiles((prevThreadFiles) =>
                                prevThreadFiles.map((prevFile) =>
                                    prevFile.name === file.name
                                        ? { ...prevFile, status: "failed" }
                                        : prevFile
                                )
                            );
                            clearInterval(interval); // Clear interval after failure
                        } else {
                            console.error(`Unexpected status: ${responseData.data.status}`);
                        }
                    } catch (error) {
                        console.error("Error checking file status:", error);
                    }
                }, 3000);
    
                return interval;
            }
    
            return null;
        });
    
        // Clear all intervals on cleanup
        return () => {
            intervals.forEach((interval) => {
                if (interval) clearInterval(interval);
            });
        };
    }, [files, threadId, token, setThreadFiles]);
    
    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };
  
    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
    };
  
    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
  
      const files = e.dataTransfer.files;
      if (files.length > 0) {
        handleFileChange({ target: { files } });
      }
    };




    useEffect(() => {
        setFilesLen(files.length);
    }, [files]);

    const getFileIcon = (fileName) => {
        if (fileName.includes("youtube")) return Youtube_Icon;
        if (fileName.includes("mp4")) return mp4Icon;
        if (fileName.includes("mov")) return movIcon;
        if (fileName.includes("doc") || fileName.includes("docx"))  return word_logo;    
        if (fileName.includes("pptx") || fileName.includes("pptx") || fileName.includes("pptm"))  return powerpoint_logo;      
        if (fileName.includes("mp3")) return mp3_logo;
        // if (fileName.includes("png")) return pngIcon;
        // if (fileName.includes("jpg")) return jpgIcon;
        if (fileName.endsWith(".pdf")) return Pdf_Icon;
        if (fileName.endsWith(".xlsx") || fileName.endsWith(".xls")) return Xlsx_Icon;
        return null;
    };

    // const handleRetryFile = async (file) => {
    //     // Update only the specific file being retried
    //     setThreadFiles(prevThreadFiles =>
    //         prevThreadFiles.map(prevFile =>
    //             prevFile.link === file.link
    //                 ? { ...prevFile, status: "uploading" }
    //                 : prevFile
    //         )
    //     );
        
    //     // Mark the specific file's progress as true
    //     setProgressStatusChecking(prev => ({ ...prev, [file.url]: true }));
    
    //     setWaitThread(true);
    
    //     try {
    //         const response = await uploadFiles(file);
    
    //         if (response && response.data) {
    //             const { id: fileId, url, status } = response.data;
    //             setThreadFiles(prevThreadFiles =>
    //                 prevThreadFiles.map(prevFile =>
    //                     prevFile.link === file.link
    //                         ? { ...prevFile, status, fileId, url }
    //                         : prevFile
    //                 )
    //             );
    
    //             setProgressStatusChecking(prev => ({ ...prev, [url]: true }));
    
    //             const interval = setInterval(async () => {
    //                 try {
    //                     const status_resp = await fetch(`${sourceUrl}/status`, {
    //                         method: "POST",
    //                         headers: {
    //                             "Content-Type": "application/json",
    //                             "Authorization": `Bearer ${token}`,
    //                         },
    //                         body: JSON.stringify({ url }),
    //                     });
    
    //                     const responseData = await status_resp.json();
    
    //                     if (status_resp.ok && responseData.data) {
    //                         const { status } = responseData.data;
    //                         setThreadFiles(prevThreadFiles =>
    //                             prevThreadFiles.map(prevFile =>
    //                                 prevFile.link === file.link
    //                                     ? { ...prevFile, status, fileId, url }
    //                                     : prevFile
    //                             )
    //                         );
    
    //                         if (status === "completed" || status === "failed") {
    //                             setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
    //                             clearInterval(interval);
    //                         }
    //                     } else {
    //                         setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
    //                         clearInterval(interval);
    //                     }
    //                 } catch (error) {
    //                     console.error('Error fetching status:', error);
    //                     setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
    //                     clearInterval(interval);
    //                 }
    //             }, 3000);
    //         } else {
    //             console.error('Invalid response from uploadFiles:', response);
    //             setThreadFiles(prevThreadFiles =>
    //                 prevThreadFiles.map(prevFile =>
    //                     prevFile.link === file.link
    //                         ? { ...prevFile, status: "failed", fileId: "", url: file.link }
    //                         : prevFile
    //                 )
    //             );
    //         }
    //     } catch (error) {
    //         console.error('Error during file upload:', error);
    //         setThreadFiles(prevThreadFiles =>
    //             prevThreadFiles.map(prevFile =>
    //                 prevFile.link === file.link
    //                     ? { ...prevFile, status: "failed", fileId: "", url: file.link }
    //                     : prevFile
    //             )
    //         );
    //     }
    
    //     setWaitThread(false);
    // };


    const handleRetryFile = async (file) => {
        // Mark the specific file as "uploading"
        setThreadFiles(prevThreadFiles =>
            prevThreadFiles.map(prevFile =>
                prevFile.fileId === file.fileId
                    ? { ...prevFile, status: "uploading" }
                    : prevFile
            )
        );
        
        setProgressStatusChecking(prev => ({ ...prev, [file.url]: true }));
        setWaitThread(true);
    
        try {
            const response = await upload_FilesOnly(file);
            if (response && response.data) {
                const { id: newFileId, url, status } = response.data;
                setThreadFiles(prevThreadFiles =>
                    prevThreadFiles.map(prevFile =>
                        prevFile.fileId === file.fileId
                            ? { ...prevFile, status, fileId: newFileId, url }
                            : prevFile
                    )
                );
    
                const interval = setInterval(async () => {
                    try {
                        const status_resp = await fetch(`${sourceUrl}/status`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${token}`,
                            },
                            body: JSON.stringify({ url }),
                        });
    
                        const responseData = await status_resp.json();
    
                        if (status_resp.ok && responseData.data) {
                            const { status } = responseData.data;
                            setThreadFiles(prevThreadFiles =>
                                prevThreadFiles.map(prevFile =>
                                    prevFile.fileId === newFileId
                                        ? { ...prevFile, status, url }
                                        : prevFile
                                )
                            );
    
                            if (status === "completed" || status === "failed") {
                                setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
                                clearInterval(interval);
                            }
                        } else {
                            setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
                            clearInterval(interval);
                        }
                    } catch (error) {
                        console.error('Error fetching status:', error);
                        setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
                        clearInterval(interval);
                    }
                }, 3000);
            } else {
                console.error('Invalid response from uploadFiles:', response);
                setThreadFiles(prevThreadFiles =>
                    prevThreadFiles.map(prevFile =>
                        prevFile.fileId === file.fileId
                            ? { ...prevFile, status: "failed" }
                            : prevFile
                    )
                );
            }
        } catch (error) {
            console.error('Error during file upload:', error);
            setThreadFiles(prevThreadFiles =>
                prevThreadFiles.map(prevFile =>
                    prevFile.fileId === file.fileId
                        ? { ...prevFile, status: "failed" }
                        : prevFile
                )
            );
        }
    
        setWaitThread(false);
    };

    const toggleRemoveFileFun =(index) =>{
        setToggleRemoveFile(!toggleRemoveFile)
        setClickIndex(index)
        
     

    }
    // console.log(toggleRemoveFile  ,'rammmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm')
    const handleClickOutside = () => {
            setToggleRemoveFile(false); // Close the dropdown if clicked outside
 
        
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

      const findUrl = (msg) => {
        const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
        const match = urlRegex.exec(msg);
        return match ? match[1] : null;
      };
      
      const getFileFormat = (url) => {
        // Extract the file extension from the URL
        const parts = url.split('.');
        if (parts.length > 1) {
          return parts[parts.length - 1]; // Return the last part as the file format
        } else {
          return ''; // Return empty string if no file extension found
        }
      };

       const handleDeleteFile =async (file) =>{
          try{
            const id = file?.fileId || file?.id;
            if (file?.fileId){
              const number = id.match(/\d+/)[0];
              const response  = await DeleteUploadFileFromS3(number)
              if(response?.status === 200){
                const newfiles = threadFiles.filter((ele)=>{
                  return ele.fileId !== file.fileId
                })
                setThreadFiles(newfiles);
              }
            }else if(file?.id){
              const number = id.match(/\d+/)[0];
              // console.log(number, 'delete file');
              const response  = await DeleteUploadFileFromS3(number)
            if(response?.status === 200){
              const res = await FetchHistoryOfThreadId();
              if(response?.status === 200){
                const updatedArray = res?.data?.data?.history.map((item, index)=>{
                  if (typeof item.agent === 'string' && item.agent.includes('dailylimitexceeded')) {
                    const fileFormat = getFileFormat(findUrl(item.agent));
                    item.agent = `Hi there,\n\nIt looks like you’ve reached your daily limit for generating ${fileFormat} files. We’re really sorry about that! We know how important uninterrupted access is for you.\n\nTo keep enjoying all the great benefits and have a seamless experience, we’d love for you to consider upgrading to our premium plan.\n\nThank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.\n\nWarm regards,\n\nThe ModalX Team`;
                }
                if(item.is_stop){
                  item.agent = "**Response generation was stopped**"
                }
                return item;
                })
                setCurrentThread(updatedArray);
                // setCurrentThread( res?.data?.data?.history);
                setHistoryFiles( res?.data?.data?.input_files || [])
              }
              else{
                setHistoryFiles([])
              }
            }
          }else{
            console.log('fileId is not found')
          }
          }catch(error){
            console.error('Error deleting file:', error);
          }
        }

    return (
        <div style={{backgroundColor:"", maxWidth:"258px", width:'100%', margin:"36px auto auto auto",  }}>   
            <p style={{ fontSize: '14px',paddingLeft:"8px" }}>Your Files ({filesLen})</p>
            {files?.length ? (
                <ul style={{ margin:"0px", padding:"0px" }}>
                    {files.map((file, index) => {
                       return (
                       <Box key={index}  onClick={()=>{handleuploadedFileClick(file)}}
                            sx={{
                              display:"flex",
                              flexDirection:"column",
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              listStyleType: 'disc',
                              textAlign: 'start',
                              cursor: 'pointer',
                              alignItems: 'center',
                              fontSize: '16px',
                              color: file.status === 'failed' ? 'red' : '',
                              backgroundColor : '',
                              borderBottom:"1px solid rgba(255, 255, 255, 0.10)",
                              borderTop: index==0 && "1px solid rgba(255, 255, 255, 0.10)",
                              gap:"2px",
                              "&:hover path": {
                                fill: file.status === "failed" ? "red" : "rgba(202, 196, 208, 1)", // Hover color
                              },
                            }}
                            title={file.name}
                        >
                         
                            <Box sx={{display: 'flex', margin:"4px 0px",justifyContent: 'space-between', alignItems:"center" ,width: '100%',padding : '8px 8px 8px 8px',borderRadius:"8px",backgroundColor : 'none',
                            "&:hover": {
                                          backgroundColor: "rgba(31, 35, 62, 1)",
                                        },
                            }}>
                        <div 
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                          color: "#CAC4D0",
                        }}
                      >
                        {(file?.name.includes(".jpeg") ||
                          file?.name.includes(".png") ||
                          file?.name.includes(".jpg")) && (
                          <img
                            src={imageIcon}
                            alt="I"
                            style={{ width: "20px", height: "20px" }}
                          />
                        )}
                        {file?.name.includes(".mp3") && (
                          <img
                            src={mp3_logo}
                            alt="I"
                            style={{ width: "20px", height: "20px" }}
                          />
                        )}
                        {file?.name.includes(".mp4") && (
                          <img
                            src={mp4Icon}
                            alt="I"
                            style={{ width: "20px", height: "20px" }}
                          />
                        )}
                        {file?.name.includes(".mov") && (
                          <img
                            src={movIcon}
                            alt="I"
                            style={{ width: "20px", height: "20px" }}
                          />
                        )}
                        {file?.name.includes(".pdf") && (
                          <img
                            src={Pdf_Icon}
                            alt="I"
                            style={{ width: "20px", height: "20px" }}
                          />
                        )}
                        {(file?.name.includes(".pptx") ||
                          file?.name.includes("pptm")) && (
                          <img
                            src={powerpoint_logo}
                            alt="I"
                            style={{ width: "20px", height: "20px" }}
                          />
                        )}
                        {(file?.name.includes("docx") ||
                          file?.name.includes("doc")) && (
                          <img
                            src={word_logo}
                            alt="I"
                            style={{ width: "20px", height: "20px" }}
                          />
                        )}
                        {(file?.name.includes(".xlsx") ||
                          file?.name.includes(".xls")) && (
                          <img
                            src={Xlsx_Icon}
                            alt="I"
                            style={{ width: "20px", height: "20px" }}
                          />
                        )}

                        {/* {file.name.length > 14
                          ? `${file.name.substring(0, 14)}...`
                          : file.name} */}
                        <div style={{display:"flex", flexDirection:"row"}}>
                        <div style={{maxWidth:"10rem", overflow:"hidden"}}>{ `${file.name}`} </div>
                        <div>...</div></div>
                      </div>
                                {/* <div>
                                    <img
                                        src={getFileIcon(file.name)}
                                        alt=""
                                        style={{ marginRight: '8px', width: '20px', height: '20px' }}
                                    />
                                    {file.name.length > 14 ? `${file.name.substring(0, 14)}...` : file.name}
                                </div> */}
                                {/* <div  onClick={() => toggleRemoveFileFun(index)} style={{}}>
                                    {file.status === "uploading" || file.status === "processing" ? (
                                        
                                        <CircularProgress  size={20} style={{ marginLeft: '8px' , color : 'white'}} />
                                    ) : file.status === "failed" ? (
                                        <IconButton onClick={() => handleRetryFile(file)} style={{height:'10px',color:'red',marginLeft:'25px'}}>
                                            <ReplayIcon />
                                        </IconButton>
                                    ) : (
                                        <MoreHorizIcon />
                                    )}
                                </div> */}
                                <div onClick={() => toggleRemoveFileFun(index)} style={{backgroundColor : '', width:'20px', height:"20px" }}>
                                    {file.status === "uploading" || file.status === "processing" ? (
                                        // <CircularProgress size={20} style={{ marginLeft: '8px', color: 'white' }} />
                                        <CircularProgress/>
                                    
                                    ) : file.status === "failed" ? (
                                        <IconButton onClick={() => handleRetryFile(file)} style={{ height: '10px',width:'20px', color: 'red', marginRight:"20px", marginBottom:"5px" }}>
                                            <ReplayIcon />
                                        </IconButton>
                                    ) : showCompleteIcon[file.url] ? (
                                        // Show the complete icon if the file is completed
                                        // <CompleteIcon style={{ marginLeft: '8px', color: 'green' }} />
                                        <img src={completeIcon} alt=""  width='20px' height='20px'/>
                                    ) : (
                                        <Tooltip
                                        componentsProps={{
                                          tooltip: {
                                            sx: {
                                              bgcolor: "#E6E0E9",
                                              color: "#17182C",
                                              fontFamily: "Inter",
                                              fontSize: "12px",
                                              fontWeight: 500,
                                              padding: "4px 8px 4px 8px",
                                              borderRadius: "4px",
                                              textAlign: "center",
                                            },
                                          },
                                        }}
                                        title={`Remove file`}
                                      >
                                        <Box 
                                          sx={{
                                            display:"flex",
                                            width: "20px",
                                            height: "20px",
                                            "& path": {
                                              fill: "none", // Default color
                                            },
                                            "&:hover path": {
                                              fill: "rgba(255, 255, 255, 1)", // Hover color
                                            },
                                          }}
                                          onClick={(event)=>{handleDeleteFile(file); event.stopPropagation(); }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20px"
                                            height="20px"
                                            viewBox="0 0 14 16"
                                            fill="none"
                                          >
                                            <path
                                              d="M2.8335 15.5C2.37516 15.5 1.9828 15.3368 1.65641 15.0104C1.33002 14.684 1.16683 14.2917 1.16683 13.8333V3H0.333496V1.33333H4.50016V0.5H9.50016V1.33333H13.6668V3H12.8335V13.8333C12.8335 14.2917 12.6703 14.684 12.3439 15.0104C12.0175 15.3368 11.6252 15.5 11.1668 15.5H2.8335ZM11.1668 3H2.8335V13.8333H11.1668V3ZM4.50016 12.1667H6.16683V4.66667H4.50016V12.1667ZM7.8335 12.1667H9.50016V4.66667H7.8335V12.1667Z"
                                            />
                                          </svg>
                                          {/* <CardMedia component="img" sx={{color:"red", '&:hover':{
                                              display:""
                                            }}} image={deleteIcon} alt="delete icon" /> */}
                                        </Box>
                                      </Tooltip>
                                        // <MoreHorizIcon />
                                    )}
                                </div>
                                
                                {/* {toggleRemoveFile ? clickIndex === index && <RemoveFileModal/> : ''} */}

                                
                            </Box>
                            {/* <Box sx={{borderBottom:"1px solid rgba(255, 255, 255, 0.10)",width: '94%', padding:"0px", margin:"0px"}}> </Box> */}
                        </Box>)
                    })}
                </ul>
            ) : null }
            <PreviewMediaModalForUploadedFiles url={url} showModal={showModalUploadFile} handleCloseModal={handleCloseModal}/>
        </div>
    );
};

export default ViewThreadFiles;






//=========================== Below one is the updated code on till 29-07-2024 (including handleRetry)===========================================





// import React, { useEffect, useState } from 'react';
// import { ProgressBar } from 'react-bootstrap';
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import sourceUrl from "../../../api/Source.api";
// import Youtube_Icon from "../../../assets/File_Icon/youtube_icon.png";
// import Xlsx_Icon from "../../../assets/File_Icon/xlsx_icon.png";
// import Pdf_Icon from "../../../assets/File_Icon/pdf_icon.png";
// import { CircularProgress } from '@mui/material';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


// const ViewThreadFiles = () => {
//     const { threadId, historyFiles, threadFiles, setThreadFiles, InvokeMatrixApi } = useWorkspace();
//     const [progressStatusChecking, setProgressStatusChecking] = useState({});
//     const token = localStorage.getItem('token');
//     const [filesLen, setFilesLen] = useState(0);

//     const id = threadId && parseInt(threadId.replace("thread-", ""), 10);

    
    

//     // Ensure both historyFiles and threadFiles are arrays
//     const files = [...(threadFiles ? [...threadFiles].reverse() : []), ...(historyFiles || [])];

//     useEffect(() => {
//         const intervals = files.map((file) => {
//             if (file.status === "uploading" || file.status === "processing") {
//                 setProgressStatusChecking((prev) => ({ ...prev, [file.url]: true }));

//                 const interval = setInterval(async () => {
//                     try {
//                         const status_resp = await fetch(`${sourceUrl}/status`, {
//                             method: "POST",
//                             headers: {
//                                 "Content-Type": "application/json",
//                                 "Authorization": `Bearer ${token}`
//                             },
//                             body: JSON.stringify({ threadId, url: file.url }),
//                         });
//                         const responseData = await status_resp.json();

//                         if (status_resp.status === 200 && responseData.data.status === "completed") {
//                             InvokeMatrixApi(responseData, status_resp, 'POST', 'status', { threadId, url: file.url });

//                             setThreadFiles((prevThreadFiles) =>
//                                 prevThreadFiles.map((prevFile) =>
//                                     prevFile.name === file.name
//                                         ? { ...prevFile, status: "completed" }
//                                         : prevFile
//                                 )
//                             );

//                             setProgressStatusChecking((prev) => ({ ...prev, [file.url]: false }));
//                             clearInterval(interval);
//                         } else if (status_resp.status === 200 && responseData.data.status === "failed") {
//                             setThreadFiles((prevThreadFiles) =>
//                                 prevThreadFiles.map((prevFile) =>
//                                     prevFile.name === file.name
//                                         ? { ...prevFile, status: "failed" }
//                                         : prevFile
//                                 )
//                             );

//                             setProgressStatusChecking((prev) => ({ ...prev, [file.url]: false }));
//                             clearInterval(interval);
//                         } else if (status_resp.status === 500) {
//                             setProgressStatusChecking((prev) => ({ ...prev, [file.url]: false }));
//                             clearInterval(interval);
//                         }
//                     } catch (error) {
//                         console.error("Error checking file status:", error);
//                         setProgressStatusChecking((prev) => ({ ...prev, [file.url]: false }));
//                         clearInterval(interval);
//                     }
//                 }, 3000);

//                 return interval;
//             }

//             return null;
//         });

//         // Cleanup intervals on unmount
//         return () => {
//             intervals.forEach((interval) => {
//                 if (interval) clearInterval(interval);
//             });
//         };
//     }, [files, threadId, token, setThreadFiles, InvokeMatrixApi]);

//     useEffect(() => {
//     setFilesLen(files.length);
//     }, [files])
    
//     const getFileIcon = (fileName) => {
//         if (fileName.includes("youtube")) return Youtube_Icon;
//         if (fileName.endsWith(".pdf")) return Pdf_Icon;
//         if (fileName.endsWith(".xlsx") || fileName.endsWith(".xls")) return Xlsx_Icon;
//         return null;
//     };

//     return (
//         <div>
//             <p style={{ fontSize: '14px'}}>Your Files ({filesLen})</p>
//             {files.length ? (
//                 <ul style={{ paddingInlineStart: '1em'}}>
//                     {files.map((file, index) => (
//                         <li
//                             key={index}
//                             style={{
//                                 overflow: 'hidden',
//                                 textOverflow: 'ellipsis',
//                                 whiteSpace: 'nowrap',
//                                 listStyleType: 'disc',
//                                 textAlign: 'start',
//                                 cursor: 'pointer',
//                                 display: 'flex',
//                                 alignItems: 'center',
//                                 fontSize:'16px'
//                             }}
//                             title={file.name}
//                         >
//                             <div style={{display:'flex',justifyContent:'space-between',width:'280px',color:file.status === 'failed'?'red':''}}>
//                                 <div>
//                                     <img 
//                                         src={getFileIcon(file.name)} 
//                                         alt="" 
//                                         style={{ marginRight: '8px', width: '20px', height: '20px' }} 
//                                     />
//                                     {file.name.length > 14 ? `${file.name.substring(0, 14)}...` : file.name}
//                                 </div>
//                                 <div>
//                                     {(file.status === "uploading" || file.status === "processing") && progressStatusChecking[file.url] ? (
//                                         <CircularProgress size={20} style={{ marginLeft: '8px' }} />
//                                     ) : (
//                                         <MoreHorizIcon />
//                                     )}
//                                 </div>
//                             </div>
//                         </li>
//                     ))}
//                 </ul>
//             ) : (
//                 // <p style={{ fontSize: '.9rem', color: 'grey', textAlign: 'center' }}>No files uploaded</p>
//                 <></>
//             )}
//         </div>
//     );
// };

// export default ViewThreadFiles;

