import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { Outlet, Navigate } from "react-router-dom";
import Home from "../pages/Home";

// const PublicRoute = () => {
//   const { user } = useAuth();
//   return user ? <Home /> : <Outlet />;
// };




const PublicRoute = () => {
  const { user } = useAuth();
  // return user ? <Navigate to="/workspace" replace /> : <Outlet />;
  return <Outlet />;
};

export default PublicRoute;