import React, { useEffect, useState, useRef } from "react";
import {
  Button as MuiButton,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  LinearProgress,
  Tooltip,
  Box,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import FileUploader from "../settingBar/FileUploader";
import LinkUploader from "../settingBar/LinkUploader";
import videoIcon from "../../../assets/Button/videoIcon.png";
import settingIcon from "../../../assets/Button/settings.png";
import musicIcon from "../../../assets/Button/Music Library.png";
import pictureIcon from "../../../assets/Button/Picture.png";
import presentationIcon from "../../../assets/Button/presentation.png";
import {
  button_bg_color_for_New_Project,
  fileUploader_bg_color_inside_onclickModal,
  hoveredSettings_button_bg_color,
  oneClickModal_backgroundColor_dm,
  selecetedSettings_Nav_bg_dm,
  uploadFiles_button_default_bg_color,
  uploadFiles_button_hovered_bg_color,
} from "../../../constants/colors";
import ViewThreadFiles from "../settingBar/ViewThreadFiles";
import {
  trackContentGeneration,
  trackModalOpen,
} from "../../../config/analytics";
import { Navigate, useNavigate } from "react-router-dom";
import { useCreditRecharge } from "../../../contexts/CreditRechageContext";
import { VideoGenAPISettings } from "../settingBar/VideoGenSettings/VideoGenAPISettings";
import SettingsButtonBelowImagePreview from "../../SettingsButtonBelowImagePreview";
import { FileUpload, trackFileUpload } from "../../../config/analytics";
import sourceUrl from "../../../api/Source.api";
import { containsMediaFile, InvokeMatrixApi } from "../../../contexts/WorkspaceUtils";
import * as Sentry from "@sentry/react";
import ViewThreadFilesInOneClickGenerateModal from "../settingBar/ViewThreadFilesInOneClickGenerateModal";
import SettingsOptionInOneClickGenerateModal from "../settingBar/SettingsOptionInOneClickGenerateModal";
import { useDailyLimitExceed } from "../../../contexts/DailyLimitExceedContext";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { New_Project_Button_bg_color } from "../../../Provider/Color_Provider";
// import { Button } from 'react-bootstrap';
import { useAuth } from "../../../contexts/AuthContext";
import VideoTutorialModal from "./VideoTutorialModal";
import createimagetutorial from '../../../assets/VideoTutorial/CreateImageTutorial.mp4'
import createvideotutorial from '../../../assets/VideoTutorial/CreateVideoTutorial.mp4'
import createaudiotutorial from '../../../assets/VideoTutorial/CreateAudioTutorial.mp4'
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { fetchAvatars, fetchBackgrounds } from "../settingBar/synthesiaSettings/videoSettingsAPI";

const ServicesForDefaultWindow = () => {
  const {
    threadFiles,
    setThreadFiles,
    sendMessage,
    setShowImageSettings,
    setCurrentThread,
    setWaitThread,
    setSending,
    historyFiles,
    showVideoGenSettings,
    setShowVideoSettings,
    showVideoSettings,
    showImageSettings,
    showPptSettings,
    showAudioSettings,
    setShowPptSettings,
    setShowAudioSettings,
    currentThread,
    waitThread,
    setShowVideoGenSettings,
    setGenVideoLanguage,
    setGenVideoAudioSettingsLoading,
    setGenVideoVoice,
    setGenVideoVoicesLoading,
    setCaptionFontForVideoGen,
    genVideoLanguage,
    genVideoVoice,
    selectedVideoGenerationPlatform,
    upload_FilesOnly,
    threadId,
    stopMessageIdRef, setVisibleStopGenerationButton,
    showTutorialModal, setShowTutorialModal,
    setBackgrounds,backgrounds,
    avatars,setAvatars, setAvatarsLoading

  } = useWorkspace();
  const {setShowSignUpModal,setShowLoginModal} = useAuth();
  const theme = localStorage.getItem("theme");
  const [currText, setCurrText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(""); // State for modal title
  const [files, setFiles] = useState([]);
  const [filesLen, setFilesLen] = useState(0);
  const [fileProcessing, setFileProcessing] = useState(false);
  const [history_Files, setHistoryFiles] = useState([]);
  const token = localStorage.getItem("token");
  const{setDailylimitExceed} = useDailyLimitExceed()
  const isBelow1500 = useMediaQuery("(max-width:1500px)");
  const navigate = useNavigate();
  const [close, setClose] = useState(true);
  const { showCreditRecharge, setShowCreditRecharge } = useCreditRecharge();
  const [videoGenerationPlatform, setVideoGenerationPlatform] = useState("");
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progressStatusChecking, setProgressStatusChecking] = useState({});
  const [fileUploaded, setFileUploaded] = useState(false);
  const [notificationToastMessage, setNotificationToastMessage] = useState("");
  const [keyForSettingOpenInModal, setKeyForSettingOpenInModal] =
    useState(null);
  const [showAllUplodedFiles, setShowAllUplodedFiles] = useState(false);
  const email = localStorage.getItem("userEmail");
  const fileInputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const boxRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const [showSignUpToUnlock,setShowSignUpToUnlock] = useState(false);
  const [Title, setTitle] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);



  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleFileChange({ target: { files } });
    }
  };

  const onClose = () => {
    setClose(false);
  };

  // useEffect(() => {
  //   setVideoGenerationPlatform((prev) => prev === 'voice-over'?'presenter':'voice-over')
  // }, [selectedVideoGenerationPlatform])

  const handleFileChange = async (event) => {
    try {
      setWaitThread(true);
      const files = Array.from(event.target.files); // Convert FileList to Array
      const existingFileNames = threadFiles.map((file) => file.name);

      // Filter out files that already exist in threadFiles by their names
      const newFiles = files.filter(
        (file) => !existingFileNames.includes(file.name)
      );

      // Clear the file input after selecting files
      event.target.value = "";

      // Map files to upload promises
      const uploadPromises = newFiles.map(async (file) => {
        setLoading(true); // Show loader

        // Map each file to the prescribed JSON format and mark as "uploading"
        const fileToUpdate = {
          type: "file",
          file: file,
          name: file.name,
          status: "uploading", // Mark as "uploading" initially
          fileId: null,
          url: null, // Add a placeholder for the URL
        };

        // Update threadFiles with the new file object
        setThreadFiles((prevThreadFiles) => [...prevThreadFiles, fileToUpdate]);

        // Upload the file and return fileId
        const response = await upload_FilesOnly(fileToUpdate);
        const fileId = response.data.id;
        const url = response.data.url;

        // console.log('here is the url that sent before check kkkkkkkkkkkkkkkkkkkkkkkkk',response)
        // After uploading file event triggred for file uploaded

        const fileType = files[0].type;
        const fileCount = threadFiles.length + 1;
        const file_method = "Click to Upload";
        trackFileUpload("file_upload", fileType, fileCount, file_method);
        FileUpload(fileType, fileCount);

        // Update the file object with uploaded state or upload failed
        setThreadFiles((prevThreadFiles) =>
          prevThreadFiles.map((prevFile) =>
            prevFile.name === file.name
              ? {
                  ...prevFile,
                  status: response.data.status, // Update status
                  fileId: fileId, // Update fileId
                  url: url, // Update URL
                }
              : prevFile
          )
        );

        // Start checking progress status
        setProgressStatusChecking((prev) => ({ ...prev, [url]: true }));

        // Poll progress status every 3 seconds
        const interval = setInterval(async () => {
          // const progressStatus = await checkUrlStatus(url);
          const status_resp = await fetch(`${sourceUrl}/status`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ threadId, url }),
          });
          const responseData = await status_resp.json();
          if (
            status_resp.status === 200 &&
            responseData.data.status === "completed"
          ) {
            // ==========================Invoke matrix api ===================================================
            InvokeMatrixApi(
              responseData,
              status_resp,
              "POST",
              "status",
              { threadId, url },
              email
            );
            // ===============================================================================================
            setThreadFiles((prevThreadFiles) =>
              prevThreadFiles.map((prevFile) =>
                prevFile.name === file.name
                  ? {
                      ...prevFile,
                      status: "completed", // Update status
                      fileId: fileId, // Update fileId
                      url: url, // Update URL
                    }
                  : prevFile
              )
            );

            setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
            clearInterval(interval); // Stop interval
            setFileUploaded(true);
            setNotificationToastMessage("File uploaded successfully");
          } else {
            if (
              status_resp.status === 200 &&
              responseData.data.status === "failed"
            ) {
              setThreadFiles((prevThreadFiles) =>
                prevThreadFiles.map((prevFile) =>
                  prevFile.name === file.name
                    ? {
                        ...prevFile,
                        status: "failed",
                        fileId: "", // Update fileId
                        url: url, // Update URL
                      }
                    : prevFile
                )
              );
              // ==========================Invoke matrix api ===================================================
              InvokeMatrixApi(
                responseData,
                status_resp,
                "POST",
                "status",
                { threadId, url },
                email
              );
              // ===============================================================================================
              setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
              clearInterval(interval); // Stop interval
            } else if (status_resp.status === 500) {
              setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
              clearInterval(interval); // Stop interval
            }
            // setFileUploaded(true);
            // setNotificationToastMessage('There was an error while uploading the file. Please try again')
          }
        }, 3000);

        // Return updated file object with fileId
        return fileId
          ? { ...fileToUpdate, status: "uploaded", fileId: fileId }
          : { ...fileToUpdate, status: "failed" };
      });

      // Wait for all uploads to finish
      await Promise.all(uploadPromises);

      setLoading(false); // Hide loader
      setWaitThread(false);
      // triggredFileUploadEvent(event)
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error while handling file change:", error);
      setFileUploaded(true);
      setNotificationToastMessage(
        "There was an error while uploading the file. Please try again"
      );
    }
  };

  useEffect(() => {
    if (historyFiles) {
      setHistoryFiles(historyFiles);
    }
  }, [historyFiles]);

  const handleOneClick = async (text, title) => {
    setCurrText(text);
    setModalTitle(title); // Set the modal title
    setShowModal(true);
    trackModalOpen(title);
  };

  useEffect(() => {
    setFilesLen(files.length);
  }, [files]);

  useEffect(() => {
    setFiles([...threadFiles, ...historyFiles]);
    setFilesLen(files.length);
  }, [threadFiles, historyFiles]);

  // console.log(files, "okkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
  useEffect(() => {
    const isAnyFileProcessing = files.some(
      (file) =>
        file &&
        file.status &&
        file.status !== "completed" &&
        file.status !== "failed"
    );
    if (isAnyFileProcessing) {
      setFileProcessing(true);
    } else {
      setFileProcessing(false);
    }
  }, [files]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseSignToUnlockModal = () => {
    setShowSignUpToUnlock(false);
  }

  const handleSend = () => {
    handleSubmit(currText);
    setShowModal(false);
    console.log(currText);
    // trackContentGeneration("Image", currText)
  };

  const findUrl = (msg) => {
    const urlRegex =
      /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
    const match = urlRegex.exec(msg);
    return match ? match[1] : null;
  };

  const getFileFormat = (url) => {
    // Extract the file extension from the URL
    const parts = url.split(".");
    if (parts.length > 1) {
      return parts[parts.length - 1]; // Return the last part as the file format
    } else {
      return ""; // Return empty string if no file extension found
    }
  };

  const handleSubmit = async (input) => {
    setSending(true);
    setVisibleStopGenerationButton(true)
    setWaitThread(true);
    if (input.trim() && !fileProcessing) {
      const latestEntry = { sender: "user", content: input };
      setCurrText("");
      setCurrentThread((prevThread) => [...prevThread, latestEntry]);
      const entryResponse = await sendMessage(latestEntry).catch(console.error);
      if (entryResponse) {
        // Check if entryResponse contains a daily limit exceeded message
        if (
          entryResponse &&
          entryResponse.content &&
          findUrl(entryResponse.content) &&
          findUrl(entryResponse.content).includes("dailylimitexceeded")
        ) {
          // Determine the file format from the URL (assuming findUrl() returns the URL)
          const fileFormat = getFileFormat(findUrl(entryResponse.content));

          // Prepare the response message
          const responseMessage = {
            content:
              `Hi there,\n\nIt looks like you’ve reached your daily limit for generating ` +
              fileFormat +
              ` files. We’re really sorry about that! We know how important uninterrupted access is for you.\n\nTo keep enjoying all the great benefits and have a seamless experience, we’d love for you to consider upgrading to our premium plan.\n\nThank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.\n\nWarm regards,\n\nThe ModalX Team`,
            sender: "modalX",
          };

          // Update the current thread with the response message
          setCurrentThread((prevThread) => [...prevThread, responseMessage]);
          // setShowCreditRecharge(true);
          if(isLoggedIn === null || isLoggedIn === ""){
            setDailylimitExceed(true);
          }else{
            setShowCreditRecharge(true)
          }
        }else if(stopMessageIdRef.current.includes(entryResponse.unique_id)){
          setCurrentThread((prevThread)=>[...prevThread ])
          setVisibleStopGenerationButton(false);
        }
         else {
          // If the response does not contain a daily limit exceeded message, proceed as usual
          // setCurrentThread((prevThread) => [...prevThread, entryResponse]);
          if(containsMediaFile(entryResponse.content)){
            setCurrentThread((prevThread) => [...prevThread, entryResponse]);
          }else{
            setCurrentThread((prevThread) => [...prevThread, entryResponse]);
            setVisibleStopGenerationButton(false);
          }
        }
        setSending(false);
      } else {
        // If there's no entryResponse, indicate processing failure
        setCurrentThread((prevThread) => [
          ...prevThread,
          { content: "Unable to process your query!..", sender: "modalX" },
        ]);
        setSending(false);
        setVisibleStopGenerationButton(false);
      }
    }
    setWaitThread(false);
  };
  // fetchVideoSettings()
  useEffect(() => {
    if (genVideoLanguage || genVideoVoice) {
      VideoGenAPISettings(
        setGenVideoLanguage,
        setGenVideoAudioSettingsLoading,
        setGenVideoVoice,
        setGenVideoVoicesLoading,
        setCaptionFontForVideoGen
      );
    }
  }, []);
  useEffect( ()=>{
  async function fetchUserInfo(){
      if(backgrounds){
        await fetchBackgrounds(setBackgrounds)
      }
      if(avatars){
        await fetchAvatars(setAvatars, setAvatarsLoading)
      }
   }
   fetchUserInfo()
  },[])

  const handleSettingsClick = async (title) => {
    if (title === "Video_Settings") {
      setShowVideoSettings(true);
      trackModalOpen(title);
    } else if (title === "Image_Settings") {
      setShowImageSettings(true);
      trackModalOpen(title);
    } else if (title === "Presentation_Settings") {
      setShowPptSettings(true);
      trackModalOpen(title);
    } else if (title === "Audio_Settings") {
      setShowAudioSettings(true);
      trackModalOpen(title);
    }
    // else if(title  === 'VideoGen_Settings'){ //title  === 'VideoGen_Settings'
    //     // console.log("VideoGen_Settings")
    //     setShowVideoGenSettings(true);
    //     trackModalOpen(title)
    // }
  };

  const buttonStyle = {
    backgroundColor: "#1F233E",
    position: "relative",
    fontSize: isBelow1500 ? "13px" : "1rem",
    transition: "transform 0.3s",
    padding: isBelow1500 ? "3px 0px 3px 0px" : "3px 10px 3px 20px",
    gap: "10px",
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    width: isBelow1500 ? "130px" : "150px",
    height: "40px",
    color: theme === "dark" ? "white" : "",
  };

  const settingButtonStyle = {
    backgroundColor: "#1F233E",
    position: "relative",
    fontSize: "1rem",
    transition: "transform 0.3s",
    padding: "9px",
    gap: "10px",
    borderLeft: "1px solid #4C4F65",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    // width:'36px',
    height: "40px",
  };

  const handleMouseEnter = (e) => {
    // e.currentTarget.style.color = 'blue';
    e.currentTarget.style.backgroundColor = "#282D51";
  };

  const handleMouseLeave = (e) => {
    // e.currentTarget.style.transform = 'scale(1)';
    // e.currentTarget.style.color = theme === 'light' ? 'black' : 'white'; // Adjust color here
    e.currentTarget.style.backgroundColor = "#1F233E";
  };

  const handleMouseLeaveForAudioSettings = (e) => {
    // e.currentTarget.style.transform = 'scale(1)';
    // e.currentTarget.style.color = theme === 'light' ? 'black' : 'white'; // Adjust color here
    e.currentTarget.style.backgroundColor = "#282D51";
  };

  const Datas = [
    {
      id: 1,
      title1: "Generate a image",
      style1: buttonStyle,
      handleOneClick: {
        text: "Generate an image of ",
        title: "Generate Image",
      },
      handleMouseEnter: handleMouseEnter,
      handleMouseLeave: handleMouseLeave,
      image1: pictureIcon,
      image1Title: "Picture",
      style2: settingButtonStyle,
      handleSettingsClick: "Image_Settings",
      image2: settingIcon,
      videoSrc:createimagetutorial,
      title:"Generate Image"
    },

    {
      id: 5,
      title1: "Generate a video",
      style1: buttonStyle,
      handleOneClick: {
        text: "Generate a video of ",
        title: "Generate Video",
      },
      handleMouseEnter: handleMouseEnter,
      handleMouseLeave: handleMouseLeave,
      image1: videoIcon,
      image1Title: "Video",
      style2: settingButtonStyle,
      handleSettingsClick: "Video_Settings",
      image2: settingIcon,
      videoSrc:createvideotutorial,
      title: "Generate Video",
    },

    // {id : 2 , title1 : 'A video that inspires', style1 : buttonStyle , handleOneClick : {text : 'Shrink the transcribed content from various sources, such as videos, audio recordings, and PDFs, to within 3000 words. Keep the points concise and generate an image from the summary accordingly.' ,title : 'Generate Video'}, handleMouseEnter : handleMouseEnter, handleMouseLeave : handleMouseLeave , image1 :videoIcon , image1Title : 'Video', style2 : settingButtonStyle, handleSettingsClick :'Video_Settings',image2 : settingIcon },

    {
      id: 3,
      title1: "Generate a presentation",
      style1: buttonStyle,
      handleOneClick: {
        text: "Generate a presentation of ",
        title: "Generate Presentation",
      },
      handleMouseEnter: handleMouseEnter,
      handleMouseLeave: handleMouseLeave,
      image1: presentationIcon,
      image1Title: "Presentation",
      style2: settingButtonStyle,
      handleSettingsClick: "Presentation_Settings",
      image2: settingIcon,
      videoSrc:null,
      title: "Generate Presentation",
    },
    {
      id: 4,
      title1: "Generate a audio",
      style1: buttonStyle,
      handleOneClick: {
        text: "Generate an audio of ",
        title: "Generate Audio",
      },
      handleMouseEnter: handleMouseEnter,
      handleMouseLeave: handleMouseLeave,
      image1: musicIcon,
      image1Title: "Audio",
      style2: settingButtonStyle,
      handleSettingsClick: "Audio_Settings",
      image2: settingIcon,
      videoSrc:createaudiotutorial,
      title: "Generate Audio",
    },
  ];

  useEffect(() => {
    //   if(videoGenerationPlatform === "presenter"){
    //     setShowVideoGenSettings(false);
    //     setShowVideoSettings(true);
    //     // trackModalOpen(true)
    //   }
    //   else if(videoGenerationPlatform === "voice-over"){
    //     setShowVideoSettings(false);
    //     setShowVideoGenSettings(true);
    //     // trackModalOpen(true)
    //   }
    setDatas(Datas);
  }, [videoGenerationPlatform]);

  useEffect(() => {
    setVideoGenerationPlatform(selectedVideoGenerationPlatform);
  }, [selectedVideoGenerationPlatform]);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setIsFocused(false); // Set focus to false if clicked outside the Box
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const HandleToolTipClickforVideoModal = ( title ,videoSrc) =>{
    if(title !== "Generate Presentation"){
      setTitle(title)
      setVideoSrc(videoSrc)
      setShowTutorialModal(true)
    }
  }

  // console.log(showTutorialModal, "ShowTutorialModal")

  return (
    <div style={{ lineHeight: "13px", overflow: "hidden" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          position: "relative",
          maxWidth: "930px",
          margin: "auto",
        }}
      >
        {datas.map((e) => (
          <div style={{ display: "flex" }} key={e.id}>
            <Tooltip onClick={HandleToolTipClickforVideoModal}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#E6E0E9",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: 500,
                    padding: "4px 8px 4px 8px",
                    borderRadius: "4px",
                    textAlign: "center",
                  },
                },
              }}
              title={
                <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
                <Box sx={{fontFamily:"Inter", fontStyle:"normal", fontWeight:500, lineHeight:"16px", fontSize:"14px", color:"#17182C"}}>
                  {e.title1}
                </Box>
               {e.title !== "Generate Presentation" &&   <Box
                    sx={{
                      height: "18px", // Adjust as needed
                      width: "1px",
                      backgroundColor: "rgba(0, 0, 0, 0.20)",
                      margin: "0 10px"//Add space around the line
                    }}
                  />}
                {
                 e.title !== "Generate Presentation" && 
                <Box onClick={()=>{HandleToolTipClickforVideoModal(e.title,e.videoSrc)}} sx={{display:"flex", justifyContent:"center", alignItems:"center", color:"rgba(23, 24, 44, 0.7)",fontFamily:"Inter", fontStyle:"normal", fontWeight:500, lineHeight:"16px", fontSize:"12px", cursor:"pointer" ,'&:hover':{color:"rgba(23, 24, 44, 0.9)"}}}>
                <PlayArrowOutlinedIcon/>
                 Watch tutorial
                </Box>
                }
                </Box>
               }
              placement="bottom"
            >
              <MuiButton
                style={buttonStyle}
                onClick={() =>{
                  handleOneClick(
                    e.handleOneClick.text,
                    e.handleOneClick.title,
                    setKeyForSettingOpenInModal(e.handleSettingsClick)
                  )
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{ textTransform: "none" }}
              >
                <img src={e.image1} alt="PictureIcon"  style={{width:"24px", height:"24px"}}/>
                {e.id === 3 ? (
                  <p
                    style={{
                      position: "absolute",
                      fontSize: "8px",
                      top: "2px",
                      right: "3px",
                      color: button_bg_color_for_New_Project,
                      fontStyle: "italic",
                    }}
                  >
                    Beta
                  </p>
                ) : (
                  ""
                )}
                {e.image1Title}
              </MuiButton>
            </Tooltip>
            <MuiButton
              style={settingButtonStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleSettingsClick(e.handleSettingsClick)}
              sx={{ minWidth: isBelow1500 ? "40px" : "60px" }}
            >
              <img src={e.image2} alt="SettingsIcon" style={{width:"16px", height:"16px"}}/>
            </MuiButton>
          </div>
        ))}
      </div>
      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            width: "750px",
            // height: "673px",
            gap: "0px",
            borderRadius: "8px",
            //  border: "1px solid #CAC4D0",
            opacity: "0px",
            bgcolor: "#282D51",
            margin: "",
          },
        }}
      >
        <div
          style={{
            backgroundColor:
              theme === "dark" ? oneClickModal_backgroundColor_dm : "",
            color: theme === "dark" ? "white" : "",
          }}
        >
          {/* section 1 */}
          <Box
            sx={{
              width: "100%",
              height: "68px",
              padding: "20px",
              gap: "10px",
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              opacity: "0px",
            }}
          >
            <Box
              sx={{
                height: "28px",
                opacity: "0px",
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "248px",
                  height: "20px",
                  gap: "0px",
                  opacity: "0px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#FFFFFF",
                  }}
                >
                  {modalTitle}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "28px",
                  height: "28px",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                <CloseIcon
                  onClick={handleCloseModal}
                  aria-label="close"
                  sx={{
                    gap: "0px",
                    opacity: "0px",
                    color: "#FFFFFF",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          {/* section 1 */}

            <DialogContent sx={{ margin: "auto", padding: "20px", border: "" }}>
              {/* section 2 */}

              <Box
                sx={{
                  width: "100%",
                  top: "84px",
                  gap: "16px",
                  opacity: "0px",
                  maxWidth: "710px",
                  margin: "auto",
                  border: "",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "20px",
                    opacity: "0px",
                    marginBottom: "16px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      textAlign: "left",
                      color: "#FFFFFF",
                    }}
                  >
                    Prompt
                  </Typography>
                </Box>

                <Box                 
                  sx={{
                    border: `2px solid ${
                      isFocused
                        ? "rgba(255, 255, 255, 0.4)"
                        : "rgba(255, 255, 255, 0.1)"
                    }`,
                    borderRadius: "8px",
                    backgroundColor: "#232746",
                    width: "100%",
                    height: "140px",
                    overflow: "hidden",
                  }}
                 
                >
                  <TextField
                  inputRef={(boxRef) => {
                    if (boxRef) {
                      boxRef.focus();
                      const length = currText.length; 
                      boxRef.setSelectionRange(length, length);
                    }
                  }}
                    ref={boxRef}
                    id="outlined-basic"
                    variant="outlined"
                    value={currText}
                    onChange={(e) =>{setCurrText(e.target.value)}}
                    onFocus={() => setIsFocused(true)}
                    multiline
                    InputProps={{
                      style: {
                        padding: "20px",
                        color: "#FFFFFF",
                        overflowY: "auto",
                        height: "100%",
                        wordWrap: "break-word",
                        width: "100%",
                        border: "",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "100%", 
                        alignItems: "flex-start", 
                        border: "none", 
                      },
                      "& .MuiInputBase-input": {
                        height: "100%",
                        overflowY: "auto", 
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "red",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "none",
                        border: "none",
                      },
                    }}
                    style={{
                      width: "100%",
                      height: "100%", 
                    }}
                  />
                </Box>
              </Box>
              {/* section 2 */}

              {/* section 3 */}
              <Box>
                {
                  <SettingsOptionInOneClickGenerateModal
                    title={keyForSettingOpenInModal}
                  />
                }
              </Box>
              {/* section 3 */}

              {/* section 4 */}
              <Box
                sx={{
                  display: "flex",
                  marginTop: "30px ",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ border: "" }}>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      letterSpacing: "0.1px",
                      textAlign: "left",
                      color: "#ffffff",
                      textTransform: "none",
                    }}
                  >
                    Upload Files
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "16px",
                      width: "380px",
                      height: "80px",
                      borderRadius: "8px",
                      bgcolor: isDragging ? "#2C2D4B" : "#17182C", // Change color on drag
                      outline: isDragging ? "1px solid #FFF" : "",
                      padding: "20px",
                    }}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <Button
                      onClick={handleFileSelect}
                      variant="contained"
                      sx={{
                        backgroundColor:
                          theme === "dark"
                            ? uploadFiles_button_default_bg_color
                            : "",
                        color: "black",
                        width: "125px",
                        height: "40px",
                        borderRadius: "4px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0px",
                        "&:hover": {
                          backgroundColor: uploadFiles_button_hovered_bg_color,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          height: "40px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: 600,
                            lineHeight: "20px",
                            letterSpacing: "0.1",
                            textAlign: "center",
                            color: "#17182C",
                            gap: "8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textTransform: "none",
                            padding: "10px 0px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="18"
                            viewBox="0 0 19 18"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_7004_2614)">
                              <path
                                d="M15.0125 7.53C14.7606 6.25329 14.0732 5.10364 13.0678 4.27743C12.0624 3.45122 10.8013 2.99971 9.5 3C7.3325 3 5.45 4.23 4.5125 6.03C3.41018 6.14912 2.39075 6.67141 1.65012 7.49649C0.909478 8.32158 0.499871 9.39126 0.5 10.5C0.5 12.9825 2.5175 15 5 15H14.75C16.82 15 18.5 13.32 18.5 11.25C18.5 9.27 16.9625 7.665 15.0125 7.53ZM14.75 13.5H5C3.3425 13.5 2 12.1575 2 10.5C2 8.9625 3.1475 7.68 4.67 7.5225L5.4725 7.44L5.8475 6.7275C6.19248 6.05614 6.71598 5.49296 7.36041 5.09996C8.00483 4.70695 8.74519 4.49935 9.5 4.5C11.465 4.5 13.16 5.895 13.5425 7.8225L13.7675 8.9475L14.915 9.03C15.4788 9.06792 16.0073 9.31799 16.3942 9.72989C16.781 10.1418 16.9975 10.6849 17 11.25C17 12.4875 15.9875 13.5 14.75 13.5ZM6.5 9.75H8.4125V12H10.5875V9.75H12.5L9.5 6.75L6.5 9.75Z"
                                fill="black"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_7004_2614">
                                <rect
                                  width="18"
                                  height="18"
                                  fill="white"
                                  transform="translate(0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <Box sx={{ margin: "0px", padding: "0px" }}>
                            Browse files
                          </Box>
                        </Typography>
                      </Box>
                    </Button>
                    <input
                      id="file-upload"
                      type="file"
                      accept=".txt, .docx, .mp3, .pdf, .mp4, .xlsx, video/*, .jpg, .png, .jpeg"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      multiple
                      onClick={(e) => {
                        if (!isLoggedIn) {
                          e.preventDefault();
                          setShowModal(false);
                          setShowSignUpToUnlock(true);
                        }
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "8px",
                      }}
                      onDrop={(e) => {
                        if (!isLoggedIn) {
                          e.preventDefault();
                          e.stopPropagation();
                          alert("Sign in required to upload files");
                        }
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 600,
                          lineHeight: "normal",
                          letterSpacing: "0.1px",
                          textAlign: "left",
                          color: "#ffffff",
                          textTransform: "none",
                        }}
                      >
                        or drop to upload
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "normal",
                          letterSpacing: "0.1px",
                          textAlign: "left",
                          color: "#ffffff",
                          opacity: "0.8",
                          // textTransform: "none",
                        }}
                      >
                        JPEG, PNG, PDF, XLSX, MOV, MP3
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "8px",
                      alignItems: "center",
                      marginTop: "12px",
                    }}
                  >
                    <Box>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M7.33334 4.66634H8.66668V5.99967H7.33334V4.66634ZM7.33334 7.33301H8.66668V11.333H7.33334V7.33301ZM8.00001 1.33301C4.32001 1.33301 1.33334 4.31967 1.33334 7.99967C1.33334 11.6797 4.32001 14.6663 8.00001 14.6663C11.68 14.6663 14.6667 11.6797 14.6667 7.99967C14.6667 4.31967 11.68 1.33301 8.00001 1.33301ZM8.00001 13.333C5.06001 13.333 2.66668 10.9397 2.66668 7.99967C2.66668 5.05967 5.06001 2.66634 8.00001 2.66634C10.94 2.66634 13.3333 5.05967 13.3333 7.99967C13.3333 10.9397 10.94 13.333 8.00001 13.333Z"
                          fill="#CAC4D0"
                        />
                      </svg>
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: 400,
                        fontStyle: "normal",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        textAlign: "left",
                        color: "#ffffff",
                        textTransform: "none",
                      }}
                    >
                      ModalX will use uploaded files as additional context.
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ border: "", width: "310px" }}>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      letterSpacing: "0.1px",
                      textAlign: "left",
                      color: "#ffffff",
                      textTransform: "none",
                      marginLeft: "20px",
                      marginBottom: "16px",
                    }}
                  >
                    {`Your Files (${filesLen})`}
                  </Typography>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        textAlign: "left",
                        color: "#CAC4D0",
                        textTransform: "none",
                        marginTop: "16px",
                        marginLeft: "20px",
                      }}
                    >
                      {filesLen == 0 ? (
                        "You haven’t uploaded any file yet."
                      ) : (
                        <Box
                          sx={
                            {
                              // height: "115px",
                              // overflowY: "auto",
                            }
                          }
                        >
                          <ViewThreadFilesInOneClickGenerateModal
                            showAllUplodedFiles={showAllUplodedFiles}
                            setShowAllUplodedFiles={setShowAllUplodedFiles}
                          />
                        </Box>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* section 4 */}
            </DialogContent>
          
          {/* section 6 button part  Generate and Close Button*/}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                // height: "72px",
                bgcolor: "#282D51",
                border: "",
                boxShadow: "0px -4px 8px 0px rgba(0, 0, 0, 0.12)",
                padding: "16px 20px",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Box
                sx={{
                  // width: "230px",
                  // height: "40px",
                  right: "16px",
                  top: "16px",
                  gap: "16px",
                  opacity: "0px",
                  display: "flex",
                  justifyContent: "space-between",
                  border: "",
                }}
              >
                <DialogActions sx={{padding:"0px"}}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      border: "",
                      gap: "16px",
                      padding:"0px"
                    }}
                  >
                    <Button
                      onClick={handleCloseModal}
                      variant="contained"
                      sx={{
                        backgroundColor:
                          theme === "dark"
                            ? uploadFiles_button_default_bg_color
                            : "",
                        color: "black",
                        padding: "10px 24px",
                        height: "40px",
                        borderRadius: "4px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "&:hover": {
                          backgroundColor: uploadFiles_button_hovered_bg_color,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: "45px",
                          height: "20px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "20px",
                            letterSpacing: "0.1",
                            textAlign: "center",
                            color: "#17182C",
                            textTransform: "none",
                          }}
                        >
                          Close
                        </Typography>
                      </Box>
                    </Button>
                    <Button
                      onClick={handleSend}
                      variant="contained"
                      // disabled={
                        // fileProcessing || (files.length === 0 && history_Files.length === 0)
                        // fullPrompt === ""
                      // }
                      sx={{
                        // backgroundColor:
                        //   fullPrompt === "" ? "grey"
                        //     : button_bg_color_for_New_Project,
                        backgroundColor: button_bg_color_for_New_Project,
                        height: "40px",
                        padding: "10px 24px",
                        borderRadius: "4px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "&:hover": {
                          backgroundColor: "#FFD9AA",
                        },
                        color: "black",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: 600,
                          lineHeight: "20px",
                          letterSpacing: "0.1px",
                          textAlign: "center",
                          color: "#17182C",
                          textTransform: "none",
                        }}
                      >
                        {modalTitle}
                      </Typography>
                    </Button>
                  </Box>
                </DialogActions>
              </Box>
            </Box>
          
        </div>
      </Dialog>

      <Dialog
        open={showSignUpToUnlock}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            width:'480px',
            // height: "673px",
            gap: "0px",
            borderRadius: "8px",
            //  border: "1px solid #CAC4D0",
            opacity: "0px",
            bgcolor: "#1F233E",
            margin: "",
          },
        }}
      >
        <IconButton
          onClick={handleCloseSignToUnlockModal}
          sx={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            color: 'white', // Adjust color based on theme
          }}
        >
          <CloseIcon />
        </IconButton>
        <div
          style={{
            backgroundColor:
              theme === "dark" ? '#1F233E' : "",
            color: theme === "dark" ? "white" : "",
            width:'480px',
            height:'376px',
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
          }}
        >
          <Box
            sx={{
              width: '60px',
              height: '60px',
              bgcolor: '#282D51',
              borderRadius: '50%',
              display: 'flex', // Enables Flexbox
              justifyContent: 'center', // Centers horizontally
              alignItems: 'center', // Centers vertically
              marginTop:'40px'
            }}
          >
            <FileUploadOutlinedIcon style={{ width: '36px', height: '36px' }} />
          </Box>
          <Typography sx={{fontSize:'16px',fontWeight:'600',fontFamily:'inter',margin:'20px auto'}}>
             Sign up to unlock file uploads
          </Typography>
          <Typography sx={{fontSize:'14px',fontWeight:'400',fontFamily:'inter',padding:'0 40px',textAlign:'center',marginBottom:'40px',color:'rgba(255, 255, 255, 0.80)'}}>
              ModalX uses your uploaded files as context in your projects.
              Sign up or login to access this feature
          </Typography>
          <Box 
                sx={{
                padding:'0 40px 40px 40px',
                width:'100%',
                height:'132px',
                display:'flex',
                flexDirection:'column',
                gap:'12px'
                }}
              >
                <Button 
                sx={{
                  maxWidth:'400px',
                  textTransform:'none',
                  bgcolor:New_Project_Button_bg_color,
                  "&:hover": {
                        bgcolor: "#ffd9aa",
                        border : 'none'
                  },
                  color:'#17182C',
                  fontSize:'16px',
                  fontWeight:600,
                  fontFamily:'inter',
                }}
                onClick={() => {
                  navigate("/");
                  setShowSignUpModal(true)
                }}
                >
                  Sign up
                </Button>
                <Button 
                sx={{
                  maxWidth:'400px',
                  textTransform:'none',
                  color:'#F6BE6A',
                  fontSize:'16px',
                  fontWeight:600,
                  fontFamily:'inter',
                  border:'2px solid #F6BE6A'
                }}
                onClick={() => {
                  navigate("/");
                  setShowLoginModal(true);
                }}
                >
                  Log in
                </Button>
              </Box>
        </div>
      </Dialog>
      {

      showAllUplodedFiles && <ViewThreadFilesInOneClickGenerateModal
              showAllUplodedFiles={showAllUplodedFiles}
              setShowAllUplodedFiles={setShowAllUplodedFiles}
      />
      }
      {
        showTutorialModal && <VideoTutorialModal Title={Title} videoSrc={videoSrc}/>
      }

      {/* <Dialog
        open={showModal}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
        PaperProps={{
        sx: {
            width: "720px",
            height: "615px",
            gap: "0px",
            borderRadius: "8px",
            border: "1px solid #CAC4D0",
            opacity: "0px",
            bgcolor: "#282D51",
        },
        }}
        > */}
      {/* <div
        style={{
            backgroundColor:
            theme === "dark" ? oneClickModal_backgroundColor_dm : "",
            color: theme === "dark" ? "white" : "",
        }}
        > */}
      {/* section 1 */}
      {/* <Box
            sx={{
            width: "100%",
            height: "68px",
            padding: "20px",
            gap: "10px",
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
            opacity: "0px",
            }}
        >
            <Box
            sx={{
                width: "680px",
                height: "28px",
                gap: "0px",
                opacity: "0px",
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
            }}
            >
            <Box
                sx={{
                width: "248px",
                height: "20px",
                gap: "0px",
                opacity: "0px",
                }}
            >
                
                <Typography
                sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#FFFFFF",
                }}
                >
                {modalTitle}
                </Typography>
            </Box>

            <Box
                sx={{
                width: "28px",
                height: "28px",
                }}
            >
                <CloseIcon
                onClick={handleCloseModal}
                aria-label="close"
                sx={{
                    paddingTop: "5.83px",
                    paddingLeft: "5.83px",
                    gap: "0px",
                    opacity: "0px",
                    color: "#FFFFFF",
                    "&:hover": {
                    cursor: "pointer",
                    },
                }}
                />
            </Box>
            </Box>
        </Box> */}
      {/* section 1 */}
      {/* <DialogContent> */}
      {/* section 2 */}

      {/* <Box
            sx={{
                width: "680px",
                height: "96px",
                position: "absolute",
                top: "84px",
                gap: "16px",
                opacity: "0px",
            }}
            >
            <Box
                sx={{
                width: "680px",
                height: "20px",
                opacity: "0px",
                opacity: "0px",
                }}
            >
                <Typography
                sx={{
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#FFFFFF",
                }}
                >
                Prompt
                </Typography>
            </Box>

            <Box
                sx={{
                width: "680px",
                height: "60px",
                position: "absolute",
                top: "36px",
                opacity: "0px",
                opacity: "0px",
                }}
            >
                <Typography
                sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#FFFFFF",
                }}
                >
                {currText}
                </Typography>
            </Box>
            </Box> */}
      {/* section 2 */}

      {/* section 3 */}

      {/* <Box
            sx={{
                width: "360px",
                height: "244px",
                position: "absolute",
                top: "216px",
                left: "20px",
                // paddingLeft : '50px',
                opacity: "0px",
                border: "2px dotted #FFFFFF",
                borderRadius: "10px",
                alignItems: "center",

                bgcolor: "#1F233E",
                display: "flex",
                // justifyContent : 'center',
                margin: "auto",
                textAlign: "center",
            }}
            >
            <FileUploader />
            </Box> */}
      {/* section 3 */}

      {/* section 4 */}

      {/* <Box
            sx={{
                width: "360px",
                height: "60px",
                position: "absolute",
                top: "478px",
                left: "20px",
                // padding :'16px 24px 16px 24px',
                gap: "20px",
                opacity: "0px",
                border: "2px dotted #FFFFFF",
                borderRadius: "10px",
                alignItems: "center",
            }}
            >
            {" "}
            <Box
                sx={{
                // paddingBottom : '-100px'
                margin: "auto",
                }}
            >
                <LinkUploader />
            </Box>
            </Box> */}

      {/* section 4 */}

      {/* section 5 */}

      {/* section 6 */}
      {/* <Box
            sx={{
                width: "280px",
                height: "108px",
                position: "absolute",
                top: "216px",
                left: "420px",
                gap: "0px",
                opacity: "0px",
            }}
            >
            <Box
                sx={{
                width: "260px",
                height: "40px",
                position: "absolute",
                left: "20px",
                }}
            >
                <Typography
                sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    textAlign: "left",
                }}
                >
                {filesLen == 0 ? (
                    <p>Upload at least one file to use this functionality </p>
                ) : (
                    <ViewThreadFiles />
                )}
                </Typography>
            </Box>
            </Box> */}

      {/* section 6 */}

      {/* section 7 button part */}
      {/* <Box
            sx={{
                width: "100%",
                height: "72px",
                position: "absolute",
                top: "538px",
                left: "0px",
                gap: "10px",
                opacity: "0px",
                bgcolor: "#282D51",
            }}
            >
            <Box
                sx={{
                width: "230px",
                height: "40px",
                position: "absolute",
                right: "16px",
                top: "16px",
                gap: "16px",
                opacity: "0px",
                display: "flex",
                justifyContent: "space-between",
                }}
            >
                {/* <DialogActions> */}
      {/* <Box
                onClick={handleCloseModal}
                variant="contained"
                sx={{
                    backgroundColor:
                    theme === "dark" ? uploadFiles_button_default_bg_color : "",
                    color: "black",
                    width: "93px",
                    height: "40px",
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    "&:hover": {
                    backgroundColor: uploadFiles_button_hovered_bg_color,
                    },
                }}
                >
                <Box
                    sx={{
                    width: "45px",
                    height: "20px",
                    }}
                >
                    <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1",
                        textAlign: "center",
                        color: "#17182C",
                    }}
                    >
                    Close
                    </Typography>
                </Box>
                </Box>

                <Button
                onClick={handleSend}
                variant="contained"
                disabled={
                    fileProcessing ||
                    (files.length === 0 && history_Files.length === 0)
                }
                sx={{
                    backgroundColor:
                    files.length === 0 && history_Files.length === 0
                        ? "grey"
                        : button_bg_color_for_New_Project,
                    width: "121px",
                    height: "40px",
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    "&:hover": {
                    backgroundColor: "#FFD9AA",
                    },
                    color: "black",
                }}
                >
                <Typography
                    sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    textAlign: "center",
                    color: "#17182C",
                    textTransform: "none",
                    }}
                >
                    Generate
                </Typography>
                </Button> */}

      {/* </DialogActions> */}
      {/* </Box> */}
      {/* </Box>  */}
      {/* </DialogContent> */}
      {/* </div> */}
      {/* </Dialog>  */}
    </div>
  );
};

export default ServicesForDefaultWindow;

// import React, { useEffect, useState } from 'react';
// import { OverlayTrigger, Button, Modal, ProgressBar } from 'react-bootstrap';
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import FileUploader from '../settingBar/FileUploader';

// const ServicesForDefaultWindow = () => {
//     const { threadFiles, sendMessage, setCurrentThread, setWaitThread, checkUrlStatus, setSending,historyFiles } = useWorkspace();
//     const theme = localStorage.getItem('theme');
//     const [currText, setCurrText] = useState('');
//     const [showModal, setShowModal] = useState(false);
//     const [files, setFiles] = useState([]);
//     const [fileProcessing, setFileProcessing] = useState(false);
//     const [history_Files,setHistoryFiles] = useState([]);
//     const token = localStorage.getItem('token');

//     useEffect(() => {
//         if(historyFiles){
//           setHistoryFiles(historyFiles);
//         }
//       }, [historyFiles])

//     const handleOneClick = async (text) => {
//         setCurrText(text);
//         setShowModal(true);
//     };

//     useEffect(() => {
//         setFiles(threadFiles);
//     }, [threadFiles]);

//     useEffect(() => {
//         const isAnyFileProcessing = files.some(file => file && file.status === 'processing');
//         setFileProcessing(isAnyFileProcessing);
//     }, [files]);

//     const renderOverlay = (text) => (
//         <div style={{ position: 'absolute', top: '-30px', left: '50%', transform: 'translateX(-50%)', backgroundColor: 'blue', color: '#fff', padding: '5px', borderRadius: '5px', zIndex: '999', pointerEvents: 'none' }}>
//             {text}
//         </div>
//     );

//     const handleCloseModal = () => {
//         setShowModal(false);
//     };

//     const handleSend = () => {
//         handleSubmit(currText);
//         setShowModal(false);
//     };

//     const handleSubmit = async (input) => {
//         setSending(true);
//         setWaitThread(true);
//         if (input.trim() && !fileProcessing) {
//             const latestEntry = { sender: "user", content: input };
//             setCurrText("");
//             setCurrentThread((prevThread) => [...prevThread, latestEntry]);
//             const entryResponse = await sendMessage(latestEntry).catch(console.error);
//             console.log(entryResponse);
//             if (entryResponse) {
//                 setSending(false);
//                 setCurrentThread((prevThread) => [...prevThread, entryResponse]);
//             } else {
//                 setSending(false);
//                 setCurrentThread((prevThread) => [...prevThread, { content: "Unable to process your query!..", sender: 'modalX' }]);
//             }
//         }
//         setWaitThread(false);
//     };

//     return (
//         <div>
//             <p style={{ textAlign: 'center', fontSize: '1.1rem',color: theme === 'dark' ? '#6c757d' : '', fontWeight: '600' }}>One Click Output</p>
//             <hr></hr>
//             <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'relative' }}>
//                 <OverlayTrigger
//                     placement="bottom"
//                     overlay={renderOverlay('Generate Video')}
//                 >
//                     <Button
//                         style={{
//                             background: 'none',
//                             border: 'none',
//                             position: 'relative',
//                             fontSize:'1.8rem',
//                             transition: 'transform 0.3s'
//                         }}
//                         onClick={() => handleOneClick('Shrink the transcribed content from various sources, such as videos, audio recordings, and PDFs, to within 3000 words. Keep the points concise and generate a video summary accordingly.')}
//                         onMouseEnter={(e) => {
//                             e.currentTarget.style.transform = 'scale(.8)'
//                             e.currentTarget.style.color = 'blue'
//                         }}
//                         onMouseLeave={(e) => {
//                             e.currentTarget.style.transform = 'scale(1)'
//                             e.currentTarget.style.color = 'white'
//                         }}
//                     >
//                         <i className="bi bi-camera-reels" style={{ color: theme === 'light' ? 'black' : '' }} />
//                     </Button>
//                 </OverlayTrigger>

//                 <OverlayTrigger
//                     placement="bottom"
//                     overlay={renderOverlay('Generate Audio')}
//                 >
//                     <Button
//                         style={{
//                             background: 'none',
//                             border: 'none',
//                             position: 'relative',
//                             fontSize:'1.8rem',
//                             transition: 'transform 0.3s'
//                         }}
//                         onClick={() => handleOneClick('Shrink the transcribed content from various sources, such as videos, audio recordings, and PDFs, to within 3000 words. Keep the points concise and generate an audio summary accordingly.')}
//                         onMouseEnter={(e) => {
//                             e.currentTarget.style.transform = 'scale(.8)'
//                             e.currentTarget.style.color = 'blue'
//                         }}
//                         onMouseLeave={(e) => {
//                             e.currentTarget.style.transform = 'scale(1)'
//                             e.currentTarget.style.color = 'white'
//                         }}
//                     >
//                         <i className="bi bi-file-earmark-music" style={{ color: theme === 'light' ? 'black' : '' }} />
//                     </Button>
//                 </OverlayTrigger>

//                 <OverlayTrigger
//                     placement="bottom"
//                     overlay={renderOverlay('Generate PPT')}
//                 >
//                     <Button
//                         style={{
//                             background: 'none',
//                             border: 'none',
//                             position: 'relative',
//                             fontSize:'1.8rem',
//                             transition: 'transform 0.3s',
//                         }}
//                         onClick={() => handleOneClick('Shrink the transcribed content from various sources, such as videos, audio recordings, and PDFs, to within 3000 words. Keep the points concise and generate a ppt accordingly.')}
//                         onMouseEnter={(e) => {
//                             e.currentTarget.style.transform = 'scale(.8)'
//                             e.currentTarget.style.color = 'blue'
//                         }}
//                         onMouseLeave={(e) => {
//                             e.currentTarget.style.transform = 'scale(1)'
//                             e.currentTarget.style.color = 'white'
//                         }}
//                     >
//                         <i className="bi bi-journal-text" style={{ color: theme === 'light' ? 'black' : '' }} />
//                     </Button>
//                 </OverlayTrigger>

//                 <OverlayTrigger
//                     placement="bottom"
//                     overlay={renderOverlay('Generate Image')}
//                 >
//                     <Button
//                         style={{
//                             background: 'none',
//                             border: 'none',
//                             position: 'relative',
//                             fontSize:'1.8rem',
//                             transition: 'transform 0.3s'
//                         }}
//                         onClick={() => handleOneClick('Shrink the transcribed content from various sources, such as videos, audio recordings, and PDFs, to within 3000 words. Keep the points concise and generate an image from the summary accordingly.')}
//                         onMouseEnter={(e) => {
//                             e.currentTarget.style.transform = 'scale(.8)'
//                             e.currentTarget.style.color = 'blue'
//                         }}
//                         onMouseLeave={(e) => {
//                             e.currentTarget.style.transform = 'scale(1)'
//                             e.currentTarget.style.color = 'white'
//                         }}
//                     >
//                         <i className="bi bi-images" style={{ color: theme === 'light' ? 'black' : '' }} />
//                     </Button>
//                 </OverlayTrigger>
//             </div>
//             <Modal size="lg" show={showModal} onHide={handleCloseModal} centered>
//                 <Modal.Header closeButton />
//                 <Modal.Body style={{marginLeft:'10px'}}>
//                     <h6>Prompt :</h6>
//                     <p>{currText}</p>
//                     <h6 style={{ color: 'grey' }}>{files.length ? "Files :" : "No files uploaded yet"}</h6>
//                     {files.length ? (
//                         // Display files here
//                         <div>
//                             <ul>
//                                 {files.map((file, index) => (
//                                     <li key={index}>
//                                         {file.name}
//                                         {file.type === 'file' && file.status === 'uploading' && (
//                                             <ProgressBar animated now={100} label="Uploading..." />
//                                         )}
//                                         {file.type === 'file' && file.status === 'upload failed' && (
//                                             <p style={{ color: 'red' }}>Upload Failed</p>
//                                         )}
//                                     </li>
//                                 ))}
//                             </ul>
//                             <FileUploader />
//                         </div>
//                     ) : (
//                         // Input element for adding files

//                         <FileUploader />
//                     )}
//                     {files.length === 0 && history_Files.length === 0 ?<p style={{fontWeight:'600',fontStyle:'italic',color:'orange'}}>You have to upload at least one file to explore this functionality!</p>:<></>}
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
//                     <Button variant="primary" onClick={handleSend} style={{backgroundColor:(files.length === 0 && history_Files.length === 0 )?'grey':''}} disabled={fileProcessing || (files.length === 0 && history_Files.length === 0 )}>Generate</Button>
//                 </Modal.Footer>
//             </Modal>
//         </div>
//     );
// };

// export default ServicesForDefaultWindow;
