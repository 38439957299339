import React, { useEffect, useState, useRef } from "react";
import { ProgressBar } from "react-bootstrap";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import sourceUrl from "../../../api/Source.api";
import Youtube_Icon from "../../../assets/File_Icon/youtube_icon.png";
import Xlsx_Icon from "../../../assets/File_Icon/xlsx_icon.png";
import Pdf_Icon from "../../../assets/File_Icon/pdf_icon.png";
import { Box, CardMedia, Dialog, IconButton, Tooltip } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ReplayIcon from "@mui/icons-material/Replay";
import RemoveFileModal from "./RemoveFileModal";
import progressIndicator from "../../../assets/progressIndicator.png";
import pngIcon from "../../../assets//Button/Picture.png";
import movIcon from "../../../assets/File_Icon/movIcon.png";
import mp4Icon from "../../../assets/File_Icon/mp4Icon.png";
import word_logo from "../../../assets/File_Icon/word_logo.png";
import mp3_logo from "../../../assets/File_Icon/mp3_logo.png";
import powerpoint_logo from "../../../assets/File_Icon/powerpoint_logo.png";
import imageIcon from "../../../assets/File_Icon/image_icon.png";
import CompleteIcon from "@mui/icons-material/CheckCircle";
import completeIcon from "../../../assets/greencheckIcon.png";
import CircularProgress from "./ProcessingIndicator/CircularProgress";
import deleteIcon from "../../../assets/DeleteIcon.png";
import { Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteUploadFileFromS3, FetchHistoryOfThreadId } from "../../../contexts/WorkspaceUtils";

const ViewThreadFilesInOneClickGenerateModal = ({
  showAllUplodedFiles,
  setShowAllUplodedFiles,
}) => {
  const {
    threadId,
    historyFiles,
    threadFiles,
    setThreadFiles,
    InvokeMatrixApi,
    uploadFiles,
    setWaitThread,
    upload_FilesOnly,
    setCurrentThread,
    setHistoryFiles
  } = useWorkspace();
  const [progressStatusChecking, setProgressStatusChecking] = useState({});
  const token = localStorage.getItem("token");
  const [filesLen, setFilesLen] = useState(0);
  const [toggleRemoveFile, setToggleRemoveFile] = useState(false);
  const [clickIndex, setClickIndex] = useState(null);
  const [completedFiles, setCompletedFiles] = useState({});
  const [showCompleteIcon, setShowCompleteIcon] = useState({});

  const id = threadId && parseInt(threadId.replace("thread-", ""), 10);

  // Ensure both historyFiles and threadFiles are arrays
  const files = [
    ...(threadFiles ? [...threadFiles].reverse() : []),
    ...(historyFiles || []),
  ];

  const renderIcons = () => {};

  useEffect(() => {
    const intervals = files.map((file) => {
      if (file.status === "uploading" || file.status === "processing") {
        const interval = setInterval(async () => {
          try {
            // Validate that threadId and file.url are not undefined or null
            if (!threadId || !file.url) {
              console.error("Invalid data: threadId or file URL is missing.");
              clearInterval(interval); // Stop if the data is invalid
              return;
            }

            // Log the request body for debugging
            const requestBody = JSON.stringify({ threadId, url: file.url });
            console.log("Request Body:", requestBody);

            // Fetch request to check file status
            const status_resp = await fetch(`${sourceUrl}/status`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: requestBody,
            });

            // Handle response
            if (!status_resp.ok) {
              console.error(`Failed with status: ${status_resp.status}`);
              return;
            }

            const responseData = await status_resp.json();
            console.log("Response Data:", responseData);

            // Handle different statuses
            if (responseData.data.status === "completed") {
              setShowCompleteIcon((prev) => ({ ...prev, [file.url]: true })); // Show complete icon
              setTimeout(() => {
                setShowCompleteIcon((prev) => ({ ...prev, [file.url]: false })); // Hide after 2 seconds
              }, 2000);

              setThreadFiles((prevThreadFiles) =>
                prevThreadFiles.map((prevFile) =>
                  prevFile.name === file.name
                    ? { ...prevFile, status: "completed" }
                    : prevFile
                )
              );
              clearInterval(interval); // Clear interval after completion
            } else if (responseData.data.status === "failed") {
              setThreadFiles((prevThreadFiles) =>
                prevThreadFiles.map((prevFile) =>
                  prevFile.name === file.name
                    ? { ...prevFile, status: "failed" }
                    : prevFile
                )
              );
              clearInterval(interval); // Clear interval after failure
            } else {
              console.error(`Unexpected status: ${responseData.data.status}`);
            }
          } catch (error) {
            console.error("Error checking file status:", error);
          }
        }, 3000);

        return interval;
      }

      return null;
    });

    // Clear all intervals on cleanup
    return () => {
      intervals.forEach((interval) => {
        if (interval) clearInterval(interval);
      });
    };
  }, [files, threadId, token, setThreadFiles]);

  useEffect(() => {
    setFilesLen(files.length);
  }, [files]);

  const handleRetryFile = async (file) => {
    // Mark the specific file as "uploading"
    setThreadFiles((prevThreadFiles) =>
      prevThreadFiles.map((prevFile) =>
        prevFile.fileId === file.fileId
          ? { ...prevFile, status: "uploading" }
          : prevFile
      )
    );

    setProgressStatusChecking((prev) => ({ ...prev, [file.url]: true }));
    setWaitThread(true);

    try {
      const response = await upload_FilesOnly(file);
      if (response && response.data) {
        const { id: newFileId, url, status } = response.data;
        setThreadFiles((prevThreadFiles) =>
          prevThreadFiles.map((prevFile) =>
            prevFile.fileId === file.fileId
              ? { ...prevFile, status, fileId: newFileId, url }
              : prevFile
          )
        );

        const interval = setInterval(async () => {
          try {
            const status_resp = await fetch(`${sourceUrl}/status`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({ url }),
            });

            const responseData = await status_resp.json();

            if (status_resp.ok && responseData.data) {
              const { status } = responseData.data;
              setThreadFiles((prevThreadFiles) =>
                prevThreadFiles.map((prevFile) =>
                  prevFile.fileId === newFileId
                    ? { ...prevFile, status, url }
                    : prevFile
                )
              );

              if (status === "completed" || status === "failed") {
                setProgressStatusChecking((prev) => ({
                  ...prev,
                  [url]: false,
                }));
                clearInterval(interval);
              }
            } else {
              setProgressStatusChecking((prev) => ({ ...prev, [url]: false }));
              clearInterval(interval);
            }
          } catch (error) {
            console.error("Error fetching status:", error);
            setProgressStatusChecking((prev) => ({ ...prev, [url]: false }));
            clearInterval(interval);
          }
        }, 3000);
      } else {
        console.error("Invalid response from uploadFiles:", response);
        setThreadFiles((prevThreadFiles) =>
          prevThreadFiles.map((prevFile) =>
            prevFile.fileId === file.fileId
              ? { ...prevFile, status: "failed" }
              : prevFile
          )
        );
      }
    } catch (error) {
      console.error("Error during file upload:", error);
      setThreadFiles((prevThreadFiles) =>
        prevThreadFiles.map((prevFile) =>
          prevFile.fileId === file.fileId
            ? { ...prevFile, status: "failed" }
            : prevFile
        )
      );
    }

    setWaitThread(false);
  };

  const toggleRemoveFileFun = (index) => {
    setToggleRemoveFile(!toggleRemoveFile);
    setClickIndex(index);
  };

  const handleClickOutside = () => {
    setToggleRemoveFile(false); // Close the dropdown if clicked outside
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const HandleShowMoreButtonClick = () => {
    setShowAllUplodedFiles(true);
  };

  const handleCloseModal = () => {
    setShowAllUplodedFiles(false);
  };

  const findUrl = (msg) => {
    const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
    const match = urlRegex.exec(msg);
    return match ? match[1] : null;
  };
  
  const getFileFormat = (url) => {
    // Extract the file extension from the URL
    const parts = url.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1]; // Return the last part as the file format
    } else {
      return ''; // Return empty string if no file extension found
    }
  };

  const handleDeleteFile =async (file) =>{
    try{
      const id = file?.fileId || file?.id;
      if (file?.fileId){
        const number = id.match(/\d+/)[0];
        const response  = await DeleteUploadFileFromS3(number)
        if(response?.status === 200){
          const newfiles = threadFiles.filter((ele)=>{
            return ele.fileId !== file.fileId
          })
          setThreadFiles(newfiles);
        }
      }else if(file?.id){
        const number = id.match(/\d+/)[0];
        // console.log(number, 'delete file');
        const response  = await DeleteUploadFileFromS3(number)
      if(response?.status === 200){
        const res = await FetchHistoryOfThreadId();
        if(response?.status === 200){
          const updatedArray = res?.data?.data?.history.map((item, index)=>{
            if (typeof item.agent === 'string' && item.agent.includes('dailylimitexceeded')) {
              const fileFormat = getFileFormat(findUrl(item.agent));
              item.agent = `Hi there,\n\nIt looks like you’ve reached your daily limit for generating ${fileFormat} files. We’re really sorry about that! We know how important uninterrupted access is for you.\n\nTo keep enjoying all the great benefits and have a seamless experience, we’d love for you to consider upgrading to our premium plan.\n\nThank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.\n\nWarm regards,\n\nThe ModalX Team`;
          }
          if(item.is_stop){
            item.agent = "**Response generation was stopped**"
          }
          return item;
          })
          setCurrentThread(updatedArray);
          // setCurrentThread( res?.data?.data?.history);
          setHistoryFiles( res?.data?.data?.input_files || [])
        }
        else{
          setHistoryFiles([])
        }
      }
    }else{
      console.log('fileId is not found')
    }
    }catch(error){
      console.error('Error deleting file:', error);
    }
  }

  return (
    <div>
      {files.length && !showAllUplodedFiles ? (
        <ul
          style={{
            padding: "0px",
            backgroundColor: "",
            margin: "0px",
          }}
        >
          {files.slice(0, 3).map((file, index) => (
            <li
              key={index}
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                listStyleType: "disc",
                textAlign: "center",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                color: file.status === "failed" ? "red" : "",
                borderBottom: "1px solid rgba(255, 255, 255, 0.10)",
              }}
              title={file.name}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "8px 16px 8px 16px",
                }}
              >
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "16px" }}
                >
                  {(file?.name.includes(".jpeg") ||
                    file?.name.includes(".png") ||
                    file?.name.includes(".jpg")) && (
                    <img
                      src={imageIcon}
                      alt="logo"
                      style={{ width: "20px", height: "20px" }}
                    />
                  )}
                  {file?.name.includes(".mp3") && (
                    <img
                      src={mp3_logo}
                      alt="logo"
                      style={{ width: "20px", height: "20px" }}
                    />
                  )}
                  {file?.name.includes(".mp4") && (
                    <img
                      src={mp4Icon}
                      alt="logo"
                      style={{ width: "20px", height: "20px" }}
                    />
                  )}
                  {file?.name.includes(".mov") && (
                    <img
                      src={movIcon}
                      alt="logo"
                      style={{ width: "20px", height: "20px" }}
                    />
                  )}
                  {file?.name.includes(".pdf") && (
                    <img
                      src={Pdf_Icon}
                      alt="logo"
                      style={{ width: "20px", height: "20px" }}
                    />
                  )}
                  {(file?.name.includes(".pptx") ||
                    file?.name.includes("pptm")) && (
                    <img
                      src={powerpoint_logo}
                      alt="logo"
                      style={{ width: "20px", height: "20px" }}
                    />
                  )}
                  {(file?.name.includes("docx") ||
                    file?.name.includes("doc")) && (
                    <img
                      src={word_logo}
                      alt="I"
                      style={{ width: "20px", height: "20px" }}
                    />
                  )}
                  {(file?.name.includes(".xlsx") ||
                    file?.name.includes(".xls")) && (
                    <img
                      src={Xlsx_Icon}
                      alt="logo"
                      style={{ width: "20px", height: "20px" }}
                    />
                  )}

                  {file.name.length > 14
                    ? `${file.name.substring(0, 14)}...`
                    : file.name}
                </div>
                <div onClick={() => toggleRemoveFileFun(index)}>
                  {file.status === "uploading" ||
                  file.status === "processing" ? (
                    // <CircularProgress size={20} style={{ marginLeft: '8px', color: 'white' }} />
                    <CircularProgress />
                  ) : file.status === "failed" ? (
                    <IconButton
                      onClick={() => handleRetryFile(file)}
                      style={{
                        height: "10px",
                        color: "red",
                        marginLeft: "25px",
                      }}
                    >
                      <ReplayIcon />
                    </IconButton>
                  ) : showCompleteIcon[file.url] ? (
                    // Show the complete icon if the file is completed
                    // <CompleteIcon style={{ marginLeft: '8px', color: 'green' }} />
                    <img src={completeIcon} alt="" width="20px" />
                  ) : (
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#E6E0E9",
                            color: "#17182C",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 500,
                            padding: "4px 8px 4px 8px",
                            borderRadius: "4px",
                            textAlign: "center",
                          },
                        },
                      }}
                      title={`Remove file`}
                    >
                      <Box
                        sx={{
                          width: "16px",
                          height: "16px",
                        }}
                        onClick={()=>{handleDeleteFile(file)}}
                      >
                        {/* <CardMedia component="img" sx={{color:"#CAC4D0"}} image={deleteIcon} alt="delete icon" /> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 14 16"
                          fill="none"
                        >
                          <path
                            d="M2.8335 15.5C2.37516 15.5 1.9828 15.3368 1.65641 15.0104C1.33002 14.684 1.16683 14.2917 1.16683 13.8333V3H0.333496V1.33333H4.50016V0.5H9.50016V1.33333H13.6668V3H12.8335V13.8333C12.8335 14.2917 12.6703 14.684 12.3439 15.0104C12.0175 15.3368 11.6252 15.5 11.1668 15.5H2.8335ZM11.1668 3H2.8335V13.8333H11.1668V3ZM4.50016 12.1667H6.16683V4.66667H4.50016V12.1667ZM7.8335 12.1667H9.50016V4.66667H7.8335V12.1667Z"
                            fill="#CAC4D0"
                          />
                        </svg>
                      </Box>
                    </Tooltip>
                  )}
                </div>
              </div>
            </li>
          ))}
          {files.length > 3 && (
            <Box
              onClick={() => {
                HandleShowMoreButtonClick();
              }}
              sx={{
                color: "#ffffff",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                textDecorationLine: "underline",
                textDecorationStyle: "solid",
                textDecorationSkipInk: "none",
                textDecorationThickness: "auto",
                textUnderlineOffset: "auto",
                marginLeft: "16px",
                cursor: "pointer",
                marginTop:"4px",
                "&:hover": {
                  color: "rgba(255, 255, 255, 0.80)",
                },
              }}
            >
              {`+ ${files.length - 3} more`}
            </Box>
          )}
        </ul>
      ) : (
        <>
          <Dialog
            open={showAllUplodedFiles}
            onClose={handleCloseModal}
            PaperProps={{
              sx: {
                width: "480px",
                height: "496px",
                gap: "0px",
                borderRadius: "8px",
                // border: "1px solid #CAC4D0",
                opacity: "0px",
                bgcolor: "#282D51",
                margin: "",
                color: "#ffffff",
              },
            }}
          >
            {/* section 1 */}
            <Box
              sx={{
                width: "100%",
                padding: "12px 20px",
                gap: "10px",
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                opacity: "0px",
              }}
            >
              <Box
                sx={{
                  height: "28px",
                  gap: "0px",
                  opacity: "0px",
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "248px",
                    height: "20px",
                    gap: "0px",
                    opacity: "0px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      textAlign: "left",
                      color: "#FFFFFF",
                    }}
                  >
                    {`Uploaded files (${files.length})`}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "28px",
                    height: "28px",
                  }}
                >
                  <CloseIcon
                    onClick={handleCloseModal}
                    aria-label="close"
                    sx={{
                      gap: "0px",
                      opacity: "0px",
                      color: "#FFFFFF",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "20px",
                letterSpacing: "0.1px",
                textAlign: "left",
                color: "#CAC4D0",
                textTransform: "none",
                margin: "10px 20px",
              }}
            >
              <ul
                style={{
                  backgroundColor: "",
                  maxHeight: "36px",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {files?.map((file, index) => (
                  <li
                    key={index}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      listStyleType: "disc",
                      textAlign: "center",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "16px",
                      color: file.status === "failed" ? "red" : "",
                      borderBottom: "1px solid rgba(255, 255, 255, 0.10)",
                    }}
                    title={file.name}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        padding: "16px 16px 16px 16px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "16px",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                          color: "#CAC4D0",
                        }}
                      >
                        {(file?.name.includes(".jpeg") ||
                          file?.name.includes(".png") ||
                          file?.name.includes(".jpg")) && (
                          <img
                            src={imageIcon}
                            alt="I"
                            style={{ width: "20px", height: "20px" }}
                          />
                        )}
                        {file?.name.includes(".mp3") && (
                          <img
                            src={mp3_logo}
                            alt="I"
                            style={{ width: "20px", height: "20px" }}
                          />
                        )}
                        {file?.name.includes(".mp4") && (
                          <img
                            src={mp4Icon}
                            alt="I"
                            style={{ width: "20px", height: "20px" }}
                          />
                        )}
                        {file?.name.includes(".mov") && (
                          <img
                            src={movIcon}
                            alt="I"
                            style={{ width: "20px", height: "20px" }}
                          />
                        )}
                        {file?.name.includes(".pdf") && (
                          <img
                            src={Pdf_Icon}
                            alt="I"
                            style={{ width: "20px", height: "20px" }}
                          />
                        )}
                        {(file?.name.includes(".pptx") ||
                          file?.name.includes("pptm")) && (
                          <img
                            src={powerpoint_logo}
                            alt="I"
                            style={{ width: "20px", height: "20px" }}
                          />
                        )}
                        {(file?.name.includes("docx") ||
                          file?.name.includes("doc")) && (
                          <img
                            src={word_logo}
                            alt="I"
                            style={{ width: "20px", height: "20px" }}
                          />
                        )}
                        {(file?.name.includes(".xlsx") ||
                          file?.name.includes(".xls")) && (
                          <img
                            src={Xlsx_Icon}
                            alt="I"
                            style={{ width: "20px", height: "20px" }}
                          />
                        )}

                        {/* {file.name.length > 28
                          ? `${file.name.substring(0, 28)}...`
                          : file.name} */}
                          <div style={{display:"flex", flexDirection:"row"}}>
                        <div style={{maxWidth:"19rem", overflow:"hidden"}}>{ `${file.name}`} </div>
                        <div>...</div></div>
                      </div>
                      <div onClick={() => toggleRemoveFileFun(index)}>
                        {file.status === "uploading" ||
                        file.status === "processing" ? (
                          // <CircularProgress size={20} style={{ marginLeft: '8px', color: 'white' }} />
                          <CircularProgress />
                        ) : file.status === "failed" ? (
                          <IconButton
                            onClick={() => handleRetryFile(file)}
                            style={{
                              height: "10px",
                              color: "red",
                              marginLeft: "25px",
                            }}
                          >
                            <ReplayIcon />
                          </IconButton>
                        ) : showCompleteIcon[file.url] ? (
                          // Show the complete icon if the file is completed
                          // <CompleteIcon style={{ marginLeft: '8px', color: 'green' }} />
                          <img src={completeIcon} alt="" width="20px" />
                        ) : (
                          <Tooltip
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: "#E6E0E9",
                                  color: "#17182C",
                                  fontFamily: "Inter",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  padding: "4px 8px 4px 8px",
                                  borderRadius: "4px",
                                  textAlign: "center",
                                },
                              },
                            }}
                            title={`Remove file`}
                          >
                            <Box  
                              sx={{
                                width: "16px",
                                height: "16px",
                              }}
                              onClick={()=>{handleDeleteFile(file)}}
                            >
                              {/* <CardMedia component="img" sx={{color:"#CAC4D0"}} image={deleteIcon} alt="delete icon" /> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 14 16"
                                fill="none"
                              >
                                <path
                                  d="M2.8335 15.5C2.37516 15.5 1.9828 15.3368 1.65641 15.0104C1.33002 14.684 1.16683 14.2917 1.16683 13.8333V3H0.333496V1.33333H4.50016V0.5H9.50016V1.33333H13.6668V3H12.8335V13.8333C12.8335 14.2917 12.6703 14.684 12.3439 15.0104C12.0175 15.3368 11.6252 15.5 11.1668 15.5H2.8335ZM11.1668 3H2.8335V13.8333H11.1668V3ZM4.50016 12.1667H6.16683V4.66667H4.50016V12.1667ZM7.8335 12.1667H9.50016V4.66667H7.8335V12.1667Z"
                                  fill="#CAC4D0"
                                />
                              </svg>
                            </Box>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </Typography>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default ViewThreadFilesInOneClickGenerateModal;
