import React,{useRef,useEffect,useState} from 'react';
import NavbarForTrackWebsite from './NavbarForTrackWebsite';
import MessageForTrackWebside from './MessageForTrackWebsite';
import { Box, Typography,CardMedia, FormControlLabel, Dialog, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import info_icon from '../../../../assets/info.png';
import { useWorkspace } from '../../../../contexts/WorkspaceContext';
import axios from 'axios';
import sourceUrl from '../../../../api/Source.api';
import MessageForTrackWebsiteEmpty from './MessageForTrackWebsiteEmpty';
import { useMediaQuery } from 'react-responsive';
import WebsiteCannotTracked from './WebsiteCannotTracked';
import { ToggleSwitch } from '../../../Common_Components/ToggleSwitch';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from "@mui/icons-material/Close";
import CircularLoader from './CircularLoader';
import { useNavigate } from 'react-router-dom';


function MessageWindowForTrackWebsite({ website,onHandleRefetch,refetchClicked,updateWebList}) {
  const {currentWebsiteLinkedId,setCurrentWebsiteLinkedId,setSelectedWeb,setWebTrackingLoading,webTrackingLoading} = useWorkspace();
  const [shouldScroll, setShouldScroll] = useState(true);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [data, setData] = useState([]);
  const token = localStorage.getItem("token");
  const isUnder992px = useMediaQuery({ maxWidth:992});
  const [moreButtonForMobileClicked,setMoreButtonForMobileClicked] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentWeb,setCurrentWeb] = useState({});
  const [page,setPage] = useState(1);
  const [totalPages,setTotalPages] = useState(0);
  const navigate = useNavigate()
  const containerRef = useRef(null);
  


  useEffect(() => {
    getSingleWebTrackDetails(currentWebsiteLinkedId);
  }, [currentWebsiteLinkedId, refetchClicked,page]);


  const getSingleWebTrackDetails = async (id) => {
    setWebTrackingLoading(true);
    try {
      const res = await axios.get(`${sourceUrl}/v1/websiteTrackings/${id}/details?page=${page}&pagesize=10`, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      setData(res.data.data.notifications);
      setCurrentWeb(res.data.data);
      setTotalPages(res?.data?.data?.totalPages);
      // if(res.data.status){
      //   invokeViewedApi(currentWebsiteLinkedId);
      // }
      setWebTrackingLoading(false);
    } catch (error) {
      console.log(error);
      setWebTrackingLoading(false);
    }
    setWebTrackingLoading(false);
  };

  const invokeViewedApi = async(id) => {
    try {
      const res = await axios.get(`${sourceUrl}/websiteTracking/viewed/${id}`, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  const toggleMoreButtonState = () => {
    setMoreButtonForMobileClicked((prev) => !prev);
  }

  const handleDeleteWebsite = async (id) => {
    try {
      // API call to delete the website with the specified ID
      const res = await axios.delete(`${sourceUrl}/v1/websiteTrackings/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      // Check if the response status is successful
      if (res.data.status) {
        console.log(res.data.message);
        alert("Website deleted successfully");
        handleBackToWebsiteListing();
        setOpenDeleteModal(false);
        setMoreButtonForMobileClicked(false)
        navigate('/workspace/trackwithmodalx')
        updateWebList();
      } else {
        console.error("Deletion failed:", res.data.message);
      }
    } catch (error) {
      console.error("Failed to delete the website:", error);
      alert("An error occurred while deleting the website.");
    }
  };

  const handleBackToWebsiteListing = () => {
    updateWebList();
    setCurrentWebsiteLinkedId(null)
    setSelectedWeb("");
  }

  const handleEnable = async (id) => {
    try {
      await axios.post(`${sourceUrl}/v1/websiteTrackings/${id}`,{"isDisabled": false}, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
       // Refresh the website list or update the status locally if feasible
    } catch (error) {
       console.error("Failed to update status:", error);
    }
 };

 const handleDisable = async (id) => {
  try {
    await axios.post(`${sourceUrl}/v1/websiteTrackings/${id}`,{"isDisabled": true}, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
     // Refresh the website list or update the status locally if feasible
  } catch (error) {
     console.error("Failed to update status:", error);
  }
};

const navbarStyle = {
  position: "fixed", // Fixes the navbar at the top of the viewport
  top: 0,            // Aligns it to the top
  left: 0,           // Aligns it to the left
  width: "100%",     // Makes it span the entire width of the screen
  zIndex: 1000,      // Ensures it stays above other elements
};

const handlePageChange = (currPage) => {
  setPage(currPage);
  if (containerRef.current) {
    containerRef.current.scrollTop = 0;  // This scrolls the container to the top
  }
}


  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <div  style= { isUnder992px ? navbarStyle : {}}>
      <NavbarForTrackWebsite website={website} onHandleRefetch={onHandleRefetch} updateWebList={updateWebList} toggleMoreButtonState={toggleMoreButtonState}/>
      </div>
  
      <div
        ref={containerRef}
        style={{
          flex: 1, // This makes the inner div take the available space
          marginBottom: '20px', // Adjust the margin as needed
          overflowY: 'scroll',
          position:'relative'
        }}
      >
        {
          data?.length > 0 ?   <MessageForTrackWebside refetchClicked={refetchClicked}  currentWebData={data} page={page} onPageChange={handlePageChange} totalPages={totalPages}/> :  webTrackingLoading === true? <CircularLoader  msg={"Loading webpage updates..."} /> : <MessageForTrackWebsiteEmpty />  // <WebsiteCannotTracked/>  // 
        }
      
      </div>
      {/* <div style={{width:'100%',backgroundColor:'red',height:'100px'}}></div> */}
      <Box sx={{
        width :isUnder992px ? "90%" : '100%',
        display : 'flex',
        justifyContent : 'center',
        marginBottom : '20px',
        marginLeft:"auto",
        marginRight:"auto"
      }}>
        <Box sx={{
          width : '720px',
          display : 'flex',
          justifyContent : 'center',
          alignItems : 'start',

        }}>
          <Box sx={{
            width : '20px',
            // height : '14px', 
            marginRight : '8px',


          }}>
              <CardMedia
                component="img"
                image={info_icon}
                alt="loading"
                sx={{width:'14px'}}
              />

          </Box>
          <Box>
          <Typography sx={{
          // maxWidth : '720px',
          color : '#FFFFFF',
          textAlign : 'left',
          fontFamily : 'Inter',
          fontSize : '12px',
          fontWeight : '400',
          opacity : '0.8',


        }}>ModalX will track changes in text content on individual webpages such as example.com/policy, or example.com (home page). It will not track changes across the entire website ‘example.com’ or changes in styles or layouts.</Typography>
        </Box>
        </Box>
         
      </Box>
      {(isUnder992px && moreButtonForMobileClicked) && 
      <Box
        sx={{
          height: '132px',
          bgcolor: '#2F355F',
          position: 'absolute',
          right: '20px',
          marginTop: '72px',
          width: '186px',
          display: 'flex', // Enables flexbox
          flexDirection: 'column', // Stacks the child elements vertically
          alignItems: 'center', // Centers children horizontally
          justifyContent: 'center', // Centers children vertically
          gap: '6px', // Adds consistent spacing between child elements
          borderRadius:'4px'
        }}
      >
        <Box
          sx={{
            width: '171px',
            height: '36px',
            display: 'flex', // Enables flexbox
            alignItems: 'center', // Vertically centers the content
            justifyContent: 'flex-start', // Aligns content to the start
            overflow: 'hidden', // Ensures no overflow beyond the container
          }}
        >
          <FormControlLabel
            control={
              <ToggleSwitch
                sx={{ transform: 'scale(0.8)' }}
                defaultChecked={currentWeb?.isDisabled === false} onClick={() => currentWeb?.isDisabled === false?handleDisable(currentWeb):handleEnable(currentWeb)}
              />
            }
            label="Enable tracking"
            labelPlacement="start"
            sx={{
              whiteSpace: 'nowrap',
              margin: 0, // Removes default margin
              maxWidth: '100%', // Ensures it doesn't exceed the parent's width
              display: 'flex',
              alignItems: 'center',
            }}
          />
        </Box>
      
        <Box
          sx={{
            width: '171px',
            height: '36px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start', // Aligns content to the start
            overflow: 'hidden',
            gap: '8px',
          }}
          onClick={() => setOpenDeleteModal(true)}
        >
          <DeleteIcon /> Remove website
        </Box>
      
        <Box
          sx={{
            width: '171px',
            height: '36px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start', // Aligns content to the start
            overflow: 'hidden',
            gap: '8px',
            whiteSpace: 'nowrap',
          }}
          onClick={onHandleRefetch}
        >
          <CachedIcon /> Check for updates
        </Box>
      </Box>
    
      }
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md" // Set modal maxWidth to medium (720px) if using Material UI's breakpoints
        PaperProps={{
          sx: {
            maxWidth: '720px', // Override to ensure the dialog width is capped at 720px
            width: '100%', // This makes the dialog responsive, taking up full width up to 720px
          }
        }}
      >
        {/* <DialogTitle id="alert-dialog-title" sx={{fontSize:'20px',fontWeight:600,fontFamily:'Inter',borderBottom:'1px solid rgba(255,255,255,0.2)',bgcolor:'#282D51',color:'#FFFFFF'}}>{"Remove website?"}</DialogTitle> */}
        <Box
              sx={{
                // justifyContent: "center",
                display: "flex",
                bgcolor:'#282D51'
                ,borderBottom:'1px solid rgba(255,255,255,0.2)'
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "28px",
                  marginTop: "20px",
                  marginBottom:'20px',
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding : '0px 20px 0px 20px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    textAlign: "left",
                    color : '#FFFFFF'
                  }}
                >
                  Remove website?
                </Typography>
                <Box  sx={{
                    color : '#FFFFFF',
                    '&:hover' :{
                      cursor : "pointer"
                    }
                }} 
                onClick={() => setOpenDeleteModal(false)}
                >
                    <CloseIcon/>
                    </Box>
              </Box>
            </Box>
        <DialogContent sx={{bgcolor:'#282D51'}}>
          <DialogContentText id="alert-dialog-description" sx={{fontSize:'16px',color:'#FFFFFF',paddingTop:'16px'}}>
          ModalX will stop tracking updates on this webpage and you will no longer see it in the list. This action cannot be undone. Are you sure you want to remove this website?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{bgcolor:'#282D51'}}>
        <Box
              sx={{
                width: "100%",
                height: "57px",
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Box
                sx={{
                  width: "257px",
                  height: "40px",
                  marginRight: "16px",
                  marginTop: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{
                    width: "103px",
                    height: "40px",
                    textTransform: "none",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    textAlign: "center",
                    bgcolor: "#FFFFFF",
                    color: "#17182C",
                    '&:hover': {
                        backgroundColor: '#CDCFDF'

                      },
                  }}
                  onClick={() => setOpenDeleteModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => handleDeleteWebsite(currentWebsiteLinkedId)}
                  sx={{
                    width: "144px",
                    height: "40px",
                    textTransform: "none",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    textAlign: "center",
                    bgcolor: "#DD2025",
                    color: "#FFFFFF",
                    '&:hover': {
                        backgroundColor: '#E6E0E91F'

                      },
                  }}
                >
                  Remove site
                </Button>
              </Box>
            </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
  
}

export default MessageWindowForTrackWebsite;
