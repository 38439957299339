// import React, { useState,useRef } from "react";
// import { Modal, Button, Form } from "react-bootstrap";
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import { trackSearchInteraction } from "../../../config/analytics";
// import { user_conversation_bg_color } from "../../../constants/colors";

// const EditLastPrompt = ({ msg }) => {
//   const contentToCheck = msg?.user || (msg?.sender === "user" && msg?.content);
//   const [showModal, setShowModal] = useState(false);
//   const [editedPrompt, setEditedPrompt] = useState(contentToCheck || "");
//   const { editClick, setEditClick,setSending,setWaitThread,setCurrentThread,sendMessage } = useWorkspace();
// // console.log("99999999999999999999999999999999999999999999999999999",contentToCheck)
//   // Open Modal

//   const textareaRef = useRef(null);

//   const handleEditClick = () => {
//     setShowModal(true);
//   };

//   // Close Modal
//   const handleClose = () => {
//     setEditClick(false);
//     setShowModal(false);
//   };

//   // Handle Save Button
//   const handleSave = () => {
//     // Call handleSubmit with the edited prompt
//     handleSubmit(editedPrompt);
//     handleClose(); // Close modal after saving
//   };

//   const decodeUnicode = (str) => {
//     const stringNewLine = str.split("\\n").join("\n");
//     let stringNewLineWithAnchorTag = stringNewLine.replace(/\\u[\dA-F]{4}|u[\dA-F]{4}/gi, (match) => {
//       const hexCode = match.replace(/\\?u/g, ''); // Remove '\u' or 'u'
//       return String.fromCharCode(parseInt(hexCode, 16)); // Convert hex code to character
//     });
//     return stringNewLineWithAnchorTag.replace(/\\/g, ''); // Remove extra backslashes
//   };

//   // Function to decode HTML entities
//   const decodeContent = (content) => decodeUnicode(content);

//   // Convert decoded content into editable textarea
//   const handlePromptChange = (e) => {
//     setEditedPrompt(e.target.value); // Update the edited prompt as user types
//   };

//   const findUrl = (msg) => {
//     const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
//     const match = urlRegex.exec(msg);
//     return match ? match[1] : null;
//   };
  
//   const getFileFormat = (url) => {
//     const parts = url.split('.');
//     return parts.length > 1 ? parts[parts.length - 1] : ''; // Return file format or empty string
//   };
  
//   const handleSubmit = async (input) => {
//     trackSearchInteraction(input);
//     setSending(true);
//     setWaitThread(true);
  
//     if (input.trim()) {
//       const latestEntry = { sender: "user", content: input };
//       setCurrentThread((prevThread) => [...prevThread, latestEntry]);
//       const entryResponse = await sendMessage(latestEntry).catch(console.error);
  
//       if (entryResponse) {
//         const url = findUrl(entryResponse.content);
//         if (url && url.includes("dailylimitexceeded")) {
//           const fileFormat = getFileFormat(url);
//           const responseMessage = {
//             content: `Hi there,\n\nIt looks like you’ve reached your daily limit for generating `+ fileFormat +` files...`,
//             sender: 'modalX',
//           };
//           setCurrentThread((prevThread) => [...prevThread, responseMessage]);
//         } else {
//           setCurrentThread((prevThread) => [...prevThread, entryResponse]);
//         }
//         setSending(false);
//       } else {
//         setCurrentThread((prevThread) => [...prevThread, { content: "Unable to process your query!..", sender: 'modalX' }]);
//         setSending(false);
//       }
//     }
  
//     setWaitThread(false);
//   };
  

//   return (
//     <div style={{padding:'0'}}>
//       {contentToCheck && (
//         <Form>
//         <Form.Group>
//           <Form.Control
//             as="textarea"
//             value={editedPrompt}
//             onChange={handlePromptChange}
//             ref={textareaRef}  // Attach ref to the textarea
//             style={{
//               wordBreak: "break-word",
//               textAlign: 'start',
//               marginRight: '-13px',
//               backgroundColor: user_conversation_bg_color,
//               border: 'none',  // Remove the border
//               resize: 'none',  // Disable resizing of the textarea
//               outline: 'none', // Remove focus border (outline)
//             }}
//           />
//         </Form.Group>
//       </Form>
//       )}
//       <div className="d-flex justify-content-end" style={{margin:'10px 10px'}}>
//       <Button
//         variant="secondary" // Retain default Bootstrap styling
//         onClick={handleClose}
//         className="me-2"
//         style={{
//             backgroundColor: 'transparent', // No background color
//             color: 'white', // Text color set to white
//             fontFamily: 'Inter, sans-serif', // Custom font family
//             fontSize: '16px', // Font size
//             fontWeight: 500, // Font weight
//             transition: 'opacity 0.3s', // Smooth opacity transition
//             border: 'none', // Ensure no borders if needed
//         }}
//         onMouseOver={(e) => (e.currentTarget.style.opacity = 0.8)} // Reduce opacity on hover
//         onMouseOut={(e) => (e.currentTarget.style.opacity = 1)}   // Reset opacity when not hovered
//         >
//         Cancel
//         </Button>

//         <Button
//             variant="primary"
//             onClick={handleSave}
//             style={{
//                 width: '64px',          // Set width to 64px
//                 height: '38px',         // Set height to 38px
//                 borderRadius: '4px',    // Set border radius to 4px
//                 backgroundColor: 'white', // Default background color
//                 color: 'black',         // Default text color
//                 transition: 'opacity 0.3s', // Smooth opacity transition
//                 fontSize: '16px',       // Font size 16px
//                 fontWeight: '500',      // Font weight 500
//                 fontFamily: 'Inter, sans-serif', // Make sure the correct font is applied
//                 boxShadow: 'none',      // Remove any default shadow
//                 border: 'none',         // Remove border if any
//                 padding: '0',           // Ensure padding is not interfering with width/height
//             }}
//             onMouseOver={(e) => (e.currentTarget.style.opacity = 0.8)} // Reduce opacity on hover
//             onMouseOut={(e) => (e.currentTarget.style.opacity = 1)}   // Reset opacity when not hovered
//             >
//             Save
//             </Button>
//       </div>
//     </div>
//   );
// };


// export default EditLastPrompt;


import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Form, Image, Row } from "react-bootstrap";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import { trackSearchInteraction } from "../../../config/analytics";
import { user_conversation_bg_color } from "../../../constants/colors";
import info from '../../../assets/info.png'
import { useMediaQuery } from "react-responsive";
import { containsMediaFile } from "../../../contexts/WorkspaceUtils";

const EditLastPrompt = ({ msg }) => {
  const contentToCheck = msg?.user || (msg?.sender === "user" && msg?.content);
  const [showModal, setShowModal] = useState(false);
  const [editedPrompt, setEditedPrompt] = useState(contentToCheck || "");
  const { editClick, setEditClick, setSending, setWaitThread, setCurrentThread, sendMessage, currentThread,currentWebsiteLinkedId,selectedWeb,setIswebTrackClicked,setCurrentWebsiteLinkedId,setSelectedWeb , stopMessageIdRef, setVisibleStopGenerationButton} = useWorkspace();
  
  const textareaRef = useRef(null);
  const isUnder991px = useMediaQuery({ maxWidth:991});

  // Focus the textarea and move the cursor to the end of the text when component mounts
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(editedPrompt.length, editedPrompt.length);
    }
  }, [showModal]);

  const handleEditClick = () => {
    setShowModal(true);
  };

  const handleCancelClick = () => {
    if(selectedWeb.length > 0 && currentThread.length > 0){
      setCurrentThread(prevThread => prevThread.slice(0, -1));
      setIswebTrackClicked(true);
    }
    setEditClick(false);
    setShowModal(false);
  }

  const handleClose = () => {
    setEditClick(false);
    setShowModal(false);
  };

  const handleSave = () => {
    handleSubmit(editedPrompt);
    handleClose();
    if(selectedWeb?.length > 0 || currentWebsiteLinkedId){
      setIswebTrackClicked(false);
      setCurrentWebsiteLinkedId(null);
      setSelectedWeb("");
    }
  };

  const decodeUnicode = (str) => {
    const stringNewLine = str.split("\\n").join("\n");
    let stringNewLineWithAnchorTag = stringNewLine.replace(/\\u[\dA-F]{4}|u[\dA-F]{4}/gi, (match) => {
      const hexCode = match.replace(/\\?u/g, ''); 
      return String.fromCharCode(parseInt(hexCode, 16)); 
    });
    return stringNewLineWithAnchorTag.replace(/\\/g, ''); 
  };

  const decodeContent = (content) => decodeUnicode(content);

  const handlePromptChange = (e) => {
    setEditedPrompt(e.target.value);
  };

  const findUrl = (msg) => {
    const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
    const match = urlRegex.exec(msg);
    return match ? match[1] : null;
  };
  
  const getFileFormat = (url) => {
    const parts = url.split('.');
    return parts.length > 1 ? parts[parts.length - 1] : ''; 
  };
  // const extractLinks = async (inputString) => {
  //   setArrayForLinksAvalible([])
  //   // Regular expressions for each file type
  //   const pptRegex = /https?:\/\/[^\s]+\.pptx\b/g;
  //   const jpegRegex = /https?:\/\/[^\s]+\.jpeg\b/g;
  //   const mp4Regex = /https?:\/\/[^\s]+\.mp4\b/g;
  //   const mp3Regex = /https?:\/\/[^\s]+\.mp3\b/g;
    
  //   // Extract and push matches into their respective arrays
  //   const pptMatches = inputString?.match(pptRegex);
  //   if (pptMatches) {
  //     setArrayForLinksAvalible((prev)=> [...prev, "pptx"])
  //   }
    
  //   const jpegMatches = inputString?.match(jpegRegex);
  //   if (jpegMatches) {
  //     setArrayForLinksAvalible((prev)=> [...prev, "image"])
  //   }
    
  //   const mp4Matches = inputString?.match(mp4Regex);
  //   if (mp4Matches) {
  //     setArrayForLinksAvalible((prev)=> [...prev, "video"])
  //   }
  //   const mp3Matches = inputString?.match(mp3Regex);
  //   if (mp3Matches) {
  //     setArrayForLinksAvalible((prev)=> [...prev, "audio"])
  //   }
    
  //   return 
  //   };
  
  const handleSubmit = async (input) => {
    trackSearchInteraction(input);
    setSending(true);
    setVisibleStopGenerationButton(true)
    setWaitThread(true);
  
    if (input.trim()) {
      const latestEntry = { sender: "user", content: input };
      // setCurrentThread((prevThread) => {
      //   const updatedThread = prevThread[prevThread.length - 1]?.sender === "user"
      //     ? prevThread.slice(0, -1) // Remove the last entry if it's from the user
      //     : prevThread;
      //   return [...updatedThread, latestEntry];
      // })
      if(currentThread[currentThread.length - 1]?.sender !== "user"){
        setCurrentThread((prevThread) => [...prevThread, latestEntry]);
      }
      
      const entryResponse = await sendMessage(latestEntry).catch(console.error);
      // if(findUrl(entryResponse.content)){
        // setHasLink(true)
        // await extractLinks(entryResponse.content)
        // }
      if (entryResponse) {
        const url = findUrl(entryResponse.content);
        if (url && url.includes("dailylimitexceeded")) {
          const fileFormat = getFileFormat(url);
          const responseMessage = {
            content: `Hi there,\n\nIt looks like you’ve reached your daily limit for generating `+ fileFormat +` files...`,
            sender: 'modalX',
          };
          setCurrentThread((prevThread) => [...prevThread, responseMessage]);
        }else if(stopMessageIdRef.current.includes(entryResponse.unique_id)){
          setCurrentThread((prevThread)=>[...prevThread])
          setVisibleStopGenerationButton(false);
        }
         else {
          // setCurrentThread((prevThread) => [...prevThread, entryResponse]);
          if(containsMediaFile(entryResponse.content)){
            setCurrentThread((prevThread) => [...prevThread, entryResponse]);
          }else{
            setCurrentThread((prevThread) => [...prevThread, entryResponse]);
            setVisibleStopGenerationButton(false);
          }
        }
        setSending(false);
      } else {
        setCurrentThread((prevThread) => [...prevThread, { content: "Unable to process your query!..", sender: 'modalX' }]);
        setSending(false);
        setVisibleStopGenerationButton(false)
      }
    }
  
    setWaitThread(false);
  };

  function cleanContent(content) {
    return content
      // Remove Markdown syntax (like # for headings, hyphens for lists)
      .replace(/^#* ?/gm, '')              // Remove leading `#` symbols from headings
      .replace(/^- /gm, '')                 // Remove `- ` from list items
      .replace(/(?:\[(.*?)\]\(#.*?\))/g, '$1')  // Remove Markdown links but keep the text
      .replace(/---+/g, '')                 // Remove horizontal rules
      .replace(/\n{2,}/g, '\n\n')           // Replace multiple newlines with a single newline for cleaner spacing
      .trim();
  }
  
  useEffect(() => {
    // Function to adjust height based on content
    const adjustHeight = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto'; // Reset the height
        textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 312)}px`; // Max height of 300px
      }
    };
    
    adjustHeight(); // Initial adjustment
  }, [editedPrompt]); // Run adjustment whenever the content changes

  return (
    <div style={{ margin:'20px 8px 20px 8px',  maxWidth:"100%", }}>
      {contentToCheck && (
        <Form style={{margin:"0px", padding:"0px"}}>
          <Form.Group>
            <Form.Control
              as="textarea"
              value={cleanContent(editedPrompt)}
              onChange={(e)=>{handlePromptChange(e);
                if (textareaRef.current) {
                  textareaRef.current.style.height = 'auto'; // Reset height
                  textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 312)}px`; // Max height of 300px
                }}
              }
              ref={textareaRef} 
              style={{
                minHeight:"12px",
                // paddingTop : '12px',
                padding:"0px",
                wordBreak: "break-word",
                textAlign: 'start',
                marginRight: '-13px',
                backgroundColor: user_conversation_bg_color,
                border: 'none',  
                resize: 'none',  
                outline: 'none', 
                boxShadow: 'none', // Remove the blue border
                // backgroundColor: 'blue',
              }}
            />
          </Form.Group>
        </Form>
      )}
      <div className="d-flex justify-content-between align-items-center" style={{ margin: isUnder991px ? "12px 0px" : '0px 0px', gap: isUnder991px ? "12px" : "",}}>
        <div style={{display:"flex", flexDirection:"row", gap:'8px', alignItems:"center"}}>
        <Image src={info} alt="icon"   style={{ width: '16px', height: '16px', }} />
        <p style={{display: "flex", textAlign:"start",fontFamily:"Inter",fontStyle:"normal", fontSize: "12px", fontWeight:400,lineHeight:"120%", letterSpacing:"0.1px" , alignItems:"center", margin: 0}}>This generates a new response, but you can still view the older response </p>
        </div>

        <div style={{display:"flex", flexDirection:"row", gap: isUnder991px ? '16px' : '20px', alignItems:"center"}}>
        <Button
          variant="primary"
          // onClick={handleClose}
          onClick={handleCancelClick}
          style={{
            height: '38px',
            backgroundColor: 'transparent',
            color: 'white',
            fontFamily: 'Inter, sans-serif',
            fontSize: '16px',
            fontWeight: 500,
            transition: 'opacity 0.3s',
            border: 'none',
            padding:"0px 10px",
            margin:"0px",
            '&:hover':{
              backgroundColor:"#282D60"
            }
          }}
          onMouseOver={(e) => (e.currentTarget.style.opacity = 0.8)}
          onMouseOut={(e) => (e.currentTarget.style.opacity = 1)}
        >
          Cancel
        </Button>

        <Button
          variant="primary"
          onClick={handleSave}
          style={{
            width: '64px',
            height: '38px',
            borderRadius: '4px',
            backgroundColor: 'white',
            color: 'black',
            transition: 'opacity 0.3s',
            fontSize: '16px',
            fontWeight: '500',
            fontFamily: 'Inter, sans-serif',
            boxShadow: 'none',
            border: 'none',
            padding: '0',
          }}
          onMouseOver={(e) => (e.currentTarget.style.opacity = 0.8)}
          onMouseOut={(e) => (e.currentTarget.style.opacity = 1)}
        >
          Save
        </Button>
        </div>
      </div>
    </div>
  );
};

export default EditLastPrompt;



