// import React, { useEffect, useRef, useState } from "react";
// import {
//   Box,
//   Button,
//   Grid,
//   Typography,
//   IconButton,
//   InputAdornment,
//   Input,
//   Card,
// } from "@mui/material";
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";
// import {
//   defaultSettings_button_bg_color,
//   hoveredSettings_button_bg_color,
//   hoveredSettings_Nav_bg_dm,
//   selected_SettingsButton_bg_color,
// } from "../../../../constants/colors";
// import { fetchLanguages } from "./VideoGenAPISettings";
// import SearchIcon from "@mui/icons-material/Search";

// const LanguageSettings = () => {
//   const [inputBoxValue, setInputBoxValue] = useState("");
//   const [debouncedValue, setDebouncedValue] = useState("");
//   const [filterLanguages, setFilterLanguages] = useState(null);
//   const {
//     genVideoAudioSettingsLoading,
//     setGenVideoAudioSettingsLoading,
//     genVideoLanguage,
//     setGenVideoLanguage,
//     selectedGenVideoLanguage,
//     setSelectedGenVideoLanguage,
//     showVideoGenSettings,
//   } = useWorkspace();
//   const theme = localStorage.getItem("theme");
//   const modalContentRef = useRef(null);

//   useEffect(() => {
//     // if (
//     //   genVideoAudioSettingsLoading &&
//     //   (genVideoSettingVoices.length === 0 ||
//     //     genVideoSettingVoices.length === undefined ||
//     //     genVideoVoice === null)
//     // ) {
//     //   setGenVideoAudioSettingsLoading(false);
//     if(!setGenVideoLanguage){
//       fetchLanguages(setGenVideoLanguage, setGenVideoAudioSettingsLoading);
//     }
//     // }
//   }, []);

//   useEffect(() => {
//     if(selectedGenVideoLanguage != null){
//       setGenVideoLanguage((prevLanguages) => {
//         const filteredLanguages = prevLanguages?.filter(lang => lang?.id !== selectedGenVideoLanguage?.id);
//         return [selectedGenVideoLanguage, ...filteredLanguages];
//       });
//     }
//   }, [selectedGenVideoLanguage]);



//   const handleLanguageSelect = (selectedLanguage) => {
//     // console.log(selectedLanguage)
//     setSelectedGenVideoLanguage(selectedLanguage);
//     modalContentRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
//   };

//   // Function to simulate filtering logic
//   const handleFilter = (value) => {
//     // console.log(value, "==================value=============");
//     // console.log(
//     //   genVideoLanguage,
//     //   "==================genVideoLanguage============="
//     // );
//     const filterValue = genVideoLanguage?.filter((item, i) =>
//       item?.languageName?.toLowerCase().includes(value.toLowerCase())
//     );
//     setFilterLanguages(filterValue);
//   };

//   // Debounce logic using useEffect
//   useEffect(() => {
//     if (showVideoGenSettings) {
//       const handler = setTimeout(() => {
//         setDebouncedValue(inputBoxValue);
//         handleFilter(inputBoxValue);
//       }, []);
//       return () => {
//         clearTimeout(handler);
//       };
//     }
//   }, [inputBoxValue]);

//   const customTextFieldStyles = {
//     "& .MuiInputLabel-root": {
//       fontFamily: "Inter",
//       fontSize: "16px",
//       fontStyle: "normal",
//       fontWeight: 400,
//       lineHeight: "24px",
//       letterSpacing: "0.5px",
//       color: "rgba(255, 255, 255, 0.80)",
//       "&.Mui-focused": {
//         color: "rgba(255, 255, 255, 0.80) !important",
//       },
//     },
//     "& .MuiInputBase-input": {
//       color: "#FFFFFF",
//     },
//     "& .MuiOutlinedInput-root": {
//       borderRadius: "8px",
//       backgroundColor: "#232746",
//       "& fieldset": {
//         borderColor: "rgba(255, 255, 255, 0.80)",
//       },
//       "&:hover fieldset": {
//         borderColor: "rgba(255, 255, 255, 0.80)",
//       },
//       "&.Mui-focused fieldset": {
//         borderColor: "rgba(255, 255, 255, 0.80) !important",
//       },
//     },
//     "& .MuiOutlinedInput-notchedOutline": {
//       borderColor: "transparent) !important",
//     },
//   };

//   const filterDataUI = (filterdata) => {
//     return (
//       <Grid container spacing={2}>
//         {filterdata?.map((language, i) => (
//           <Grid item key={i} xs="auto">
//             <Card
//               onClick={() => handleLanguageSelect(language)}
//               sx={{
//                 cursor: "pointer",
//                 height: "32px",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 padding: "6px 12px",
//                 boxSizing: "border-box",
//                 marginBottom: 1,
//                 marginRight: 1,
//                 bgcolor:
//                   selectedGenVideoLanguage?.id === language.id
//                     ? selected_SettingsButton_bg_color
//                     : theme === "dark"
//                     ? defaultSettings_button_bg_color
//                     : "",
//                 border:
//                   selectedGenVideoLanguage?.id === language.id
//                     ? "2px solid #CAC4D0"
//                     : "",
//                 "&:hover": {
//                     backgroundColor: "#373E6D", 
//                     border:" 1px solid #373E6D"
//                   },
//               }}
//             >
//               <Typography
//                 variant="body1"
//                 sx={{
//                   fontSize: "14px",
//                   fontWeight: "600",
//                   color: theme === "dark" ? "#CAC4D0" : "",
//                 }}
//               >
//                 {language.languageName}
//               </Typography>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>
//     );
//   };

//   return (
//     <>
//       {genVideoAudioSettingsLoading ? (
//         <Box sx={{ p: 2 }}>
//           <Typography>Loading Language</Typography>
//         </Box>
//       ) : (
//         <Box sx={{ p: 2 }} ref={modalContentRef}>
//           <Typography
//             variant="h6"
//             gutterBottom
//             style={{
//               fontSize: "16px",
//               fontWeight: "500",
//               width: "143px",
//               height: "20px",
//               marginBottom: "20px",
//             }}
//           >
//             Select Language
//           </Typography>

//           <Box sx={{ marginBottom: "20px", marginTop: "20px" }}>
//             <Input
//               type="text"
//               disableUnderline={true}
//               placeholder="Search language"
//               variant="outlined"
//               sx={customTextFieldStyles}
//               onChange={(e) => {
//                 setInputBoxValue(e.target.value);
//               }}
//               value={inputBoxValue}
//               style={{
//                 width: "100%",
//                 maxWidth: "180px",
//                 height: "48px",
//                 color: "#FFFFFF",
//                 backgroundColor: "#2F355F",
//                 borderRadius: "8px",
//                 padding: "0px 12px",
//                 boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
//                 "&:focus": {
//                   outline: "none",
//                 },
//               }}
//               startAdornment={
//                 <InputAdornment position="start">
//                   <SearchIcon style={{ color: "rgba(255, 255, 255, 0.80)" }} />
//                 </InputAdornment>
//               }
//             />
//           </Box>
//           {inputBoxValue == "" ? (
//             <Grid container spacing={1} justifyContent="flex-start">
//               {Array.isArray(genVideoLanguage) &&
//                 genVideoLanguage.map((voiceOption) => (
//                   <Grid item key={voiceOption.id}>
//                     <Button
//                       variant="outlined"
//                       onClick={() => handleLanguageSelect(voiceOption)}
//                       sx={{
//                         textTransform: "none",
//                         // width: "54px",
//                         // height: "32px",
//                         backgroundColor:
//                           selectedGenVideoLanguage?.id === voiceOption.id
//                             ? "#3a3f69"
//                             : "#30355f",
//                         // backgroundColor: theme === 'dark'? '#30355f' : '',
//                         color: theme === "dark" ? "white" : "black",
//                         "&:hover": {
//                                     backgroundColor: "#373E6D", 
//                                     border:" 1px solid #373E6D"
//                                   },
//                         borderColor:
//                           selectedGenVideoLanguage?.id === voiceOption.id
//                             ? "#cac4d0"
//                             : "transparent",
//                         mx: 0.5, // Add horizontal margin for spacing
//                       }}
//                     >
//                       {voiceOption.languageName}
//                     </Button>
//                   </Grid>
//                 ))}
//             </Grid>
//           ) : (
//             filterDataUI(filterLanguages)
//           )}
//         </Box>
//       )}
//     </>
//   );
// };

// export default LanguageSettings;

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  Grid,
  Typography,
  InputAdornment,
  Input,
  Button
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import {
  defaultSettings_button_bg_color,
  selected_SettingsButton_bg_color,
} from "../../../../constants/colors";
import { fetchLanguages } from "./VideoGenAPISettings";

const LanguageSettings = () => {
  const [inputBoxValue, setInputBoxValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [filterLanguages, setFilterLanguages] = useState([]);
  const {
    genVideoLanguage,
    setGenVideoLanguage,
    selectedGenVideoLanguage,
    setSelectedGenVideoLanguage,
    setGenVideoAudioSettingsLoading
  } = useWorkspace();
  const modalContentRef = useRef(null);
  const theme = localStorage.getItem("theme");

  useEffect(() => {
    if (genVideoLanguage?.length === 0) {
      fetchLanguages(setGenVideoLanguage,setGenVideoAudioSettingsLoading);
    }
  }, [setGenVideoLanguage, genVideoLanguage]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputBoxValue);
    }, 300); // Adjust debounce time as needed
    return () => clearTimeout(handler);
  }, [inputBoxValue]);

  useEffect(() => {
    if (debouncedValue) {
      const filtered = genVideoLanguage?.filter((item) =>
        item?.languageName?.toLowerCase().includes(debouncedValue.toLowerCase())
      );
      setFilterLanguages(filtered || []);
    } else {
      setFilterLanguages(genVideoLanguage || []);
    }
  }, [debouncedValue, genVideoLanguage]);

  const handleLanguageSelect = (selectedLanguage) => {
    setSelectedGenVideoLanguage(selectedLanguage);
    modalContentRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  
    // Move the selected language to the top
    const updatedLanguages = genVideoLanguage.filter(
      (language) => language.id !== selectedLanguage.id
    );
    setGenVideoLanguage([selectedLanguage, ...updatedLanguages]);
  };
  

  const renderLanguageCards = (languages) => (
    <Grid container spacing={2}>
      {languages.map((language, index) => (
        <Grid item key={index} xs="auto">
          <Button
            onClick={() => handleLanguageSelect(language)}
            sx={{
              cursor: "pointer",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "6px 12px",
              textTransform:"none",
              color: theme === "dark" ? "white" : "black",
              bgcolor:
                selectedGenVideoLanguage?.id === language.id
                  ? selected_SettingsButton_bg_color
                  : theme === "dark"
                  ? defaultSettings_button_bg_color
                  : "",
              borderColor:"transparent",
              border:
                selectedGenVideoLanguage?.id === language.id
                  ? "1px solid #CAC4D0"
                  : "",
              "&:hover": {
                backgroundColor: "#373E6D",
                borderColor:"#373E6D",
              },
            }}
          >
            {/* <Typography
              variant="body1"
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                color: theme === "dark" ? "#CAC4D0" : "",
              }}
            > */}
              {language.languageName}
            {/* </Typography> */}
          </Button>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Box sx={{ p: 2 }} ref={modalContentRef}>
      <Typography
        variant="h6"
        gutterBottom
        style={{
          fontSize: "16px",
          fontWeight: "500",
          marginBottom: "20px",
        }}
      >
        Select Language
      </Typography>

      <Box sx={{ marginBottom: "20px", marginTop: "20px" }}>
        <Input
          type="text"
          disableUnderline={true}
          placeholder="Search language"
          sx={{
            width: "100%",
            maxWidth: "180px",
            height: "48px",
            color: "#FFFFFF",
            backgroundColor: "#2F355F",
            borderRadius: "8px",
            padding: "0px 12px",
            boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
            "&:focus": {
              outline: "none",
            },
          }}
          onChange={(e) => setInputBoxValue(e.target.value)}
          value={inputBoxValue}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon style={{ color: "rgba(255, 255, 255, 0.80)" }} />
            </InputAdornment>
          }
        />
      </Box>

      {renderLanguageCards(filterLanguages)}
    </Box>
  );
};

export default LanguageSettings;

