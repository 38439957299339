// import React from 'react'

// function AllDelete() {
//   return (
//     <div>
      
//     </div>
//   )
// }

// export default AllDelete


import { Box, Dialog, IconButton, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { ButtonClick, CreateNewProjectConfirmation, DeleteAllProjectsConfirmation, trackButtonEvent } from "../config/analytics";
import sourceUrl from "../api/Source.api";
import { useWorkspace } from "../contexts/WorkspaceContext";
import { createThread } from "../contexts/WorkspaceUtils";

function AllDelete() {
  const { logout, toggleLogout, setToggleLogout ,allDelete, setAllDelete} = useAuth();
  const token = localStorage.getItem('token');
// const [toggleLogout , setToggleLogout] = useState(true)
  const {setShowLeftsideBar,setIswebTrackClicked,setCurrentWebsiteLinkedId,setShowSuggested,clearThread,setThreadFiles,setHistoryFiles,setThreadId,setCurrentThread,setToday,setYesterday,setThisWeek,setThisMonth,setPrevMonth} = useWorkspace();

  const navigate = useNavigate();

//   const handleLogout = async () => {
//     await logout();
//     localStorage.setItem("isLoggedIn", "");
//     localStorage.setItem("token", "");
//     localStorage.removeItem("userEmail");
//     localStorage.removeItem("current_thread_id");
//     localStorage.removeItem("current_history_id");

//     navigate("/");
//     setToggleLogout(false);
//   };


  const closefun = () => {
    setAllDelete(false);
  };

  const createThreadId = async () => {
    try {
      const response = await fetch(`${sourceUrl}/threads`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });

      const responseData = await response.json();

      if (responseData.status) {
        const newThreadId = responseData.data.match(/(.*)/)[1];
        setThreadId(newThreadId);
        localStorage.setItem('threadId', newThreadId); // Store the new threadId in localStorage
        return newThreadId;
      } else {
        throw new Error("Thread creation failed");
      }
    } catch (error) {
      console.error("Thread creation error:", error);
      return null;
    }
  }

  const handleDeleteAllProject = async () =>{
    // DeleteAllProjectsConfirmation()
    ButtonClick("delete_all_projects", "delete_all_projects")
    try {
      const response = await fetch(`${sourceUrl}/threads/deleteall`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });

      const responseData = await response.json();

      if (responseData.status) {
        if (window.innerWidth < 1281) {
          setShowLeftsideBar(false)
        }
        ButtonClick("new_project_click", "new Project")
        setIswebTrackClicked(false);
        setCurrentWebsiteLinkedId(null);
        setShowSuggested(true)
        clearThread();
        setThreadFiles([]);
        setHistoryFiles([]);
        localStorage.removeItem("current_thread_id");
        localStorage.removeItem("current_history_id");
        const newThreadId = await createThread(setThreadId).catch((error) => {
          console.error("Error creating thread ID:", error);
          return null;
        });
  
        if (!newThreadId) {
          console.log("Failed to create new thread ID");
          return;
        }
        setThreadId(newThreadId);
        localStorage.setItem('current_thread_id',newThreadId);
        navigate(`/workspace/${newThreadId}`)
        setCurrentThread([]);
        CreateNewProjectConfirmation(newThreadId)
        trackButtonEvent("button_click_New_Project", {
          button_name: "New Project",
          button_position: "Top-left",
        });
        setAllDelete(false);
      } else {
        alert("Delete all projects operation has been failed");
        setAllDelete(false);
      }
    } catch (error) {
      setAllDelete(false);
      console.error("Thread creation error:", error);
      return null;
    }
    setAllDelete(false);
    setToday([]);
    setYesterday([]);
    setThisWeek([]);
    setThisMonth([]);
    setPrevMonth([]);
  }

  return (
    
      <>
        <Dialog
          open={allDelete}
          close={closefun}
          PaperProps={{
            sx: {
              width: "100%", // Increase the width as needed
              borderRadius: "8px", // Adjust if you want to change the border radius
            },
          }}
        >
          <Box
            sx={{
              width: "600px",
              height: "200px",
              borderRadius: "8px",
              border: "1px solid rgba(255, 255, 255, 0.2)",
              bgcolor: "#171A2D",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  width: "560px",
                  height: "28px",
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    textAlign: "left",
                    color : '#FFFFFF'
                  }}
                >
                  Delete all projects?
                </Typography>
                <Box  sx={{
                    color : '#FFFFFF',
                    '&:hover' :{
                      cursor : "pointer"
                    }
                }} onClick={closefun}>
                    <CloseIcon/>
                    </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "560px",
                height: "20px",

                display: "flex",
                justifyContent: "center",
                margin: "auto",
                marginTop: "20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  textAlign: "left",
                  color : '#FFFFFF'
                }}
              >
                This will delete all projects and associated data. This action cannot be undone. Are you sure you want to delete all projects?
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                width: "580px",
                height: "72px",
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Box
                sx={{
                  width: "226px",
                  height: "40px",
                  marginRight: "16px",
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={closefun}
                  sx={{
                    width: "103px",
                    height: "40px",
                    textTransform: "none",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    textAlign: "center",
                    bgcolor: "#FFFFFF",
                    color: "#17182C",
                    '&:hover': {
                        backgroundColor: '#CDCFDF'

                      },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleDeleteAllProject}
                  sx={{
                    width: "103px",
                    height: "40px",
                    textTransform: "none",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    textAlign: "center",
                    bgcolor: "#DD2025",
                    color: "#FFFFFF",
                    '&:hover': {
                        backgroundColor: '#E6E0E91F'

                      },
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>
      </>
    
  );
}

export default AllDelete;


