// import React, { useState, useEffect } from "react";
// import { Col, Button, Offcanvas, Row } from "react-bootstrap";
// import { useMediaQuery } from "react-responsive";
// import SidebarContent from "./SidebarContent"; // Import the SidebarContent component
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import { SelectedChat_bg_color } from "../../../Provider/Color_Provider";
// import { hovered_chat_bg_color_dm, selected_chat_bg_color_dm } from "../../../constants/colors";
// import { Box } from "@mui/material";
// const Sidebar = () => {
//   const {Today,Yesterday,ThisWeek,ThisMonth,PrevMonth,setShowLeftsideBar,showLeftsideBar} = useWorkspace();
//   const [showSidebar, setShowSidebar] = useState(false);
//   const [userToggled, setUserToggled] = useState(false);
//   const isXsOrSm = useMediaQuery({ maxWidth: 1500 });
  

//   // useEffect(() => {
//   //   if (!userToggled) {
//   //     setShowSidebar(!isXsOrSm);
//   //   }
//   // }, [isXsOrSm, userToggled]);

//   useEffect(() => {
//     if(!showLeftsideBar){
//       setShowSidebar(false);
//       setUserToggled(true)
//     }
//   }, [showLeftsideBar])
  

//   useEffect(() => {
//     if((Today.length > 0 || Yesterday.length > 0 || ThisWeek.length > 0 || ThisMonth.length > 0 || PrevMonth.length > 0 ) && !userToggled){
//       setShowSidebar(true);
//       setShowLeftsideBar(true);
//     }
//   }, [Today,Yesterday,ThisWeek,ThisMonth,PrevMonth])

//   useEffect(() => {
//     if(isXsOrSm){
//       setShowSidebar(false);
//     }
//     else{
//       setShowSidebar(true)
//     }
//   }, [isXsOrSm])
  
  
//   const toggleSidebar = () => {
//     setShowSidebar(!showSidebar);
//     setShowLeftsideBar(!showSidebar)
//     setUserToggled((prev) => !prev);
//   };

//   const sidebarToggle = (
//     <Col
//       style={{
//         maxWidth: "1px",
//         minWidth: "1px",
//         backgroundColor:'#FFFFFF',
//         opacity:'10%'

//       }}
//       className="m-0 p-0 d-flex justify-content-start"
//     >
//       <Button
//         variant="Secondary"
//         className="p-0 m-0 border-0 bg-transparent"
//         onClick={toggleSidebar}
//       >
//         {showSidebar ? (
//           // <div
//           //   style={{
//           //     width: "25px",
//           //     minWidth: "25px",
//           //     maxWidth: "25px",
//           //     display: "flex",
//           //     alignItems: "center",
//           //     justifyContent: "center",
//           //   }}
//           // >
//           // <i className="bi bi-chevron-compact-left p-0 m-0 fs-4" />
//           <></>
//         ) : (
//           // </div>
//           // <div
//           //   style={{
//           //     width: "25px",
//           //     minWidth: "25px",
//           //     maxWidth: "25px",
//           //     display: "flex",
//           //     alignItems: "center",
//           //     justifyContent: "center",
//           //     backgroundColor:selected_chat_bg_color_dm,
//           //     color:'rgba(255,255,255,0.8)'
//           //   }}
//           // >
//           // <i className="bi bi-chevron-compact-right p-0 m-0 fs-4" />
//           // </div>
//           <Box 
//             sx={{
//               width:'25px',
//               display:'flex',
//               alignItems: "center",
//               justifyContent: "center",
//               backgroundColor:selected_chat_bg_color_dm,
//               color:'rgba(255,255,255,0.8)',
//               '&:hover': {
//                 bgcolor: hovered_chat_bg_color_dm,
//                 color:'#FFFFFF'
//               }
//             }}>
//             <i className="bi bi-chevron-compact-right p-0 m-0 fs-4" />
//           </Box>
//         )}
//       </Button>
//     </Col>
//   );

//   return (
//     <>
//       {isXsOrSm ? (
//         <Offcanvas
//           show={showSidebar}
//           onHide={toggleSidebar}
//           placement="start"
//           style={{
//             maxWidth: "285px",
//             minWidth: "285px",
//             overflowY: "auto",
//             display: "flex",
//             border: "none",
//             flexDirection: "column",
//           }}
//           className="p-0 m-0"
//         >
//           <Offcanvas.Body className="p-0 m-0">
//             <Row style={{ height: "100vh" }} className="p-0 m-0">
//               <>
//                 <SidebarContent />
//                 {showSidebar && sidebarToggle}
//               </>
//             </Row>
//           </Offcanvas.Body>
//         </Offcanvas>
//       ) : (
//         <>{showSidebar && <SidebarContent />}</>
//       )}
//       {sidebarToggle}
//     </>
//   );
// };

// export default Sidebar;


import React, { useState, useEffect } from "react";
import { Col, Button, Offcanvas, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import SidebarContent from "./SidebarContent";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import { hovered_chat_bg_color_dm, selected_chat_bg_color_dm } from "../../../constants/colors";
import { Box } from "@mui/material";
import { ExpandCollapseSidebar } from "../../../config/analytics";

const Sidebar = () => {
  const { Today, Yesterday, ThisWeek, ThisMonth, PrevMonth, setShowLeftsideBar, showLeftsideBar, showSidebar, setShowSidebar,currentThread,setShowSuggested } = useWorkspace();
  // const [showSidebar, setShowSidebar] = useState(false);
  const [userToggled, setUserToggled] = useState(false);
  const isXsOrSm = useMediaQuery({ maxWidth: 1280 });
  const mobileView = useMediaQuery({maxWidth: 992});

  useEffect(() => {
    if (!showLeftsideBar) {
      setShowSidebar(false);
      setUserToggled(true);
    }
  }, [showLeftsideBar]);

  useEffect(() => {
    if ((Today.length > 0 || Yesterday.length > 0 || ThisWeek.length > 0 || ThisMonth.length > 0 || PrevMonth.length > 0) && !userToggled && !mobileView) {
      setShowSidebar(true);
      setShowLeftsideBar(true);
    }
  }, [Today, Yesterday, ThisWeek, ThisMonth, PrevMonth]);

  useEffect(() => {
    if (isXsOrSm || mobileView) {
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }
  }, [isXsOrSm, mobileView]);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    ExpandCollapseSidebar(showSidebar ? "hideSideBar" : "ShowSideBar" )
    setShowLeftsideBar(!showSidebar);
    setUserToggled((prev) => !prev);
    if (currentThread?.length === 0){
      setShowSuggested(true)
    }
  };

  const sidebarToggle = (
    <>
      <Col
        style={{
          maxWidth: "1px",
          minWidth: "1px",
          backgroundColor: "#FFFFFF",
          opacity: "20%",
        }}
        className="m-0 p-0 d-flex justify-content-start"
      />
      <Button
        variant="Secondary"
        className="p-0 m-0 border-0 bg-transparent"
        onClick={toggleSidebar}
        style={{display : mobileView ? 'none' : 'block'}}
      >
        {showSidebar ? (
          <></>
        ) : (
          <Box
            sx={{
              width: '25px',
              display: 'flex',
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: selected_chat_bg_color_dm,
              color: 'rgba(255,255,255,0.8)',
              '&:hover': {
                bgcolor: hovered_chat_bg_color_dm,
                color: '#FFFFFF',
              },
            }}
          >
            <i className="bi bi-chevron-compact-right p-0 m-0 fs-4" />
          </Box>
        )}
      </Button>
      
    </>
  );

    return (
    <>
      {isXsOrSm ? (
        <Offcanvas
          show={showSidebar}
          onHide={toggleSidebar}
          placement="start"
          style={{
            maxWidth: "283px",
            // minWidth: "284px",
            overflowY: "auto",
            display: "flex",
            border: "none",
            flexDirection: "column",
          }}
          className="p-0 m-0"
        >
          <Offcanvas.Body className="p-0 m-0">
            <Row style={{ height: "100vh" }} className="p-0 m-0">
              <>
                <SidebarContent />
                {showSidebar && sidebarToggle}
              </>
            </Row>
          </Offcanvas.Body>
        </Offcanvas>
      ) : (
        <>{showSidebar && <SidebarContent />}</>
      )}
      {sidebarToggle}
    </>
  );
};

export default Sidebar;


