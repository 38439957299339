import React, { createContext, useContext, useState } from "react";

// Create the context
const DailyLimitExceedContext = createContext();

// Create a provider component
export const DailyLimitExceedProvider = ({ children }) => {
    const [dailyLimitExceed, setDailylimitExceed] = useState(false);

  return (
    <DailyLimitExceedContext.Provider value={{ dailyLimitExceed, setDailylimitExceed }}>
      {children}
    </DailyLimitExceedContext.Provider>
  );
};

// Custom hook to use the CreditRecharge context
export const useDailyLimitExceed = () => useContext(DailyLimitExceedContext);

