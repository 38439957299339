import React, { useEffect, useState } from 'react';
import { Box, Typography,CardMedia,Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions, FormControlLabel} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import canadaFlag from '../../../../assets/canadaFlag.png'
import defaultIcon from "../../../../assets/Website Tracking/DefaultIconFor_Websites.png";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import sourceUrl from '../../../../api/Source.api';
import { useMediaQuery } from 'react-responsive';
import { ToggleSwitch } from '../../../Common_Components/ToggleSwitch';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from "react-router-dom";
import { fetchWebsites } from '../../../../contexts/WorkspaceUtils';


function NavbarForTrackWebsite({ website,onHandleRefetch,updateWebList,toggleMoreButtonState}) {
  const {currentWebsiteLinkedId,setCurrentWebsiteLinkedId,setSelectedWeb, setCurrentWebs} = useWorkspace();
  const currentWeb = website?.find(item => item?.websiteId === currentWebsiteLinkedId);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const token = localStorage.getItem("token");
  const isUnder992px = useMediaQuery({ maxWidth:992});
  const navigate = useNavigate();

  const handleRefetchClick = () => {
    setIsClicked(true);
    onHandleRefetch();

    setTimeout(() => {
      setIsClicked(false);
    }, 500); 
  };

  useEffect(()=>{
    if(website){
      fetchWebsites(setCurrentWebs)
    }
  },[])

  const handleDeleteWebsite = async (id) => {
    try {
      // API call to delete the website with the specified ID
      const res = await axios.delete(`${sourceUrl}/v1/websiteTrackings/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      // Check if the response status is successful
      if (res.data.status) {
        console.log(res.data.message);
        alert("Website deleted successfully");
        handleBackToWebsiteListing();
        setOpenDeleteModal(false);
        updateWebList();
      } else {
        console.error("Deletion failed:", res.data.message);
      }
    } catch (error) {
      console.error("Failed to delete the website:", error);
      alert("An error occurred while deleting the website.");
    }
  };

  const handleRefetchData = () => {
    
  }

  const handleEnable = async (ele) => {
    try {
      await axios.put(`${sourceUrl}/v1/websiteTrackings/${ele?.websiteId}`,{
          "websiteId": ele?.websiteId,
          "websiteLink": ele?.websiteLink,
          "displayName": ele?.displayName,
          "frequencyInMins": ele?.frequencyInMins,
          "isDisabled": false,
          "isDefault": ele?.isDefault,
          "addedAt": ele?.addedAt
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
       // Refresh the website list or update the status locally if feasible
    } catch (error) {
       console.error("Failed to update status:", error);
    }
 };

 const handleDisable = async (ele) => {
  try {
    await axios.put(`${sourceUrl}/v1/websiteTrackings/${ele?.websiteId}`,{
      "websiteId": ele?.websiteId,
          "websiteLink": ele?.websiteLink,
          "displayName": ele?.displayName,
          "frequencyInMins": ele?.frequencyInMins,
          "isDisabled": true,
          "isDefault": ele?.isDefault,
          "addedAt": ele?.addedAt
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
     // Refresh the website list or update the status locally if feasible
  } catch (error) {
     console.error("Failed to update status:", error);
  }
};


  const handleBackToWebsiteListing = () => {
    updateWebList();
    setCurrentWebsiteLinkedId(null)
    setSelectedWeb("");
    navigate('/workspace/trackwithmodalx')
  }

  return (
    <div>
      {/* <Box sx={{
        maxWidth : '100%',
        height : '72px',
        bgcolor : '#171A2D',
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center',
        borderBottom : '1px solid #535774',
      }}>
        <Box sx={{
            // width : '309px',
            height : '24px',
            display : 'flex',
            justifyContent : 'space-between',
            alignItems : 'center',
        }}>
            <Box sx={{
                width : '24px',
                height : '24px',
            }}>
              <CardMedia
              component="img"

              image={defaultIcon}
              alt="loading"
            />

            </Box>
            <Typography sx={{
                fontSize : '14px',
                fontWeight : '400',
                textAlign : 'left',
                color : '#FFFFFF',
                marginLeft : '12px',

            }}>
            {currentWeb.displayName}
            </Typography>

        </Box>

        <Box sx={{
            width : '116px',
            height : '48px',
            display : 'flex',
            justifyContent : 'space-between',
            alignItems : 'center',
            position : 'relative', // To use top and left properties
            left : '200px', // Adjust the left position as needed

        }}>
            {currentWeb.notifications === true && 
              <Box 
                sx={{
                  width : '48px',
                  height : '48px',
                  bgcolor  : '#1C2039',
                  borderRadius : '50%',
                  display : 'flex',
                  justifyContent : 'center',
                  alignItems : 'center',
                  "&:hover": {
                      backgroundColor:  "#282D51",
                      cursor: "pointer"
                    },
                }}
                // onClick={onAddWebClicked}
                onClick={onHandleRefetch}
              >
              <CachedIcon/>

            </Box>}
            <Box sx={{
                width : '48px',
                height : '48px',
                bgcolor  : '#1C2039',
                borderRadius : '50%',
                display : 'flex',
                justifyContent : 'center',
                alignItems : 'center',
                "&:hover": {
                    backgroundColor:  "#282D51",
                    cursor: "pointer"
                  },

            }}><DeleteIcon/>

            </Box>

        </Box>

      </Box> */}
      <Box sx={{
        maxWidth: '100%',
        height: '72px',
        bgcolor: '#171A2D',
        display: 'flex',
        alignItems: 'center',
        justifyContent:"space-between",
        borderBottom: '1px solid #535774',
        position: 'relative', // Make this parent box relative for absolute positioning of child
        padding: isUnder992px ? '0px 12px' : "0px 20px", // Optional: Add padding to prevent content from touching the edge
      }}>
        {/* Left aligned arrow icon */}
        <Box 
              sx={{
                width: '48px',
                height: '48px',
                // bgcolor: '#1C2039',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: "pointer",
                // "&:hover": {
                //   backgroundColor:  "#282D51",
                //   cursor: "pointer"
                // },
              }}
              onClick={handleBackToWebsiteListing}
            >
              <ArrowBackIcon />
            </Box>

        {/* Middle Container for Typography */}
        <Box sx={{
          flexGrow: 1, // Allows this box to take up the available space
          display: 'flex',
          // justifyContent: isUnder992px ? 'left': "center" , // Center the content within this box
          alignItems: "center",
          position:  isUnder992px ? "" : "absolute",
          left:  isUnder992px ? "" : "50%",
          transform:  isUnder992px ? "" : "translateX(-50%)",
        }}>
          <Box sx={{
            width: '24px',
            height: '24px',
          }}>
              <CardMedia
                component="img"
                image={defaultIcon}
                alt="loading"
              />

          </Box>
          <Tooltip title={currentWeb?.displayName}>  
            {
              isUnder992px ?
              <Typography sx={{
                fontSize: '12px',
                fontWeight: '400',
                textAlign: 'left', // Center the text
                color: '#FFFFFF',
                marginLeft: '12px',
                marginRight: '12px', // Optional: Add some right margin for spacing
                cursor: 'pointer', 
    
              }}>            
                {currentWeb?.displayName?.length > 30 ? `${currentWeb?.displayName?.substring(0, 30)}...` : currentWeb?.displayName}
              </Typography>
              : 
              <Typography sx={{
                fontSize: '14px',
                fontWeight: '400',
                textAlign: 'center', // Center the text
                color: '#FFFFFF',
                marginLeft: '12px',
                marginRight: '12px', // Optional: Add some right margin for spacing
                cursor: 'pointer', 
    
              }}>            
                {currentWeb?.displayName?.length > 70 ? `${currentWeb?.displayName?.substring(0, 70)}...` : currentWeb?.displayName}
              </Typography>
            }       
          
          </Tooltip>
        </Box>


        {/* Right-aligned icons */}
        {isUnder992px?
        <Box 
          sx={{
            width: '48px',
            height: '48px',
            bgcolor: '#1C2039',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            "&:hover": {
              backgroundColor:  "#282D51",
              cursor: "pointer"
            },
          }}
          onClick={() => toggleMoreButtonState()}
        >
          <MoreVertIcon />
        </Box>
         :
        <Box sx={{
          // width: '303px',
          height: '48px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight:'12px',
        }}>
          {/* {currentWeb.notifications === true && 
          <Tooltip title="Get latest update">
            <Box 
              sx={{
                width: '48px',
                height: '48px',
                bgcolor: '#1C2039',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                "&:hover": {
                  backgroundColor:  "#282D51",
                  cursor: "pointer"
                },
              }}
              onClick={onHandleRefetch}
            >
              <CachedIcon/>
            </Box>
            </Tooltip>
          } */}
          <FormControlLabel
            control={<ToggleSwitch sx={{ m: 1 }} defaultChecked={currentWeb?.isDisabled === false} onClick={() => currentWeb?.isDisabled === false?handleDisable(currentWeb):handleEnable(currentWeb)} />}
            label="Enable tracking"
            labelPlacement="start" // Positions the label to the left of the switch
            sx={{marginRight:"20px"}}
          />

          <Box 
              sx={{
                width: '48px',
                height: '48px',
                bgcolor: isClicked ? "#2F355F"  : '#1C2039',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight:"20px",
                border:"",
                "&:hover": {
                  backgroundColor: isClicked  ? "#2F355F"  :  "#282D51",
                  cursor: "pointer"
                },
              }}
              onClick={handleRefetchClick}
            >
              <CachedIcon/>
            </Box>

        <Tooltip 
                  componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#E6E0E9",
                          color: "#17182C",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 500,
                          padding: "4px 8px 4px 8px",
                          borderRadius: "4px",
                          textAlign: "left",
                        },
                      },
                    }}
            title="Remove website">
          <Box sx={{
            width: '48px',
            height: '48px',
            bgcolor: '#1C2039',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            "&:hover": {
              backgroundColor:  "#282D51",
              cursor: "pointer"
            },
          }}
          onClick={() => setOpenDeleteModal(true)}
          >
            <DeleteIcon/>
          </Box>
        </Tooltip>
        </Box>}
      </Box>

      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md" // Set modal maxWidth to medium (720px) if using Material UI's breakpoints
        PaperProps={{
          sx: {
            maxWidth: '720px', // Override to ensure the dialog width is capped at 720px
            width: '100%', // This makes the dialog responsive, taking up full width up to 720px
          }
        }}
      >
        {/* <DialogTitle id="alert-dialog-title" sx={{fontSize:'20px',fontWeight:600,fontFamily:'Inter',borderBottom:'1px solid rgba(255,255,255,0.2)',bgcolor:'#282D51',color:'#FFFFFF'}}>{"Remove website?"}</DialogTitle> */}
        <Box
              sx={{
                // justifyContent: "center",
                display: "flex",
                bgcolor:'#282D51'
                ,borderBottom:'1px solid rgba(255,255,255,0.2)'
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "28px",
                  marginTop: "20px",
                  marginBottom:'20px',
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding : '0px 20px 0px 20px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    textAlign: "left",
                    color : '#FFFFFF'
                  }}
                >
                  Remove website?
                </Typography>
                <Box  sx={{
                    color : '#FFFFFF',
                    '&:hover' :{
                      cursor : "pointer"
                    }
                }} 
                onClick={() => setOpenDeleteModal(false)}
                >
                    <CloseIcon/>
                    </Box>
              </Box>
            </Box>
        <DialogContent sx={{bgcolor:'#282D51'}}>
          <DialogContentText id="alert-dialog-description" sx={{fontSize:'16px',color:'#FFFFFF',paddingTop:'16px'}}>
          ModalX will stop tracking updates on this webpage and you will no longer see it in the list. This action cannot be undone. Are you sure you want to remove this website?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{bgcolor:'#282D51'}}>
        <Box
              sx={{
                width: "100%",
                height: "57px",
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Box
                sx={{
                  width: "257px",
                  height: "40px",
                  marginRight: "16px",
                  marginTop: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{
                    width: "103px",
                    height: "40px",
                    textTransform: "none",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    textAlign: "center",
                    bgcolor: "#FFFFFF",
                    color: "#17182C",
                    '&:hover': {
                        backgroundColor: '#CDCFDF'

                      },
                  }}
                  onClick={() => setOpenDeleteModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => handleDeleteWebsite(currentWebsiteLinkedId)}
                  sx={{
                    width: "144px",
                    height: "40px",
                    textTransform: "none",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    textAlign: "center",
                    bgcolor: "#DD2025",
                    color: "#FFFFFF",
                    '&:hover': {
                        backgroundColor: '#E6E0E91F'

                      },
                  }}
                >
                  Remove site
                </Button>
              </Box>
            </Box>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default NavbarForTrackWebsite;
