import React, { useState } from "react";
import { New_Project_Button_bg_color } from "../../Provider/Color_Provider";
import {
  Box,
  Typography,
  Dialog,
  CardMedia,
  Button,
  TextField,
} from "@mui/material";
import errorIcon from "../../assets/modalXIconWIthErrorIcon.png";
import reportIcon from "../../assets/ReportIcon.png";
import CloseIcon from "@mui/icons-material/Close";
import I_icon from "../../assets/HelpButtons/I_icon.png";
import sourceUrl from "../../api/Source.api";
import CommonToast from "./CommonToast";

const ErrorPage = () => {
  const [openReportIssueModal, setOpenReportIssueModal] = useState(false);

  const handleRefreshPageButtonClick = () => {
    window.location.reload();
  };

  const handleReportIssueButtonClick = () => {
    setOpenReportIssueModal(true);
  };

  return (
    <>
      <Box
        onClick={(event) => event.stopPropagation()}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: "480px",
            marginTop: "125px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px 0px",
                margin: "0px 0px",
              }}
            >
              <img
                width={"108px"}
                height={"100px"}
                src={errorIcon}
                alt="error"
              />
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px 0px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "normal",
                textAlign: "center",
                margin: "0px 0px",
              }}
            >
              The system encountered an error
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "36px",
              width: "100%",
              padding: "0px 0px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "normal",
                textAlign: "center",
                margin: "0px 0px",
              }}
            >
              ModalX is experiencing high load at the moment. Please try
              refreshing the page.
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "60px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: New_Project_Button_bg_color,
                color: "rgba(23, 24, 44, 1)",
                padding: "10px 24px 10px 24px",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "20px",
                letterSpacing: "0.1px",
                textAlign: "center",
                width: "100%",
                maxWidth: "152px",
                textTransform: "none",
                "&:hover": {
                  bgcolor: "#ffd9aa",
                },
              }}
              onClick={handleRefreshPageButtonClick}
            >
              Refresh page
            </Button>
          </Box>

          <Box
            onClick={handleReportIssueButtonClick}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "40px",
              padding: "0px 0px",
              gap: "12px",
            }}
          >
            <img width={"20px"} height={"20px"} src={reportIcon} alt="icon" />

            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "normal",
                textAlign: "center",
                margin: "0px 0px",
                padding: "0px 0px",
                cursor: "pointer",
              }}
            >
              Report issue
            </Typography>
          </Box>
        </Box>
      </Box>
      <ReportIssueModal
        openReportIssueModal={openReportIssueModal}
        setOpenReportIssueModal={setOpenReportIssueModal}
      />
    </>
  );
};

export default ErrorPage;

const ReportIssueModal = ({
  openReportIssueModal,
  setOpenReportIssueModal,
}) => {
  const token = localStorage.getItem("token");
  const userEmail = localStorage.getItem("userEmail");

  const [feedback, setFeedback] = useState("");
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState("");
  const [iconType, setIconType] = useState("success");
  let lenFeedback = feedback.length;

  const handleCloseModal = () => {
    setOpenReportIssueModal(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (!feedback) {
      return; // Prevent submission if feedback is empty
    }

    try {
      const response = await fetch(`${sourceUrl}/support/contactus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
        body: JSON.stringify({
          email: userEmail, // replace with dynamic data
          message: feedback,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setOpen(true);
        setContent(data?.message);
        setFeedback("");
        setOpenReportIssueModal(false);
      } else {
        console.error("Something went wrong:", data.error || "Error occurred");
        setOpen(true);
        setIconType("error");
        setContent("Something went wrong:", data.error || "Error occurred");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setOpen(true);
      setIconType("error");
      setContent(error);
    }
  };

  return (
    <>
      <Dialog
        open={openReportIssueModal}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            width: "720px",
            //   height: "615px",
            borderRadius: "8px",
            //   border: "1px solid #CAC4D0",
            bgcolor: "#282D51",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "68px",
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              color: "#FFFFFF",
            }}
          >
            Report an issue or send feedback
          </Typography>
          <CloseIcon
            onClick={handleCloseModal}
            sx={{
              cursor: "pointer",
              color: "#FFFFFF",
            }}
          />
        </Box>

        <Box sx={{ margin: "40px 0px 0px 40px" }}>
          <Typography sx={{ fontSize: "14px", color: "#FFFFFF" }}>
            What issue did you face while using ModalX?
          </Typography>
        </Box>

        <TextField
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)} // Store user input in state
          variant="standard"
          placeholder={"Briefly describe the issue"}
          InputProps={{
            disableUnderline: true,
            sx: {
              padding: "10px", // Padding inside the text field
              "&::placeholder": {
                color: "rgba(255, 255, 255, 0.8)", // Custom color for placeholder
                fontSize: "16px", // Optional: Adjust font size
              },
            },
          }}
          sx={{
            margin: "20px 40px 0px 40px",
            height: "112px",
            border: "1px solid white",
            borderRadius: "4px",
            bgcolor: "#232746",
            "& .MuiInputBase-input": {
              color: "#FFFFFF",
            },
          }}
        />
        <Box
          sx={{
            margin: "40px 40px 0px 40px",

            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "15px",
              height: "15px",
            }}
          >
            <CardMedia component="img" image={I_icon} />
          </Box>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 400,
              textAlign: "left",
              marginLeft: "8px",
              color: "#FFFFFF",
            }}
          >
            ModalX may email you for more information or updates about the
            issue.
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
            height: "72px",
            display: "flex",
            justifyContent: "right",
          }}
        >
          <Box
            sx={{
              minWidth: "290px",
              height: "40px",
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              marginRight: "40px",
            }}
          >
            <Button
              onClick={handleCloseModal}
              sx={{
                width: "93px",
                height: "40px",
                textTransform: "none",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 600,
                textAlign: "center",
                bgcolor: "#FFFFFF",
                color: "#17182C",
                "&:hover": {
                  backgroundColor: "#CDCFDF",
                },
              }}
            >
              Close
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={lenFeedback === 0} // Disable the button if feedback is empty
              sx={{
                width: "181px",
                height: "40px",
                textTransform: "none",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 600,
                textAlign: "center",
                bgcolor: lenFeedback ? "#F6BE6B" : "rgba(230, 224, 233, 0.12) ",
                color: "#17182C",
                border: "none",
                "&:hover": {
                  bgcolor: "#ffd9aa",
                  border: "none",
                },
                "&:disabled": {
                  bgcolor: "rgba(230, 224, 233, 0.12)", // Explicitly set background when disabled
                  color: "#E6E0E9", // Explicitly set text color when disabled
                },
              }}
            >
              Submit feedback
            </Button>
          </Box>
        </Box>
      </Dialog>
      <CommonToast
        open={open}
        onClose={onClose}
        content={content}
        iconType={iconType}
      />
    </>
  );
};
