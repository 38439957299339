import React,{useEffect, useState} from 'react'
import WebsiteListingPage from './WebsiteListingPage'
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import MessageWindowForTrackWebsite from './MessageWindowForTrackWebsite';
import axios from 'axios';
import sourceUrl from '../../../../api/Source.api';
import TrackWebsiteModal from './TrackWebsiteModal';

const WebTrackHome = () => {
    const {iswebTrackClicked,currentWebsiteLinkedId, updateWebList,setUpdateWebList,currentWebs,setCurrentWebs, refetchClicked,setRefetchClicked,setWebTrackingWebsiteLoading} = useWorkspace();
    const token = localStorage.getItem('token');
    // const [currentWebs,setCurrentWebs] = useState([]);
    const [addWebClicked, setAddWebClicked] = useState(false);
    // const [refetchClicked,setRefetchClicked] = useState(false);
    // const [updateWebList,setUpdateWebList] = useState(false);

    const handleUpdateList = () => {
      setUpdateWebList(!updateWebList);
    };

    useEffect(() => {
      fetchWebsites();
    }, [addWebClicked,updateWebList,currentWebsiteLinkedId]);

    const handleAddWebClicked = () => {
      setAddWebClicked(!addWebClicked);
    };

    const handleRefetchClicked = () => {
      setRefetchClicked(!refetchClicked);
    };
  
    const fetchWebsites = async () => {
      setWebTrackingWebsiteLoading(true);
      try {
        const res = await axios.get(`${sourceUrl}/v1/websiteTrackings/listAll`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        // const sortedData = res.data.data.sort((a, b) => {
        //   if (a.notifications === b.notifications) return 0;
        //   return a.notifications ? -1 : 1;
        // });
        const sortedData = res?.data?.data?.sort((a, b) => {
          // Step 1: Prioritize websites with `notificationCount > 0`
          const aHasNotifications = a.notificationCount > 0;
          const bHasNotifications = b.notificationCount > 0;
        
          if (aHasNotifications !== bHasNotifications) {
            return bHasNotifications - aHasNotifications; // Websites with notifications > 0 come first
          }
        
          // Step 2: Among websites with the same notification status, sort by `addedAt` (most recent first)
          return new Date(b.addedAt) - new Date(a.addedAt);
        });
        
        setCurrentWebs(sortedData);
        setWebTrackingWebsiteLoading(false);
      } catch (error) {
        console.error(error);
        setWebTrackingWebsiteLoading(false);
      }
    };

  return (
    <div>
      <WebsiteListingPage website={currentWebs} updateWebList={handleUpdateList} />
     {/* { currentWebsiteLinkedId ? <MessageWindowForTrackWebsite website={currentWebs} onHandleRefetch={handleRefetchClicked} refetchClicked={refetchClicked} updateWebList={handleUpdateList} /> : <WebsiteListingPage website={currentWebs} updateWebList={handleUpdateList} />} */}
        <TrackWebsiteModal
          onAddWebClicked={handleAddWebClicked}
        />
    </div>
  )
}

export default WebTrackHome