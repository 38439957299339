import sourceUrl from "../../../../api/Source.api";
import { avatarVoiceLanguages } from '../../../../constants/avatarVoiceLanguages';
const token = localStorage.getItem('token');
const isLoggedIn = localStorage.getItem('isLoggedIn');


const VideoSettingsAPI = (setCategories,setBackgrounds,setSelectedCategory ,setAvatars, setLanguages,setVoices, avatarsloading, setAvatarsLoading, voiceloading, setVoiceLoading  ) =>{

    fetchAvatars(setAvatars, setAvatarsLoading)
    fetchCategories(setCategories,setSelectedCategory)
    fetchBackgrounds(setBackgrounds)
    fetchVoices(setLanguages, setVoices, setVoiceLoading)

}

const fetchVoices = async (setLanguages, setVoices, setVoiceLoading) => {
  try {
    const response = await fetch(`${sourceUrl}/video/avatarVoice`, {
      credentials: "include",
      headers: {
        ...(isLoggedIn ? { Authorization: `Bearer ${token}` } : {}),
      }
    });

    if (!response.ok) {
      throw new Error("Failed to fetch voices");
    }
    const json = await response.json();
    const voiceData = json.data.data;
    setLanguages(avatarVoiceLanguages);
    setVoices(voiceData);
  } catch (error) {
    console.error("Error fetching voices:", error.message);
  }
  setVoiceLoading(false)
};

const fetchAvatars = async (setAvatars, setAvatarsLoading) => {
  try {
    const response = await fetch(`${sourceUrl}/video/avatar`, {
      credentials: "include",
      headers: { 
        ...(isLoggedIn ? { Authorization: `Bearer ${token}` } : {}),
      }
    });
    if (!response.ok) {
      throw new Error("Failed to fetch avatars");
    }
    const json = await response.json();
    setAvatars(json["data"]["data"]);
  } catch (error) {
    console.error("Error fetching avatars:", error.message);
  }
  setAvatarsLoading(false);
};

const fetchCategories = async (setCategories,setSelectedCategory) => {
  try {
    const response = await fetch(`${sourceUrl}/video/backgroundCategory`, {
      credentials: "include",
      headers: { 
        ...(isLoggedIn ? { Authorization: `Bearer ${token}` } : {}),
      },
    });
    if (!response.ok)
      throw new Error("Failed to fetch background categories");
    const json = await response.json();
    setCategories(json.data);
    setSelectedCategory(json.data[0]);
  } catch (error) {
    console.error("Error fetching background categories:", error.message);
  }
};

const fetchBackgrounds = async (setBackgrounds) => {
  try {
    const response = await fetch(`${sourceUrl}/video/backgroundImage`, {
      credentials: "include",
      headers: { 
        ...(isLoggedIn ? { Authorization: `Bearer ${token}` } : {}),
      },
    });
    if (!response.ok) throw new Error("Failed to fetch background images");
    const json = await response.json();
    setBackgrounds(json.data.data);
  } catch (error) {
    console.error("Error fetching background images:", error.message);
  }
};

export { VideoSettingsAPI, fetchBackgrounds, fetchCategories , fetchAvatars , fetchVoices }

