import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Pagination from "@mui/material/Pagination";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const CurrentUsers = ({
  error,
  setError,
  users,
  loading,
  currentPage,
  setCurrentPage,
  totalPages,
  totalUsersCount,
}) => {
  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update the current page when a page is clicked
  };

  // Custom theme for Material UI Pagination
  const theme = createTheme({
    typography: {
      fontFamily: "Inter",
    },
    components: {
      MuiPagination: {
        styleOverrides: {
          root: {
            "& .MuiPaginationItem-root": {
              color: "#ffffff",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "rgba(179, 168, 183, 0.26)",
              },
              fontSize: "14px",
            },
            "& .MuiPaginationItem-page.Mui-selected": {
              backgroundColor: "#CAC4D0",
              color: "black",
              "&:hover": {
                backgroundColor: "#CAC4D0",
              },
            },
            "& .MuiPaginationItem-ellipsis": {
              color: "#ffffff",
            },
            "& .MuiPaginationItem-previousNext": {
              color: "#ffffff",
              borderRadius: "20px",
            },
          },
        },
      },
    },
  });

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between mb-3">
          <h5 className="card-title">Total Users Count:</h5>
          <h5 className="card-title text-primary">{totalUsersCount}</h5>
        </div>
        <div className="overflow-auto" style={{ maxHeight: "400px" }}>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : (
            <ul className="list-group">
              {users.map((user, index) => (
                <li key={index} className="list-group-item">
                  {user}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="d-flex justify-content-center mt-3">
          <ThemeProvider theme={theme}>
            <Pagination
              count={totalPages} // Total number of pages
              page={currentPage} // Current page
              siblingCount={1} // Show one previous and one upcoming page
              boundaryCount={1} // Show first and last page buttons
              onChange={handlePageChange} // Handle page change
              shape="rounded" // Rounded buttons
              size="large" // Larger buttons
            />
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default CurrentUsers;
