import React, { useState,useEffect } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { Button, Grid, Typography } from '@mui/material';
import he from 'he';
import black_logo from "../../../../assets/ModalX_-orange_Icon.png";
import white_logo from "../../../../assets/modalX_robot_icon_white.png";
import LinkedIn_Icon from "../../../../assets/LinkedInIconForUserInfo.png";
import EditUserDetails from './EditUserDetails'; // Import the EditUserDetails component
import { hovered_chat_bg_color_dm, uploadFiles_button_clicked_bg_color, uploadFiles_button_default_bg_color, uploadFiles_button_hovered_bg_color } from '../../../../constants/colors';
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";
import { InvokeOnBoardingStatusAPI } from '../../../../contexts/WorkspaceUtils';

const User_Challenges = ({ message }) => {
  const [theme, setTheme] = useState(localStorage.getItem('theme'));
  const [openEditModal, setOpenEditModal] = useState(false);
  const contentToCheck = message?.content || message?.agent;
  const {setSending,submitChallenge,setCurrentThread,setWaitThread,setOnBoardingData,onBoardingData} = useWorkspace();
  const [challenges,setChallenges] = useState([]);

  const extractInfo = (possibleKeys, content) => {
    const lines = content.split('\n');
    for (const line of lines) {
      const trimmedLine = line.trim();
      for (const key of possibleKeys) {
        if (trimmedLine.startsWith(`${key}:`)) {
          return trimmedLine.replace(`${key}:`, '').trim();
        }
      }
    }
    return 'Not provided';
  };

  // const  name = extractInfo(['Name'],contentToCheck)
  // const companyName = extractInfo(['Company Name', 'Company', 'Organisation Name'], contentToCheck);
  // const role = extractInfo(['Role of the Person', 'Role', 'Position'], contentToCheck);
  // const linkedIn = extractInfo(['LinkedIn Profile', 'LinkedIn', 'LinkedIn URL'], contentToCheck);

  // const industry = extractInfo(['Industry', 'Sector'], contentToCheck);
  // const founded = extractInfo(['Founded', 'Established'], contentToCheck);
  // const teamSize = extractInfo(['Team Size', 'Employees', 'Employee Count'], contentToCheck);
  // const revenue = extractInfo(['Revenue (FY23)', 'Revenue'], contentToCheck);
  // const profit = extractInfo(['Profit (FY23)', 'Profit'], contentToCheck);
  // const fundingRaised = extractInfo(['Funding Raised', 'Total Funding'], contentToCheck);

  // const companyDetails = extractInfo(['Company Details', 'Organisation Details', 'Organisation Information'], contentToCheck);

  // const companyDetailsMessage = companyDetails !== 'Not provided'
  //   ? <p style={{ fontSize: '16px', color: 'rgba(255,255,255,0.8)', marginTop: '10px' }}>{companyDetails}</p>
  //   : <p style={{ fontSize: '16px', color: 'rgba(255,255,255,0.8)', marginTop: '10px' }}>Currently, we don't have specific information available about the company. We'll update it as soon as possible.</p>;

    // const firstSentenceMatch = contentToCheck.match(/^.*?(\.|\?|!)/);
    // const firstSentence = firstSentenceMatch ? firstSentenceMatch[0] : 'Welcome!';

    const extractFirstSentence = (content) => {
      // Find the index where the challenge list starts
      const challengeStartIndex = content.indexOf('\\n');
      
      if (challengeStartIndex === -1) {
        // If no challenge list found, return the entire content as the first sentence
        return content.split('\n')[0];
      }
    
      // Extract content before the challenge list starts
      const firstSentence = content.substring(0, challengeStartIndex);
      
      // Trim and ensure we end at a complete sentence
      const trimmedFirstSentence = firstSentence.trim();
      const sentenceEndMatch = trimmedFirstSentence.match(/.*?([.!?])\s*$/);
      
      return sentenceEndMatch ? sentenceEndMatch[0] : trimmedFirstSentence;
    };
  
    const firstSentence = extractFirstSentence(contentToCheck);

    

    useEffect(() => {
      if (contentToCheck) {
        extractChallenges(contentToCheck,possibleKeys);
      }
    }, [contentToCheck]);

    // const extractChallenges = (content) => {
    //   const challengePattern = /\d+\.\s\*\*(.*?)\*\*/g;
    //   const challengesArray = [];
    //   let match;
    
    //   console.log("Extracting challenges from content:", content); // Log the content
    
    //   while ((match = challengePattern.exec(content)) !== null) {
    //     let title = match[1].trim();
    
    //     if (title.endsWith(':')) {
    //       title = title.slice(0, -1).trim();
    //     }
    
    //     challengesArray.push({
    //       title: title,
    //       details: '' // Skip details for now
    //     });
    
    //     console.log("Challenge title added:", title); // Log each added challenge
    //   }
    
    //   setChallenges(challengesArray); // Update state
    //   console.log("All Challenges extracted:", challengesArray); // Log all extracted challenges
    // };


    const extractChallenges = (content, possibleKeys) => {
      const challengesArray = [];
      let match;
    
      // Convert possibleKeys into a regex pattern to match any of them
      const keysPattern = possibleKeys
        ?.map(key => key.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
        .join('|');
    
      // Regex to match challenges by number (e.g., "1. **Challenge Title**")
      const numberedChallengePattern = /\d+\.\s\*\*(.*?)\*\*/g;
    
      // Regex to match challenges with specific keys like "Professional Challenge" (e.g., "**Professional Challenge 1: Challenge Title**")
      const labeledChallengePattern = new RegExp(`\\*\\*(${keysPattern})\\s\\d+[:\\s]+(.*?)\\*\\*`, 'g');
    
      console.log("Extracting challenges from content:", content); // Log the content
    
      // First, match numbered challenges (e.g., "1. **Challenge Title**")
      while ((match = numberedChallengePattern.exec(content)) !== null) {
        let title = match[1].trim();
    
        if (title.endsWith(':')) {
          title = title.slice(0, -1).trim();  // Remove trailing colon if present
        }
    
        challengesArray.push({
          title: title,
          details: ''  // Skipping details extraction for now
        });
    
        console.log("Numbered challenge added:", title);  // Log each added challenge
      }
    
      // Then, match challenges with specific labels (e.g., "**Professional Challenge 1: Challenge Title**")
      while ((match = labeledChallengePattern.exec(content)) !== null) {
        let title = match[2].trim();
    
        if (title.endsWith(':')) {
          title = title.slice(0, -1).trim();  // Remove trailing colon if present
        }
    
        challengesArray.push({
          title: title,
          details: ''  // Skipping details extraction for now
        });
    
        console.log("Labeled challenge added:", title);  // Log each added challenge
      }
    
      setChallenges(challengesArray);  // Update state with the extracted challenges
      console.log("All challenges extracted:", challengesArray);  // Log all extracted challenges
    };
    

    const possibleKeys = ["Professional Challenge", "challenge","Challenge"];
    
  
    // const challenges = extractChallenges(contentToCheck);

  const decodeContent = (content) => {
    return he.decode(content);
  };

  const handleEditClick = () => {
    setOpenEditModal(true);
  };

  const handleSendChallenge = async (prompt) => {
    setWaitThread(true);
    setSending(true);
    try {
      const latestEntry = { sender: "user", content: prompt };
      setCurrentThread((prevThread) => [...prevThread, latestEntry]);

      const entryResponse = await submitChallenge(latestEntry);
      if (entryResponse) {
        setCurrentThread((prevThread) => [...prevThread, entryResponse]);
      } else {
        setCurrentThread((prevThread) => [
          ...prevThread, 
          { content: "Unable to process your query!", sender: "modalX" }
        ]);
      }
      const res = await InvokeOnBoardingStatusAPI();
      setOnBoardingData(res);
    } catch (error) {
      console.error("Error sending initial message:", error);
    } finally {
      setWaitThread(false);
      setSending(false);
    }
  };

  const findUrl = (msg) => {
    const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
    const match = urlRegex.exec(msg);
    return match ? match[1] : null;
  };

  const removeUrlsAndBrackets = (msg) => {
    return msg.replace(/\(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt)\)/g, '');
  };

  const getFileFormat = (url) => {
    // Extract the file extension from the URL
    const parts = url.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1]; // Return the last part as the file format
    } else {
      return ''; // Return empty string if no file extension found
    }
  };

  const decodeUnicode = (str) => {
    // const stringWithoutDoubleBackslashes = str.split("\\\")
    const stringNewLine = str.split("\\n").join("\n")
    let stringNewLineWithAnchorTag = stringNewLine.replace(/\\u[\dA-F]{4}|u[\dA-F]{4}/gi, (match) => {
      const hexCode = match.replace(/\\?u/g, ''); // Remove '\u' or 'u'
      return String.fromCharCode(parseInt(hexCode, 16)); // Convert hex code to character
    });
    const finalString = stringNewLineWithAnchorTag.replace(/\\/g, '');
    return finalString
  };



  function renderResponse(response){  
    response = findUrl(response) ? removeUrlsAndBrackets(response) : response;                   
    if (findUrl(response) && findUrl(response).includes('dailylimitexceeded')) {
      // updatedParagraph = 'Your daily '+ getFileFormat(findUrl(message.agent))+' generation limit was exceeded. Consider upgrading to our premium plan for unlimited access to features.' ;
      let updatedParagraph = `Hi there,\n\nIt looks like you’ve reached your daily limit for generating `+ getFileFormat(findUrl(response))+` files. We’re really sorry about that! We know how important uninterrupted access is for you.\n\nTo keep enjoying all the great benefits and have a seamless experience, we’d love for you to consider upgrading to our premium plan.\n\nThank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.\n\nWarm regards,\n\nThe ModalX Team`
     
       return <div style={{ wordBreak: "break-word" }}>
          <MDEditor.Markdown source={decodeUnicode(updatedParagraph)} style={{backgroundColor:"#171A2D", color:"white" }} previewOptions={{rehypePlugins: [[rehypeSanitize]],}}/> 
        </div>
      
    }else{
      return <div style={{ wordBreak: "break-word",  }}>
        <MDEditor.Markdown source={decodeUnicode(response)} style={{ backgroundColor:"#171A2D", color:"white"}}  className="custom-md-editor"/>
      </div>
    } 
}

  return (
    <div>
      {(message.sender === "user" || message.user) && (
        <Col xs={11} className="text-end" style={{  marginBottom: '20px', paddingRight: '70px', paddingLeft: '60px' , marginLeft : '' }}>
          <Row style={{backgroundColor : '',marginRight : '-120px'}}>
            {/* <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0" style={{ backgroundColor: '#1F233E', borderRadius: '50%', width: '48px', height: '48px' }}>
              <i className="bi bi-person fs-4" style={{ margin: 'auto' }} />
            </Col> */}
            <Col xs={11} style={{ margin: 'auto' , }}>
              {message.user && typeof message.user === 'string' && message.user.split("\n").map((line, index) => (
                <div key={index} style={{ wordBreak: "break-word" }}>
                  {decodeContent(line)}
                </div>
              ))}
            </Col>
            <Col xs={1}  style={{ backgroundColor: '#1F233E', width: '48px', height: '48px',display : 'flex',
              alignItems : 'center', marginLeft : '0px', borderRadius : '24px'
             }}>
              <i className="bi bi-person fs-4" style={{ margin: 'auto',  }} />
            </Col>
          </Row>
        </Col>
      )}

      {contentToCheck && (
        <Row className="justify-content-start" style={{backgroundColor : ''}}>
          <Col xs={11} className="text-end" style={{display : 'flex'}}>
          <Col xs={1} style={{marginLeft : '10px', backgroundColor : '#1F233E' , width : '48px', height : '48px',borderRadius : '24px',
            display : 'flex', alignItems : 'center', justifyContent : 'center'
          }} >
                <img style={{width : '24px', height : '24px'}} src={theme === 'dark' ? black_logo : white_logo} alt="ModalX Icon" className="img-fluid" />
              </Col>
            <Row>
              <Col xs={11} style={{ margin: 'auto', paddingLeft: '0px', marginBottom: '40px', paddingTop: '10px', backgroundColor : '' }} className={contentToCheck.length > 55 ? "text-start" : 'text-end'}>
                <Row>
                  <div style={{ wordBreak: "break-word", marginBottom: '20px',backgroundColor : ''}}>
                    {firstSentence && renderResponse(firstSentence)}
                  </div>
                  {/* {challenges.map((challenge, index) => (
                    <Grid item xs={12} key={index} sx={{ backgroundColor: '#1F233E', borderRadius: '5px', marginBottom: '10px', padding: '12px 24px',cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: hovered_chat_bg_color_dm,
                      }, }} 
                      onClick={() => handleSendChallenge(challenge.title)}
                      >
                      <Typography variant="h6" fontSize={"16px"} sx={{ fontWeight: '400', color: 'rgba(255,255,255,0.8)' }}>
                        {challenge.title}
                      </Typography>
                    </Grid>
                  ))} */}
                  {challenges.slice(0, 3).map((challenge, index) => (
                    <Grid
                      item
                      xs={12}
                      key={index}
                      sx={{
                        backgroundColor: '#1F233E',
                        borderRadius: '5px',
                        marginBottom: '10px',
                        padding: '12px 24px',
                        cursor: 'pointer',
                        marginLeft : '12px',
                        '&:hover': {
                          backgroundColor: hovered_chat_bg_color_dm,
                        },
                      }}
                      onClick={() => onBoardingData?.Solutions === true ? null : handleSendChallenge(challenge.title)}
                    >
                      <Typography
                        variant="h6"
                        fontSize={"16px"}
                        sx={{ fontWeight: '400', color: 'rgba(255,255,255,0.8)' }}
                      >
                        {challenge.title}
                      </Typography>
                    </Grid>
                  ))}

                </Row>
              </Col>
              {/* <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
                <img src={theme === 'dark' ? black_logo : white_logo} alt="ModalX Icon" className="img-fluid" />
              </Col> */}
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default User_Challenges; 


// import React, { useState } from 'react';
// import { Grid, Card, Typography } from '@mui/material';
// import { Button } from '@mui/material';
// import he from 'he';
// import black_logo from "../../../../assets/ModalX_-orange_Icon.png";
// import white_logo from "../../../../assets/modalX_robot_icon_white.png";
// import LinkedIn_Icon from "../../../../assets/LinkedInIconForUserInfo.png";
// import EditUserDetails from './EditUserDetails'; // Import the EditUserDetails component
// import { hovered_chat_bg_color_dm, uploadFiles_button_clicked_bg_color, uploadFiles_button_default_bg_color, uploadFiles_button_hovered_bg_color } from '../../../../constants/colors';
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";

// const User_Challenges = ({ message }) => {
//   const [theme, setTheme] = useState(localStorage.getItem('theme'));
//   const [openEditModal, setOpenEditModal] = useState(false);
//   const contentToCheck = message?.content || message?.agent;
//   const {sendMessage,setWaitThread,setCurrentThread,setSending} = useWorkspace();

//   const extractInfo = (possibleKeys, content) => {
//     const lines = content.split('\n');
//     for (const line of lines) {
//       const trimmedLine = line.trim();
//       for (const key of possibleKeys) {
//         if (trimmedLine.startsWith(`${key}:`)) {
//           return trimmedLine.replace(`${key}:`, '').trim();
//         }
//       }
//     }
//     return 'Not provided';
//   };

//   const extractFirstSentence = (content) => {
//     const challengeStartIndex = content.indexOf('\n\n1.');
    
//     if (challengeStartIndex === -1) {
//       return content.split('\n')[0];
//     }
  
//     const firstSentence = content.substring(0, challengeStartIndex);
//     const trimmedFirstSentence = firstSentence.trim();
//     const sentenceEndMatch = trimmedFirstSentence.match(/.*?([.!?])\s*$/);
    
//     return sentenceEndMatch ? sentenceEndMatch[0] : trimmedFirstSentence;
//   };

//   const firstSentence = extractFirstSentence(contentToCheck);

//   const extractChallenges = (content) => {
//     const challengePattern = /\d+\.\s\*\*(.*?)\*\*:/g; // Pattern to match "1. **Title**:"
//     const challenges = [];
//     let match;
  
//     while ((match = challengePattern.exec(content)) !== null) {
//       challenges.push({
//         title: match[1].trim(),
//       });
//     }
  
//     return challenges;
//   };

//   const challenges = extractChallenges(contentToCheck);

//   const decodeContent = (content) => {
//     return he.decode(content);
//   };

//   const handleEditClick = () => {
//     setOpenEditModal(true);
//   };

  // const handleSendChallenge = async (prompt) => {
  //   setWaitThread(true);
  //   setSending(true);
  //   try {
  //     const latestEntry = { sender: "user", content: prompt };
  //     setCurrentThread((prevThread) => [...prevThread, latestEntry]);

  //     const entryResponse = await sendMessage(latestEntry);
  //     if (entryResponse) {
  //       setCurrentThread((prevThread) => [...prevThread, entryResponse]);
  //     } else {
  //       setCurrentThread((prevThread) => [
  //         ...prevThread, 
  //         { content: "Unable to process your query!", sender: "modalX" }
  //       ]);
  //     }
  //   } catch (error) {
  //     console.error("Error sending initial message:", error);
  //   } finally {
  //     setWaitThread(false);
  //     setSending(false);
  //   }
  // };

//   return (
//     <div>
//       {(message.sender === "user" || message.user) && (
//         <Grid container spacing={2} sx={{ marginBottom: '20px', paddingRight: '70px', paddingLeft: '40px' }}>
//           <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#1F233E', borderRadius: '50%', width: '48px', height: '48px' }}>
//             <i className="bi bi-person fs-4" />
//           </Grid>
//           <Grid item xs={11} sx={{ margin: 'auto' }}>
//             {message.user && typeof message.user === 'string' && message.user.split("\n").map((line, index) => (
//               <div key={index} style={{ wordBreak: "break-word" }}>
//                 {decodeContent(line)}
//               </div>
//             ))}
//           </Grid>
//         </Grid>
//       )}

//       {contentToCheck && (
//         <Grid container spacing={2} justifyContent="flex-end">
//           <Grid item xs={11} sx={{ paddingLeft: '40px', marginBottom: '40px', paddingTop: '10px' }}>
//             <Grid container spacing={2}>
//               <Grid item xs={11} sx={{ margin: 'auto', textAlign: contentToCheck.length > 55 ? 'left' : 'right' }}>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12} sx={{ wordBreak: "break-word", marginBottom: '20px' }}>
//                     {firstSentence}
//                   </Grid>
//                   {challenges.map((challenge, index) => (
//                     <Grid item xs={12} key={index} sx={{ backgroundColor: '#1F233E', borderRadius: '5px', marginBottom: '10px', padding: '12px 24px',cursor: 'pointer',
//                       '&:hover': {
//                         backgroundColor: hovered_chat_bg_color_dm,
//                       }, }} 
//                       >
//                       <Typography variant="h6" fontSize={"16px"} sx={{ fontWeight: '400', color: 'rgba(255,255,255,0.8)' }}>
//                         {challenge.title}
//                       </Typography>
//                     </Grid>
//                   ))}
//                 </Grid>
//               </Grid>
//               <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                 <img src={theme === 'dark' ? black_logo : white_logo} alt="ModalX Icon" style={{ width: '100%' }} />
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//       )}
//     </div>
//   );
// };

// export default User_Challenges;
