import React, { useEffect, useState } from "react";
import { Box, Typography, CardMedia, Modal, Button } from "@mui/material";
import LensIcon from "@mui/icons-material/Lens";
import { button_bg_color_for_New_Project_dm } from "../../constants/colors";
import { useNavigate } from "react-router-dom";
import image1 from '../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image1.webp'
import image2  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image2.webp";
import image3  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image3.webp"
import image4  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image4.webp"
import image5  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image5.webp";
import video1 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video1.webp";
import video2 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video2.webp";
import video3 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video3.webp";
import video4 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video4.webp";
import video5 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video5.webp";
import ppt1 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation1.webp";
import ppt2 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation2.webp";
import ppt3 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation3.webp";
import ppt4 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation4.webp";
import ppt5 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation5.webp";
import audio1 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio1.webp";
import audio2  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio2.webp";
import audio3  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio3.webp";
import audio4  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio4.webp";
import audio5  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio5.webp";
import blog1 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog1.webp";
import blog2  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog2.webp";
import blog3  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog3.webp";
import blog4  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog4.webp";
import blog5  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog5.webp";
import translate1 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation1.webp";
import translate2  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation2.webp";
import translate3  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation3.webp";
import translate4  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation4.webp";
import translate5  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation5.webp";
import report1  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Report/report1.webp";
import report2  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Report/report2.webp";
import report3  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Report/report3.webp";
import report4  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Report/report1.webp";
import report5  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Report/report2.webp";
import web1  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/websearch/websearch1.webp";
import web2  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/websearch/websearch2.webp";
import web3  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/websearch/websearch3.webp";
import web4  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/websearch/websearch1.webp";
import web5  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/websearch/websearch2.webp";

const CommanHoverModal = ({
  open,
  handleLeave,
  modalPosition,
  hoveredCard,
  handleModalHover,
}) => {
  const [imageArray, setImageArray] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState(null);
  const navigate = useNavigate();

  const AllImageArray = [
    {
      id: 1,
      name: "Video",
      imageArray: [
        { id: 1, name: "image", url: video1 },
        { id: 2, name: "image", url: video2 },
        { id: 3, name: "image", url: video3 },
        { id: 4, name: "image", url: video4 },
        { id: 5, name: "image", url: video5 },
      ],
      title: "Video Generation",
      description: "Generate video at scale for diverse audiences.",
      url: "/videogallery",
    },
    {
      id: 2,
      name: "Speech",
      imageArray: [
        { id: 1, name: "image", url: audio1 },
        { id: 2, name: "image", url: audio2 },
        { id: 3, name: "image", url: audio3 },
        { id: 4, name: "image", url: audio4 },
        { id: 5, name: "image", url: audio5 },
      ],
      title: "Speech Generation",
      description:
        "Generate realistic speech in multiple languages seamlessly.",
      url: "/audiogallery",
    },
    {
      id: 3,
      name: "Blog",
      imageArray: [
        { id: 1, name: "image", url: blog1 },
        { id: 2, name: "image", url: blog2 },
        { id: 3, name: "image", url: blog3 },
        { id: 4, name: "image", url: blog4 },
        { id: 5, name: "image", url: blog5 },
      ],
      title: "Blog Generation",
      description: "Generate engaging blogs that deliver value",
      url: "/blogarticle",
    },
    {
      id: 4,
      name: "translation",
      imageArray: [
        { id: 1, name: "image", url: translate1 },
        { id: 2, name: "image", url: translate2 },
        { id: 3, name: "image", url: translate3 },
        { id: 4, name: "image", url: translate4 },
        { id: 5, name: "image", url: translate5 },
      ],
      title: "Translation",
      description:
        "Get accurate translations while keeping the original context",
      url: "/workspace",
    },
    {
      id: 5,
      name: "Image",
      imageArray: [
        { id: 1, name: "image", url: image1 },
        { id: 2, name: "image", url: image2 },
        { id: 3, name: "image", url: image3 },
        { id: 4, name: "image", url: image4 },
        { id: 5, name: "image", url: image5 },
      ],
      title: "Image Generation",
      description:
        "Generate copyright-free images that effectively communicate your ideas.",
      url: "/imagegallery",
    },
    {
      id: 6,
      name: "Presentation",
      imageArray: [
        { id: 1, name: "image", url: ppt1 },
        { id: 2, name: "image", url: ppt2 },
        { id: 3, name: "image", url: ppt3 },
        { id: 4, name: "image", url: ppt4 },
        { id: 5, name: "image", url: ppt5 },
      ],
      title: "Presentation Generation",
      description: "Generate impactful presentations that deliver value",
      url: "/presentationgallery",
    },
    { id: 7,title: "Find anything on the web", name:"Search", 
      imageArray: [
        { id: 1, name: "image", url: web1 },
        { id: 2, name: "image", url: web2 },
        { id: 3, name: "image", url: web3 },
        { id: 4, name: "image", url: web4 },
        { id: 5, name: "image", url: web5 },
      ],
      description:"Get any information you want from the web through genuine sources",
      url:"/workspace",
    },
    { id: 9, title: "Generate a report",name: "Report", 
      imageArray: [
        { id: 1, name: "image", url: report1 },
        { id: 2, name: "image", url: report2 },
        { id: 3, name: "image", url: report3 },
        { id: 4, name: "image", url: report4 },
        { id: 5, name: "image", url: report5 },
      ],
      description: "Generate engaging report that deliver value",
      url: "/workspace",
     },
  ]

  useEffect(() => {
    let interval;
  
    if (hoveredCard) {
      const matchedObject = AllImageArray.find(
        (item) => item.name === hoveredCard.name
      );
  
      if (matchedObject) {
        const matchedImageArray = matchedObject.imageArray;
  
        // Update imageArray and set the first image immediately
        setImageArray(matchedImageArray);
        if (matchedImageArray.length > 0) {
          setCurrentImage(matchedImageArray[0]?.url);
          setCurrentIndex(0);
        }
  
        // Start the interval for the slideshow if the modal is open
        if (open && matchedImageArray.length > 0) {
          interval = setInterval(() => {
            setCurrentIndex((prevIndex) => {
              const nextIndex = (prevIndex + 1) % matchedImageArray.length;
              setCurrentImage(matchedImageArray[nextIndex]?.url);
              return nextIndex;
            });
          }, 2000);
        }
      }
    }
  
    // Cleanup function to clear interval
    return () => {
      clearInterval(interval);
    };
  }, [hoveredCard, open]);


  const handleViewButtonClick = () => {
    if (hoveredCard) {
      window.scrollTo(0, 0);
      navigate(hoveredCard?.url);
    }
  };

  return (
    <Modal
      anchorEl={open}
      onMouseEnter={() => handleModalHover(true)}
      onMouseLeave={() => handleModalHover(false)}
      sx={{
        position: "absolute",
        zIndex: 100,
        maxWidth: "304px",
        maxHeight: "331px",
        top: modalPosition.top !== 0 && modalPosition.top,
        left: modalPosition.left !== 0 && modalPosition.left,
        right: modalPosition.right !== 0 && modalPosition.right,
        bottom: modalPosition.bottom !== 0 && modalPosition.bottom,
        transform: "translate(-50%, -50%)",
      }}
      open={open}
      onClose={handleLeave}
    >
      <Box
        sx={{
          width: "304px",
          height: "331px",
          padding: "12px 16px",
          bgcolor: "#1F233E",
          outline: "none",
          border: "none",
          borderRadius: "10px",
          // border:"1px solid red"
        }}
      >
        {hoveredCard && (
          <Box>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "32px",
                color: "rgba(246, 246, 247, 1)",
                marginBottom: "8px",
              }}
            >
              {hoveredCard?.title}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
                color: "rgba(255, 255, 255, 0.6)",
                marginBottom: "18px",
              }}
            >
              {hoveredCard?.description}
            </Typography>
            <CardMedia
              component="img"
              image={currentImage}
              alt={`Hovered Card ${hoveredCard.id}`}
              style={{ width: "100%", height: "153px", borderRadius: "4px" }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  width: "116px",
                  height: "36px",
                  padding: "12px",
                }}
              >
                {imageArray &&
                  imageArray?.map((item, idx) => {
                    return (
                      <LensIcon key={idx}
                        sx={{
                          color:
                            idx === currentIndex
                              ? "white"
                              : "rgba(74, 80, 118, 1)",
                          width: "12px",
                          height: "12px",
                        }}
                      />
                    );
                  })}
              </Box>
              <Box>
                <Button
                  onClick={handleViewButtonClick}
                  style={{
                    backgroundColor: button_bg_color_for_New_Project_dm,
                    color: "black",
                    fontWeight: "600",
                    border: "none",
                    //   marginTop :  isBelow991 ? '24px' : '',
                    //   width : isBelow991 ? '310px' : ''
                    textTransform: "none",
                  }}
                >
                 {hoveredCard?.url === "/workspace" ? "Try now" : "View gallery" }
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default CommanHoverModal;
