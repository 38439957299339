// import React,{useState,useEffect} from "react";
// import { Row } from "react-bootstrap";
// import MessageWindow from "../components/workspace/message/MessageWindow"; // Import the MessageWindow component
// import Sidebar from "../components/workspace/sideBar/Sidebar";
// import SettingBar from "../components/workspace/settingBar/SettingBar";
// import { Navigate, useNavigate } from "react-router-dom";
// import Messages from "../components/workspace/message/Messages";
// import { useWorkspace } from "../contexts/WorkspaceContext";
// import CreditRechargeModal from "../components/CreditRechargeModal";
// import VideoPreview from "../assets/ModalX_Video_Preview.mp4";
// import VideoModal from "../components/VideoModal";
// import * as Sentry from "@sentry/react";

// const Workspace = () => {
//   const isLoggedIn = localStorage.getItem('isLoggedIn');
//   const [showVideoModal, setShowVideoModal] = useState(false);
//   const navigate = useNavigate();
//   const {threadId,currentThread,currentHistoryId,showFirstVisitModal,setShowFirstVisitModal} = useWorkspace();
//   window.addEventListener('beforeunload', (event) => {
//     // Check the length of the current thread
//     if (currentThread.length > 0) {
//         // Perform necessary actions before refresh
//         // For example, saving the current thread to local storage or making an API call
//         localStorage.setItem('current_thread_id', threadId);
//         localStorage.setItem('current_history_id',currentHistoryId);
//         window.location.reload();
//     }
//     // else{
//     //     localStorage.setItem('current_thread_id','');
//     //     window.location.reload();
//     // }
// });

// useEffect(() => {
//   if (!localStorage.getItem('threadId')) {
//     setShowVideoModal(true);
//   }
// }, [])


  
//   return (
//     // isLoggedIn ?
//     // <Row
//     //   style={{
//     //     height: "100vh",
//     //     maxWidth: "100vw",
//     //   }}
//     //   className="m-0"
//     // >
//     //   <Sidebar />
//     //   <MessageWindow />
//     //   <SettingBar />
//     // </Row>
//     // :
//     // <Navigate to={"/home"} /> 
//     isLoggedIn? 
//     <div style={{display:'flex',width:'100vw',height:'100vh'}}>
//       <Sidebar />
//       <MessageWindow />
//       <Sentry.ErrorBoundary fallback={<div>Something went wrong in the workspace</div>}>
//         <SettingBar />
//       </Sentry.ErrorBoundary>
      
//       <CreditRechargeModal />
//       {showFirstVisitModal &&
//       <VideoModal 
//       show={showVideoModal} 
//       handleClose={() => setShowVideoModal(false)} 
//       videoSrc={VideoPreview} 
//     />
//       }
//     </div> :''
//   );
// };

// export default Workspace;

// ====================== above one is the latest code on 08/07/2022 ====================

// import React, { useState, useEffect } from "react";
// import { Row } from "react-bootstrap";
// import MessageWindow from "../components/workspace/message/MessageWindow";
// import Sidebar from "../components/workspace/sideBar/Sidebar";
// import SettingBar from "../components/workspace/settingBar/SettingBar";
// import { Navigate, useNavigate } from "react-router-dom";
// import { useWorkspace } from "../contexts/WorkspaceContext";
// import CreditRechargeModal from "../components/CreditRechargeModal";
// import VideoPreview from "../assets/ModalX_Video_Preview.mp4";
// import VideoModal from "../components/VideoModal";
// import * as Sentry from "@sentry/react";

// const Workspace = () => {
//   const isLoggedIn = localStorage.getItem('isLoggedIn');
//   const [showVideoModal, setShowVideoModal] = useState(false);
//   const navigate = useNavigate();
//   const { threadId, currentThread, currentHistoryId, showFirstVisitModal, setShowFirstVisitModal } = useWorkspace();

//   useEffect(() => {
//     if (!localStorage.getItem('threadId')) {
//       setShowVideoModal(true);
//     }

//     const handleBeforeUnload = (event) => {
//       if (currentThread.length > 0) {
//         localStorage.setItem('current_thread_id', threadId);
//         localStorage.setItem('current_history_id', currentHistoryId);
//       }
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [currentThread, threadId, currentHistoryId]);

//   return (
//     isLoggedIn ? 
//     <div style={{ display: 'flex', width: '100vw', height: '100vh'}}>
//       <Sidebar />
//       <MessageWindow />
//       <Sentry.ErrorBoundary fallback={<div>Something went wrong in the workspace</div>}>
//         <SettingBar />
//       </Sentry.ErrorBoundary>
//       <CreditRechargeModal />
//       {showFirstVisitModal &&
//         <VideoModal 
//           show={showVideoModal} 
//           handleClose={() => setShowVideoModal(false)} 
//           videoSrc={VideoPreview} 
//         />
//       }
//     </div> 
//     : 
//     <Navigate to={"/"} />
//   );
// };

// export default Workspace;

// ====================== above one is the latest code on 14/08/2022 ====================

// import React, { useState, useEffect } from "react";
// import MessageWindow from "../components/workspace/message/MessageWindow";
// import Sidebar from "../components/workspace/sideBar/Sidebar";
// import SettingBar from "../components/workspace/settingBar/SettingBar";
// import { Navigate } from "react-router-dom";
// import { useWorkspace } from "../contexts/WorkspaceContext";
// import CreditRechargeModal from "../components/CreditRechargeModal";
// import VideoPreview from "../assets/ModalX_Video_Preview.mp4";
// import VideoModal from "../components/VideoModal";
// import * as Sentry from "@sentry/react";

// const Workspace = () => {
//   const isLoggedIn = localStorage.getItem('isLoggedIn');
//   const [showVideoModal, setShowVideoModal] = useState(false);
//   const { threadId, currentThread, currentHistoryId, showFirstVisitModal, setShowFirstVisitModal } = useWorkspace();

//   useEffect(() => {
//     if (!localStorage.getItem('threadId')) {
//       setShowVideoModal(true);
//     }

//     const handleBeforeUnload = (event) => {
//       if (currentThread.length > 0) {
//         localStorage.setItem('current_thread_id', threadId);
//         localStorage.setItem('current_history_id', currentHistoryId);
//       }
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [currentThread, threadId, currentHistoryId]);

//   return (
//     isLoggedIn ? 
//     <div style={{ display: 'flex', width: '100vw', height: '100vh', overflow: 'hidden' }}>
//       <Sidebar />
//       <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
//         <MessageWindow />
//         <CreditRechargeModal />
//         {showFirstVisitModal && (
//           <VideoModal 
//             show={showVideoModal} 
//             handleClose={() => setShowVideoModal(false)} 
//             videoSrc={VideoPreview} 
//           />
//         )}
//       </div>
//       <Sentry.ErrorBoundary fallback={<div>Something went wrong in the workspace</div>}>
//         <SettingBar />
//       </Sentry.ErrorBoundary>
//     </div> 
//     : 
//     <Navigate to={"/"} />
//   );
// };

// export default Workspace;

// ====================== above one is the latest code on 27/08/2022 ====================


import React, { useState, useEffect, useRef } from "react";
import MessageWindow from "../components/workspace/message/MessageWindow";
import Sidebar from "../components/workspace/sideBar/Sidebar";
import SettingBar from "../components/workspace/settingBar/SettingBar";
import { Navigate, useNavigate } from "react-router-dom";
import { useWorkspace } from "../contexts/WorkspaceContext";
import CreditRechargeModal from "../components/CreditRechargeModal";
import VideoPreview from "../assets/ModalX_Video_Preview.mp4";
import VideoModal from "../components/VideoModal";
import axios from "axios";
import sourceUrl from "../api/Source.api";
import * as Sentry from "@sentry/react";
import { useAuth } from "../contexts/AuthContext";
import { Box, IconButton, Typography, Button, Tooltip, useMediaQuery } from "@mui/material";
import LogOut from "./LogOut";
import AllDelete from "./AllDelete";
import ShareModalForSocialMedia from "../components/workspace/message/ShareModalForSocialMedia";
import Default_WebTrackScreen from "../components/workspace/sideBar/Track_Website/Default_WebTrackScreen";
import WebTrackHome from "../components/workspace/sideBar/Track_Website/WebTrackHome";
import CommonToast from "../components/Common_Components/CommonToast";
import ImageSettings from "../components/workspace/settingBar/ImageSettings/ImageSettings";
import PptTemplates from "../components/workspace/settingBar/PptTemplates";
import AudioSettings from "../components/workspace/settingBar/AudioSettings/AudioSettings";
import VideoSettings from "../components/workspace/settingBar/VideoSettings";
import LimitExceedModal from "../components/LimitExceedModal";
import HelpButtons from "../components/workspace/settingBar/HelpButton/HelpButtons";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

const Workspace = () => {
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [aftervideomodal, setAftervideomodal] = useState(false);
  const [toggleButtonPosition, setToggleButtonPosition] = useState({ left: 0, top: 0 });
  const token = localStorage.getItem('token');

  const { 
    threadId, 
    currentThread, 
    currentHistoryId, 
    showFirstVisitModal, 
    setShowFirstVisitModal,
    Today, 
    Yesterday, 
    ThisWeek, 
    ThisMonth, 
    PrevMonth,
    setCurrentThread,
    sendMessage,
    setWaitThread,
    setSending,
    freshUser,
    setFreshUser,
    iswebTrackClicked,
    allVoiceforSettingShow, setAllVoiceforSettingShow
  } = useWorkspace();
    const helpButtonsRef = useRef(null);
    const toggleButtonRef = useRef(null);
    const [showHelpButton, setShowHelpButton] = useState(false);
    const isBelow991 = useMediaQuery("(max-width:991px)")

    const toggleHelpButton = () => {
      setShowHelpButton((prev) => !prev);
    };

      const handleClickOutside = (event) => {
    // Check if the click is outside both the toggle button and the modal
    if (
      toggleButtonRef.current &&
      helpButtonsRef.current &&
      !toggleButtonRef.current.contains(event.target) &&
      !helpButtonsRef.current.contains(event.target)
    ) {
      setShowHelpButton(false); // Close the modal if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  

  useEffect(() => {
    if(token){
      localStorage.removeItem('par_practice_token');
    }
  }, [])
  
  useEffect(()=>{
    const fetchVoices = async () => {
      try {
        const response = await fetch(`${sourceUrl}/audio/list`, {
          method: 'GET',
          credentials: "include",
          headers: {
            ...(isLoggedIn ? { Authorization: `Bearer ${token}` } : {}),
            'Content-Type': 'application/x-www-form-urlencoded',
          }
        });
        const data = await response.json();
        // console.log('Fetched voices:', data.data.data); // Log the response for debugging
        setAllVoiceforSettingShow(data.data.data);
        
      } catch (error) {
        console.error('Error fetching voices:', error);
      }
    };
    if(allVoiceforSettingShow === null){
      fetchVoices()
    }
  },[])
  

  useEffect(() => {
    if (!localStorage.getItem('threadId')) {
      setShowVideoModal(true);
    }

    const handleBeforeUnload = (event) => {
      if (currentThread.length > 0) {
        localStorage.setItem('current_thread_id', threadId);
        localStorage.setItem('current_history_id', currentHistoryId);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [currentThread, threadId, currentHistoryId]);

  const extractNameFromEmail = (email) => {
    const namePart = email.split('@')[0]; // Get the part before '@'
    const nameParts = namePart.split(/[._]/); // Split by '.' or '_'

    // Capitalize first letter of each part
    const capitalizedParts = nameParts.map(part => part.charAt(0).toUpperCase() + part.slice(1));

    // Join the parts with a space (if there's more than one part)
    return capitalizedParts.join(' ');
  };

  // useEffect(() => {
  //   const userEmail = localStorage.getItem("userEmail");
    
  //   if (
  //     !showFirstVisitModal && 
  //     !Today.length && 
  //     !Yesterday.length && 
  //     !ThisWeek.length && 
  //     !ThisMonth.length && 
  //     !PrevMonth.length &&
  //     userEmail
  //   ) {
  //     const userName = extractNameFromEmail(userEmail);
  //     const firstTimeVisit = localStorage.getItem("hasVisitedWorkspace");
  //     const token = localStorage.getItem('token');
  //     const prompt = `Extract the company name and role of the person from deepak@pariksha.co and find details about the company.`;
  //     // const prompt = `Hi ${extractNameFromEmail(userEmail)}, welcome to ModalX!. Please give me a moment while I set things up for you.`
  //     const response = axios.get(`${sourceUrl}/user/onboardingStatus`, {
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //         'Content-Type': 'application/json',
  //         'User-Agent': 'Mozilla/5.0',
  //       },
  //     });

  //     const { status } = response.data?.data || {};

  //     // Update modal and hasVisitedWorkspace based on status
  //     if (!status && !firstTimeVisit) {
  //       sendInitialMessage(prompt); // If status is true, show video settings modal
  //     }
  //     // if(!firstTimeVisit){
  //     //   sendInitialMessage(prompt);
  //     // }
  //   }
  // }, [showFirstVisitModal]); 
    const fetchOnboardingStatus = async () => {
      try {
        const userEmail = localStorage.getItem("userEmail");
  
        if (
          !showFirstVisitModal && 
          !Today.length && 
          !Yesterday.length && 
          !ThisWeek.length && 
          !ThisMonth.length && 
          !PrevMonth.length &&
          userEmail
        ) {
          const userName = extractNameFromEmail(userEmail);
          const firstTimeVisit = localStorage.getItem("hasVisitedWorkspace");
          const token = localStorage.getItem('token');
          // const prompt = `Extract the company name and role of the person from deepak@pariksha.io and find details about the company.`;
          const prompt = `Hi ${extractNameFromEmail(userEmail)}, welcome to ModalX!. Please give me a moment while I set things up for you.`;
          // const prompt = `Write a response that: 1. Extracts the company name and person’s name from the email address vishnurajkarockal@gmail.com. 2. Provides a brief summary of the company found through a web search, including it’s main activities, location, and any notable achievements or news. 3. Searches for information about the person associated with the email address, including their professional background, roles, and accomplishments. Please provide the extracted company and person names, followed by a web search summary article that includes the requested information.`
  
          // Make the API call asynchronous
          const response = await axios.get(`${sourceUrl}/user/onboardingStatus`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              // 'User-Agent': 'Mozilla/5.0',
            },
          });
  
          // Safely access the response data using optional chaining
          const { status } = response.data?.data || {};
  
          // Now invoke sendInitialMessage only if status is false and it's the user's first visit
          if (!status) {
            await sendInitialMessage(prompt);  // Ensure sendInitialMessage is awaited
          }
        }
      } catch (error) {
        console.error("Error fetching onboarding status:", error);
      }
    };

  const sendInitialMessage = async (prompt) => {
    setWaitThread(true);
    setSending(true);
    try {
      const latestEntry = { sender: "user", content: prompt };
      setCurrentThread((prevThread) => [...prevThread, latestEntry]);

      const entryResponse = await sendMessage(latestEntry);
      if (entryResponse) {
        setCurrentThread((prevThread) => [...prevThread, entryResponse]);
      } else {
        setCurrentThread((prevThread) => [
          ...prevThread, 
          { content: "Unable to process your query!", sender: "modalX" }
        ]);
      }
    } catch (error) {
      console.error("Error sending initial message:", error);
    } finally {
      setWaitThread(false);
      setSending(false);
    }
  };

  if(freshUser === 'loading'){
    return <div style={{height:"100vh", width:"100vw"}}></div>
  }

  return (
    // isLoggedIn ? 
    <div style={{ display: 'flex', width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <Sidebar />
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        {/* {
          iswebTrackClicked?
          <Default_WebTrackScreen /> :
          <MessageWindow />
        } */}
        {/* {
          mode === "track" ?
          <WebTrackHome /> :
          <MessageWindow />
        } */}
         <MessageWindow />
        <CreditRechargeModal />
        <LimitExceedModal/>
        <AllDelete/>
        <LogOut/>
        <CommonToast />
          <ImageSettings />
          <PptTemplates />
          <AudioSettings />
          <VideoSettings />
        {/* <ShareModalForSocialMedia/> */}
        {freshUser && currentThread?.length === 0 && !localStorage.getItem('par_practice_token') && (
          <VideoModal 
            show={freshUser} 
            handleClose={() =>{ setFreshUser(false)
                                fetchOnboardingStatus();
            }} 
            videoSrc={VideoPreview} 
          />
        )}
      </div>
        {/* Fixed Button */}
       {/* <Box
        ref={helpButtonsRef}
        sx={{
          display: showHelpButton ? "block" : "none",
          right:0,
          marginRight:"3%",
          position: "fixed",
          zIndex: 1100,
          bottom: {
            xs: isBelow991 ? "4%" : "7%", // Extra-small screens (0px to 600px)
            sm: isBelow991 ? "4%" : "7%", // Small screens (600px to 960px)
            md: isBelow991 ? "4%" : "7%", // Medium screens (960px to 1280px)
            lg: isBelow991 ? "4%" : "7%", // Large screens (1280px to 1920px)
            xl: isBelow991 ? "4%" : "7%", // Extra-large screens (1920px and up)
          },
        }}
      >
        <HelpButtons />
      </Box>
  <Tooltip
    title={showHelpButton ? null : "Help"}
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: "#E6E0E9",
          color: "#17182C",
          fontFamily: "Inter",
          fontSize: "12px",
          fontWeight: 500,
          padding: "4px 8px",
          borderRadius: "4px",
          maxWidth: "104px",
          textAlign: "left",
        },
      },
    }}
  >
    <Box
      ref={toggleButtonRef}
      onClick={toggleHelpButton}
      sx={{
        position: 'fixed',
        bottom: {
          xs: isBelow991 ? "8px"  :'12px', // Adjusted for small screens
          sm: isBelow991 ? "8px"  :'12px',
          md: isBelow991 ? "8px"  :'12px',
          lg: isBelow991 ? "8px"  :'12px',
          xl: isBelow991 ? "8px"  :'12px',
        },
        right: '12px', // Positioned away from the right edge
        zIndex: 1100, // High z-index to ensure visibility
        width: isBelow991 ? '24px' : "96px",
        height: isBelow991 ? '24px' : "34px",
        border: '1px solid rgba(255, 255, 255, 0.1)',
        borderRadius: isBelow991 ? '50%' : "100px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        gap:"2px",
        bgcolor: showHelpButton ? "#25294C" :"transparent", 
        padding:isBelow991 ? "0px 0px 3px 0px" : "0px 0px 0px 3px",
        '&:hover':{
          bgcolor: showHelpButton ? "#25294C" : "#1F233E",
        }
      }}
    >
      <Typography sx={{display: isBelow991 ? "none":"flex", flexShrink:0 ,fontFamily:"Inter", fontSize:"12px", fontStyle:"normal", fontWeight:400, lineHeight:"normal", letterSpacing:"0.5px", color:"#CAC4D0"}}>
       Need help
      </Typography>
      <Box>
      <QuestionMarkIcon  sx={{ width:"14px", height:"16px", paddingBottom: !isBelow991 && "2px" ,color:"rgba(202, 196, 208, 1)"}}/>
      </Box>
    </Box>
    </Tooltip> */}
      <Sentry.ErrorBoundary fallback={<div>Something went wrong in the workspace</div>}>
        <SettingBar />
      </Sentry.ErrorBoundary>
    </div>
  );
};

export default Workspace;




// import { useEffect } from "react";
// import { useAuth } from "../contexts/AuthContext";
// import { useNavigate } from "react-router-dom";

// const navigate = useNavigate();
// const { user } = useAuth();
// console.log(user);
// console.log(user.firstName);

// useEffect(() => {
//   if (user && !user.firstName) {
//     navigate("/userJourney");
//   }
// }, [user, navigate]);
