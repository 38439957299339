import React, { useState, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import { useUI } from "../../../contexts/UIContext";
import Uploader from "./FileUploader";
import LinkUploader from "./LinkUploader";
import Services from "./Services";
import PptTemplates from "./PptTemplates";
import ImageSettings from "./ImageSettings/ImageSettings";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import * as Sentry from "@sentry/react";
import {
  darkTheme_bg_color,
  fileUploader_bg_color_for_dm,
  uploadFiles_button_default_bg_color,
  uploadFiles_button_hovered_bg_color,
} from "../../../constants/colors";
import { useMediaQuery, Box, Tooltip, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import HelpButtons from "./HelpButton/HelpButtons";
import ViewThreadFiles from "./ViewThreadFiles";
import { InvokeMatrixApi } from "../../../contexts/WorkspaceUtils";
import { FileUpload, trackFileUpload } from "../../../config/analytics";
import sourceUrl from "../../../api/Source.api";

const SettingContent = () => {
  const { currentTheme } = useUI();
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const email = localStorage.getItem("userEmail");
  const token = localStorage.getItem("token");
   const {
      currentThread,
      threadFiles,
      setShowSettingBar,
      setShowRightsideBar,
      showSettingBar,
      setThreadFiles,
      sendMessage,
      setShowImageSettings,
      setCurrentThread,
      setWaitThread,
      setSending,
      historyFiles,
      showVideoGenSettings,
      setShowVideoSettings,
      showVideoSettings,
      showImageSettings,
      showPptSettings,
      showAudioSettings,
      setShowPptSettings,
      setShowAudioSettings,
      waitThread,
      setShowVideoGenSettings,
      setGenVideoLanguage,
      setGenVideoAudioSettingsLoading,
      setGenVideoVoice,
      setGenVideoVoicesLoading,
      setCaptionFontForVideoGen,
      genVideoLanguage,
      genVideoVoice,
      selectedVideoGenerationPlatform,
      upload_FilesOnly,
      threadId,
    } = useWorkspace();
  const [currThreadLen, setCurrThreadLen] = useState(0);
  const isBelow1500 = useMediaQuery("(max-width:1500px)");
  const mobileView = useMediaQuery("(max-width:992px)");
  // const [showHelpButton, setShowHelpButton] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progressStatusChecking, setProgressStatusChecking] = useState({});
  const [notificationToastMessage, setNotificationToastMessage] = useState("");
  const [fileUploaded, setFileUploaded] = useState(false);
  // const dropdownRef = useRef(null);
  // const toggleButtonRef = useRef(null);
  // const helpButtonsRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setCurrThreadLen(currentThread?.length);
  }, [currentThread]);

  const getSettingBarStyle = (theme) => {
    return theme === "dark"
      ? { backgroundColor: darkTheme_bg_color, color: "white" }
      : { backgroundColor: "var(--bs-gray-300)", color: "black" };
  };

  const toggleSettingBar = () => {
    setShowSettingBar(!showSettingBar);
    setShowRightsideBar(!showSettingBar);
  };

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  // console.log("2222222222222222222222222222222222222222", showHelpButton);
  // const toggleHelpButton = () =>{
  //   setShowHelpButton(!showHelpButton)
  // }

  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setShowHelpButton(false); // Close the dropdown if clicked outside
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  // const handleFileChange = async (event) => {
  //   try {
  //     setWaitThread(true);
  //     const files = Array.from(event.target.files); // Convert FileList to Array
  //     const existingFileNames = threadFiles.map((file) => file.name);

  //     // Filter out files that already exist in threadFiles by their names
  //     const newFiles = files.filter(
  //       (file) => !existingFileNames.includes(file.name)
  //     );

  //     // Clear the file input after selecting files
  //     event.target.value = "";

  //     // Map files to upload promises
  //     const uploadPromises = newFiles.map(async (file) => {
  //       setLoading(true); // Show loader

  //       // Map each file to the prescribed JSON format and mark as "uploading"
  //       const fileToUpdate = {
  //         type: "file",
  //         file: file,
  //         name: file.name,
  //         status: "uploading", // Mark as "uploading" initially
  //         fileId: null,
  //         url: null, // Add a placeholder for the URL
  //       };

  //       // Update threadFiles with the new file object
  //       setThreadFiles((prevThreadFiles) => [...prevThreadFiles, fileToUpdate]);

  //       // Upload the file and return fileId
  //       const response = await upload_FilesOnly(fileToUpdate);
  //       const fileId = response.data.id;
  //       const url = response.data.url;

  //       // console.log('here is the url that sent before check kkkkkkkkkkkkkkkkkkkkkkkkk',response)
  //       // After uploading file event triggred for file uploaded

  //       const fileType = files[0].type;
  //       const fileCount = threadFiles.length + 1;
  //       const file_method = "Click to Upload";
  //       trackFileUpload("file_upload", fileType, fileCount, file_method);
  //       FileUpload(fileType, fileCount);

  //       // Update the file object with uploaded state or upload failed
  //       setThreadFiles((prevThreadFiles) =>
  //         prevThreadFiles.map((prevFile) =>
  //           prevFile.name === file.name
  //             ? {
  //                 ...prevFile,
  //                 status: response.data.status, // Update status
  //                 fileId: fileId, // Update fileId
  //                 url: url, // Update URL
  //               }
  //             : prevFile
  //         )
  //       );

  //       // Start checking progress status
  //       setProgressStatusChecking((prev) => ({ ...prev, [url]: true }));

  //       // Poll progress status every 3 seconds
  //       const interval = setInterval(async () => {
  //         // const progressStatus = await checkUrlStatus(url);
  //         const status_resp = await fetch(`${sourceUrl}/status`, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //           body: JSON.stringify({ threadId, url }),
  //         });
  //         const responseData = await status_resp.json();
  //         if (
  //           status_resp.status === 200 &&
  //           responseData.data.status === "completed"
  //         ) {
  //           // ==========================Invoke matrix api ===================================================
  //           InvokeMatrixApi(
  //             responseData,
  //             status_resp,
  //             "POST",
  //             "status",
  //             { threadId, url },
  //             email
  //           );
  //           // ===============================================================================================
  //           setThreadFiles((prevThreadFiles) =>
  //             prevThreadFiles.map((prevFile) =>
  //               prevFile.name === file.name
  //                 ? {
  //                     ...prevFile,
  //                     status: "completed", // Update status
  //                     fileId: fileId, // Update fileId
  //                     url: url, // Update URL
  //                   }
  //                 : prevFile
  //             )
  //           );

  //           setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
  //           clearInterval(interval); // Stop interval
  //           setFileUploaded(true);
  //           setNotificationToastMessage("File uploaded successfully");
  //         } else {
  //           if (
  //             status_resp.status === 200 &&
  //             responseData.data.status === "failed"
  //           ) {
  //             setThreadFiles((prevThreadFiles) =>
  //               prevThreadFiles.map((prevFile) =>
  //                 prevFile.name === file.name
  //                   ? {
  //                       ...prevFile,
  //                       status: "failed",
  //                       fileId: "", // Update fileId
  //                       url: url, // Update URL
  //                     }
  //                   : prevFile
  //               )
  //             );
  //             // ==========================Invoke matrix api ===================================================
  //             InvokeMatrixApi(
  //               responseData,
  //               status_resp,
  //               "POST",
  //               "status",
  //               { threadId, url },
  //               email
  //             );
  //             // ===============================================================================================
  //             setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
  //             clearInterval(interval); // Stop interval
  //           } else if (status_resp.status === 500) {
  //             setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
  //             clearInterval(interval); // Stop interval
  //           }
  //           // setFileUploaded(true);
  //           // setNotificationToastMessage('There was an error while uploading the file. Please try again')
  //         }
  //       }, 3000);

  //       // Return updated file object with fileId
  //       return fileId
  //         ? { ...fileToUpdate, status: "uploaded", fileId: fileId }
  //         : { ...fileToUpdate, status: "failed" };
  //     });

  //     // Wait for all uploads to finish
  //     await Promise.all(uploadPromises);

  //     setLoading(false); // Hide loader
  //     setWaitThread(false);
  //     // triggredFileUploadEvent(event)
  //   } catch (error) {
  //     Sentry.captureException(error);
  //     console.error("Error while handling file change:", error);
  //     setFileUploaded(true);
  //     setNotificationToastMessage(
  //       "There was an error while uploading the file. Please try again"
  //     );
  //   }
  // };

  // const toggleHelpButton = () => {
  //   setShowHelpButton((prev) => !prev);
  // };

  // const handleClickOutside = (event) => {
  //   // Check if the click is outside both the toggle button and the modal
  //   if (
  //     toggleButtonRef.current &&
  //     helpButtonsRef.current &&
  //     !toggleButtonRef.current.contains(event.target) &&
  //     !helpButtonsRef.current.contains(event.target)
  //   ) {
  //     setShowHelpButton(false); // Close the modal if clicked outside
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleFileChange({ target: { files } });
    }
  };

  const handleFileChange = async (event) => {
    try {
      setWaitThread(true);
      const files = Array.from(event.target.files); // Convert FileList to Array
      const existingFileNames = threadFiles.map((file) => file.name);

      // Filter out files that already exist in threadFiles by their names
      const newFiles = files.filter(
        (file) => !existingFileNames.includes(file.name)
      );

      // Clear the file input after selecting files
      event.target.value = "";

      // Map files to upload promises
      const uploadPromises = newFiles.map(async (file) => {
        setLoading(true); // Show loader

        // Map each file to the prescribed JSON format and mark as "uploading"
        const fileToUpdate = {
          type: "file",
          file: file,
          name: file.name,
          status: "uploading", // Mark as "uploading" initially
          fileId: null,
          url: null, // Add a placeholder for the URL
        };

        // Update threadFiles with the new file object
        setThreadFiles((prevThreadFiles) => [...prevThreadFiles, fileToUpdate]);

        // Upload the file and return fileId
        const response = await upload_FilesOnly(fileToUpdate);
        const fileId = response.data.id;
        const url = response.data.url;

        // console.log('here is the url that sent before check kkkkkkkkkkkkkkkkkkkkkkkkk',response)
        // After uploading file event triggred for file uploaded

        const fileType = files[0].type;
        const fileCount = threadFiles.length + 1;
        const file_method = "Click to Upload";
        trackFileUpload ("file_upload", fileType, fileCount, file_method);
        FileUpload(fileType, fileCount);

        // Update the file object with uploaded state or upload failed
        setThreadFiles((prevThreadFiles) =>
          prevThreadFiles.map((prevFile) =>
            prevFile.name === file.name
              ? {
                  ...prevFile,
                  status: response.data.status, // Update status
                  fileId: fileId, // Update fileId
                  url: url, // Update URL
                }
              : prevFile
          )
        );

        // Start checking progress status
        setProgressStatusChecking((prev) => ({ ...prev, [url]: true }));

        // Poll progress status every 3 seconds
        const interval = setInterval(async () => {
          // const progressStatus = await checkUrlStatus(url);
          const status_resp = await fetch(`${sourceUrl}/status`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ threadId, url }),
          });
          const responseData = await status_resp.json();
          if (
            status_resp.status === 200 &&
            responseData.data.status === "completed"
          ) {
            // ==========================Invoke matrix api ===================================================
            InvokeMatrixApi(
              responseData,
              status_resp,
              "POST",
              "status",
              { threadId, url },
              email
            );
            // ===============================================================================================
            setThreadFiles((prevThreadFiles) =>
              prevThreadFiles.map((prevFile) =>
                prevFile.name === file.name
                  ? {
                      ...prevFile,
                      status: "completed", // Update status
                      fileId: fileId, // Update fileId
                      url: url, // Update URL
                    }
                  : prevFile
              )
            );

            setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
            clearInterval(interval); // Stop interval
            setFileUploaded(true);
            setNotificationToastMessage("File uploaded successfully");
          } else {
            if (
              status_resp.status === 200 &&
              responseData.data.status === "failed"
            ) {
              setThreadFiles((prevThreadFiles) =>
                prevThreadFiles.map((prevFile) =>
                  prevFile.name === file.name
                    ? {
                        ...prevFile,
                        status: "failed",
                        fileId: "", // Update fileId
                        url: url, // Update URL
                      }
                    : prevFile
                )
              );
              // ==========================Invoke matrix api ===================================================
              InvokeMatrixApi(
                responseData,
                status_resp,
                "POST",
                "status",
                { threadId, url },
                email
              );
              // ===============================================================================================
              setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
              clearInterval(interval); // Stop interval
            } else if (status_resp.status === 500) {
              setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
              clearInterval(interval); // Stop interval
            }
            // setFileUploaded(true);
            // setNotificationToastMessage('There was an error while uploading the file. Please try again')
          }
        }, 3000);

        // Return updated file object with fileId
        return fileId
          ? { ...fileToUpdate, status: "uploaded", fileId: fileId }
          : { ...fileToUpdate, status: "failed" };
      });

      // Wait for all uploads to finish
      await Promise.all(uploadPromises);

      setLoading(false); // Hide loader
      setWaitThread(false);
      // triggredFileUploadEvent(event)
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error while handling file change:", error);
      setFileUploaded(true);
      setNotificationToastMessage(
        "There was an error while uploading the file. Please try again"
      );
    }
  };

  return (
    <div
      style={{
        width: "282px",
        // height: '100vh',
        height: "100%",
        overflowY: "auto",
        display: "inline-block",
        ...getSettingBarStyle(currentTheme),
        // padding: '20px'
        backgroundColor:"#151729",
      }}
    >
      <Col className="py-3">
        <div
          onClick={toggleSettingBar}
          style={{
            display: mobileView ? "block" : "none",
            width: "24px",
            height: "24px",
            marginLeft: "225px",
          }}
        >
          {" "}
          <CloseIcon />
        </div>
        <Sentry.ErrorBoundary
          fallback={<div>Something went wrong in the workspace</div>}
        >
          {/* <div
            style={{
              // margin : '20px',
              margin: mobileView ? "20px 0px 20px 10px" : "20px",
              height: mobileView ? "" : "281px",
              width: "242px",
              border: mobileView ? "" : "2px dotted",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // backgroundColor:theme === 'dark'?fileUploader_bg_color_for_dm:'',
              backgroundColor: mobileView ? "" : fileUploader_bg_color_for_dm,
            }}
          >
            <Uploader />
          </div> */}
          <Box
                sx={{
                  display: "flex",
                  justifyContent:"center",
                  alignSelf:"center",
                  maxWidth:"258px",
                  margin:"0px auto auto auto",
                  backgroundColor:""
                }}
              >
                <Box sx={{ display:"flex", flexDirection:"column", justifyContent:"center" }}>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      letterSpacing: "0.1px",
                      textAlign: "left",
                      color: "#ffffff",
                      textTransform: "none",
                    }}
                  >
                    Upload Files
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginTop: "20px",
                      width: "258px",
                      borderRadius: "8px",
                      bgcolor: isDragging ? "#2C2D4B" : "#1F233E", // Change color on drag
                      outline: isDragging ? "1px solid #FFF" : "",
                      padding: "20px",
                      rowGap:"24px",
                    }}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                     <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: 600,
                          lineHeight: "normal",
                          letterSpacing: "0.1px",
                          textAlign: "left",
                          color: "#ffffff",
                          textTransform: "none",
                        }}
                      >
                        Select files or drop to upload 
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "normal",
                          letterSpacing: "0.1px",
                          textAlign: "left",
                          color: "#ffffff",
                          opacity: "0.8",
                          // textTransform: "none",
                        }}
                      >
                        JPEG, PNG, PDF, XLSX, MOV, MP3
                      </Typography>
                    </Box>
                    <Box>
                    <Button
                      onClick={handleFileSelect}
                      variant="contained"
                      sx={{
                        backgroundColor:
                          theme === "dark"
                            ? uploadFiles_button_default_bg_color
                            : "",
                        color: "black",
                        width: "100%",
                        height: "40px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0px",
                        "&:hover": {
                          backgroundColor: uploadFiles_button_hovered_bg_color,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          height: "40px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: 600,
                            lineHeight: "20px",
                            letterSpacing: "0.1",
                            textAlign: "center",
                            color: "#17182C",
                            gap: "8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textTransform: "none",
                            padding: "10px 0px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="18"
                            viewBox="0 0 19 18"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_7004_2614)">
                              <path
                                d="M15.0125 7.53C14.7606 6.25329 14.0732 5.10364 13.0678 4.27743C12.0624 3.45122 10.8013 2.99971 9.5 3C7.3325 3 5.45 4.23 4.5125 6.03C3.41018 6.14912 2.39075 6.67141 1.65012 7.49649C0.909478 8.32158 0.499871 9.39126 0.5 10.5C0.5 12.9825 2.5175 15 5 15H14.75C16.82 15 18.5 13.32 18.5 11.25C18.5 9.27 16.9625 7.665 15.0125 7.53ZM14.75 13.5H5C3.3425 13.5 2 12.1575 2 10.5C2 8.9625 3.1475 7.68 4.67 7.5225L5.4725 7.44L5.8475 6.7275C6.19248 6.05614 6.71598 5.49296 7.36041 5.09996C8.00483 4.70695 8.74519 4.49935 9.5 4.5C11.465 4.5 13.16 5.895 13.5425 7.8225L13.7675 8.9475L14.915 9.03C15.4788 9.06792 16.0073 9.31799 16.3942 9.72989C16.781 10.1418 16.9975 10.6849 17 11.25C17 12.4875 15.9875 13.5 14.75 13.5ZM6.5 9.75H8.4125V12H10.5875V9.75H12.5L9.5 6.75L6.5 9.75Z"
                                fill="black"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_7004_2614">
                                <rect
                                  width="18"
                                  height="18"
                                  fill="white"
                                  transform="translate(0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <Box sx={{ margin: "0px", padding: "0px" }}>
                            Browse files
                          </Box>
                        </Typography>
                      </Box>
                    </Button>
                    <input
                      id="file-upload"
                      type="file"
                      accept=".txt, .docx, .mp3, .pdf, .mp4, .xlsx, video/*, .jpg, .png, .jpeg"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      multiple
                    />
                    </Box>
                   
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "8px",
                      alignItems: "baseline",
                      marginTop: "12px",
                      width: "258px",
                    }}
                  >
                    <Box>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M7.33334 4.66634H8.66668V5.99967H7.33334V4.66634ZM7.33334 7.33301H8.66668V11.333H7.33334V7.33301ZM8.00001 1.33301C4.32001 1.33301 1.33334 4.31967 1.33334 7.99967C1.33334 11.6797 4.32001 14.6663 8.00001 14.6663C11.68 14.6663 14.6667 11.6797 14.6667 7.99967C14.6667 4.31967 11.68 1.33301 8.00001 1.33301ZM8.00001 13.333C5.06001 13.333 2.66668 10.9397 2.66668 7.99967C2.66668 5.05967 5.06001 2.66634 8.00001 2.66634C10.94 2.66634 13.3333 5.05967 13.3333 7.99967C13.3333 10.9397 10.94 13.333 8.00001 13.333Z"
                          fill="#CAC4D0"
                        />
                      </svg>
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: 400,
                        fontStyle: "normal",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        textAlign: "left",
                        color: "rgba(255, 255, 255, 0.8)",
                        textTransform: "none",
                      }}
                    >
                      ModalX will use uploaded files as additional context for your project
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ border: "", width: "100%" }}>
                  {/* <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      letterSpacing: "0.1px",
                      textAlign: "left",
                      color: "#ffffff",
                      textTransform: "none",
                      marginLeft: "20px",
                      marginBottom: "16px",
                    }}
                  >
                    {`Your Files (${filesLen})`}
                  </Typography>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        textAlign: "left",
                        color: "#CAC4D0",
                        textTransform: "none",
                        marginTop: "16px",
                        marginLeft: "20px",
                      }}
                    >
                      {
                      // filesLen == 0
                      true ? (
                        "You haven’t uploaded any file yet."
                      ) : (
                        <Box
                          sx={
                            {
                              // height: "115px",
                              // overflowY: "auto",
                            }
                          }
                        >
                          <ViewThreadFilesInOneClickGenerateModal
                            showAllUplodedFiles={showAllUplodedFiles}
                            setShowAllUplodedFiles={setShowAllUplodedFiles}
                          />
                        </Box>
                      )}
                    </Typography>
                  </Box> */}
                </Box>
              </Box>
        </Sentry.ErrorBoundary>

        {/* <div className="mb-3" style={{}} /> */}
        {/* <div 
        style={{
            margin : mobileView ? '20px 5px 20px 10px' : '20px',
            border: '2px dotted',
            borderRadius: '10px',
            backgroundColor:theme === 'dark'?fileUploader_bg_color_for_dm:''
        }}>
          <LinkUploader />
        </div> */}
        {/* <hr style={{ marginTop: "28px" }}></hr> */}
        <Box>
          <ViewThreadFiles />
        </Box>

        {/* <hr style={{ margin: "10px 0", borderTop: "2px solid black" }} /> */}
        {/* <ViewThreadFiles />
        <hr style={{ margin: "10px 0", borderTop: "2px solid black" }} />
        <SynthesiaSettings />
        <hr style={{ margin: "10px 0", borderTop: "2px solid black" }} />
        <ImageSettings />
        <PptTemplates />
        {currThreadLen > 0 &&
        <div style={{ marginTop: '50px' }}>
          <Services />
        </div>
        } */}
      </Col>
      {/* <Box 
      ref={dropdownRef}
      sx={ {
        display : showHelpButton ? 'block' : "none",
        marginLeft : '20px',
        marginTop : '-15px'
      }}>
      <HelpButtons/>
      </Box>     
      <Box 
      onClick={toggleHelpButton}
      sx={{
        // marginTop : showHelpButton ? "" : '240px',
        marginTop : showHelpButton ? "35.3%" : '80%',
        marginLeft : '224px',
        width : '36px',
        height : '36px',
        border : '2px solid rgba(255, 255, 255, 0.1)',
        borderRadius : '50%',
        display : "flex",
        justifyContent : "center",
        alignItems : 'center',
        cursor : 'pointer',
        paddingTop:'-36px'
      }}><QuestionMarkIcon/>

      </Box> */}
  {/*below code for help button now  its move to parant component WorkSpace*/}
      {/* <Box
        ref={helpButtonsRef}
        sx={{
          display: showHelpButton ? "block" : "none",
          marginLeft: "20px",
          // marginTop: '-15px',
          position: "fixed",
          bottom: {
            xs: "7%", // Extra-small screens (0px to 600px)
            sm: "7%", // Small screens (600px to 960px)
            md: "7%", // Medium screens (960px to 1280px)
            lg: "7%", // Large screens (1280px to 1920px)
            xl: "7%", // Extra-large screens (1920px and up)
          },
        }}
      >
        <HelpButtons />
      </Box>
      <Tooltip
        title={showHelpButton ? null : "Help"}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#E6E0E9",
              color: "#17182C",
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 500,
              padding: "4px 8px 4px 8px",
              borderRadius: "4px",
              maxWidth: "104px",
              textAlign: "left",
            },
          },
        }}
      >
        <Box
          ref={toggleButtonRef}
          onClick={toggleHelpButton}
          sx={{
            // marginTop: showHelpButton ? '35.3%' : '80%',
            marginLeft: "224px",
            position: "fixed",
            bottom: {
              xs: "2%", // Extra-small screens (0px to 600px)
              sm: "2%", // Small screens (600px to 960px)
              md: "2%", // Medium screens (960px to 1280px)
              lg: "2%", // Large screens (1280px to 1920px)
              xl: "2%", // Extra-large screens (1920px and up)
            },

            width: "36px",
            height: "36px",
            border: "2px solid rgba(255, 255, 255, 0.1)",
            width: "36px",
            height: "36px",
            border: "2px solid rgba(255, 255, 255, 0.1)",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <QuestionMarkIcon />
        </Box>
      </Tooltip> */}
    </div>
  );
};

export default SettingContent;

// import React from "react";
// import { Col } from "react-bootstrap";
// import { useUI } from "../../../contexts/UIContext";
// import Uploader from "./FileUploader";
// import LinkUploader from "./LinkUploader";
// import SynthesiaSettings from "./synthesiaSettings/SynthesiaSettings";
// import Services from "./Services";
// import ViewThreadFiles from "./ViewThreadFiles";

// const SettingContent = () => {
//   const { currentTheme } = useUI();

//   const getSettingBarStyle = (theme) => {
//     return theme === "dark"
//       ? { backgroundColor: "var(--bs-gray-dark)", color: "white" }
//       : { backgroundColor: "var(--bs-gray-300)", color: "black" };
//   };

//   return (
//     <Col
//       style={{
//         maxWidth: "300px",
//         minWidth: "300px",
//         overflowY: "auto",
//         height:'100vh',
//         display: "flex",
//         flexDirection: "column",
//         ...getSettingBarStyle(currentTheme),
//         padding:'20px'
//       }}
//       className="py-3"
//     >
//       <Uploader />
//       <div className="mb-3" />
//       <LinkUploader />
//       <hr style={{ margin: "10px 0", borderTop: "2px solid black" }} />
//       <ViewThreadFiles />
//       <hr style={{ margin: "10px 0", borderTop: "2px solid black" }} />
//       <SynthesiaSettings />
//       <div style={{marginTop:'50px'}}>
//         <Services />
//       </div>
//     </Col>
//   );
// };

// export default SettingContent;
