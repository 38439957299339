import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import sourceUrl from "../../api/Source.api";

const InviteUser = () => {
  const [email, setEmail] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastClass, setToastClass] = useState('');
  const [emailError, setEmailError] = useState('');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (email?.length >= 1 && !validateEmail(email)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  }, [email]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const token = localStorage.getItem('token');
  
  const handleInvite = async () => {
    if (!validateEmail(email)) {
      setToastMessage('Invalid email address');
      setToastClass('bg-danger text-white');
      setShowToast(true);
      return;
    }

    try {
      const res = await axios.post(`${sourceUrl}/admin/invite`, {
        email,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      setToastMessage('Invite sent successfully!');
      setToastClass('bg-success text-white');
      setEmail('');
      fetchAllUsers(); // Refresh the user list after sending the invite
    } catch (error) {
      setToastMessage(error.message);
      setToastClass('bg-danger text-white');
    } finally {
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    }
  };

  const fetchAllUsers = async () => {
    try {
      const res = await axios.get(`${sourceUrl}/admin/invite`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (res.data) {
        setUsers(res.data.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  return (
    <div className="container my-4" style={{ maxHeight: '70vh', overflow: 'hidden' }}>
      {/* <h2>Invite User</h2> */}
      <div className="mb-3">
        <label htmlFor="email" className="form-label">Email address</label>
        <input
          type="email"
          className="form-control"
          id="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter email"
          style={{ maxWidth: '400px' }}
        />
        {emailError && <div className="alert alert-danger mt-2">{emailError}</div>}
      </div>
      <button className="btn btn-primary" onClick={handleInvite} disabled={!!emailError}>Invite</button>

      <hr></hr>

      <div className="card-body">
        <div className="d-flex justify-content-between mb-3">
          <h5 className="card-title">Total Invites:</h5>
          <h5 className="card-title text-primary">{users?.length}</h5>
        </div>
        <div className="overflow-auto" style={{ maxHeight: '400px' }}>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : (
            <ul className="list-group">
              {users.slice().reverse().map((ele, i) => (
                <li key={i} className="list-group-item">
                  {ele}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {showToast && (
        <div className={`toast show position-absolute bottom-0 start-50 translate-middle-x p-3 ${toastClass}`} style={{ zIndex: 11 }}>
          <div className="d-flex">
            <div className="toast-body">
              {toastMessage}
            </div>
            <button type="button" className="btn-close btn-close-white me-2 m-auto" onClick={() => setShowToast(false)}></button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InviteUser;
