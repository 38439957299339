// import React, { useEffect, useState } from "react";
// import { Button, Modal, Form, Image } from "react-bootstrap";
// import Theme1Preview from "../../../assets/ppt_template_1.png";
// import Theme2Preview from "../../../assets/ppt_template_2.png";
// import Theme3Preview from "../../../assets/ppt_template_3.png";
// import Theme4Preview from "../../../assets/ppt_template_4.png";
// import Theme5Preview from "../../../assets/ppt_template_5.png";
// import Theme6Preview from "../../../assets/ppt_template_6.png";
// import { useWorkspace } from "../../../contexts/WorkspaceContext";

// const PptTemplates = () => {
//   const {templateId,setTemplateId,showPptSettings} = useWorkspace();
//   const [showModal, setShowModal] = useState(false);
//   const [selectedTheme, setSelectedTheme] = useState("theme2");
//   const theme = localStorage.getItem('theme');

//   useEffect(() => {
//     if(showPptSettings){
//       setShowModal(showPptSettings);
//     }
//   }, [showPptSettings])
  
//   useEffect(() => {
//     // if(selectedTheme === 'theme1'){
//     //   setTemplateId(1);
//     // }
//     if(selectedTheme === 'theme2'){
//       setTemplateId(2);
//     }
//     else if(selectedTheme === 'theme3'){
//       setTemplateId(3);
//     }
//     else if(selectedTheme === 'theme4'){
//       setTemplateId(4);
//     }
//     else if(selectedTheme === 'theme5'){
//       setTemplateId(5);
//     }
//     else if(selectedTheme === 'theme6'){
//       setTemplateId(6);
//     }
//   }, [selectedTheme])
  

//   const handleThemeChange = (event) => {
//     setSelectedTheme(event.target.value);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//   };

//   const handleOpenModal = () => {
//     setShowModal(true);
//   };

//   const handleSaveTemplate = () => {
//     // Add logic to save selected theme
//     // For now, just console log the selected theme
//     console.log("Selected theme:", selectedTheme);
//     setShowModal(false);
//   };

//   return (
//     <>
//       <Button variant="primary" onClick={handleOpenModal} style={{backgroundColor:theme ==='dark'?'#343a40':'#dee2e6',color:theme === 'dark'?'white':'black',margin:'20px 0'}}>
//         PPT Templates
//       </Button>

//       <Modal show={showModal} onHide={handleCloseModal} dialogClassName="modal-90w" >
//         <Modal.Header closeButton>
//           <Modal.Title>Select PPT Template</Modal.Title>
//         </Modal.Header>
//         <Modal.Body style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto",backgroundColor:theme ==='light'?'#dee2e6':'',marginLeft:'20px' }}>
//           <div style={{ display: "flex", justifyContent: "center",flexDirection:'column' }}>
//             {/* <div style={{ marginRight: "20px"}}>
//               <Image src={Theme1Preview} fluid style={{ width: "100%", }} />
//               <Form style={{padding:'20px 0'}}>
//                 <Form.Check
//                   type="radio"
//                   label="Theme 1"
//                   name="theme"
//                   id="theme1"
//                   value="theme1"
//                   checked={selectedTheme === "theme1"}
//                   onChange={handleThemeChange}
//                 />
//               </Form>
//             </div> */}
//             <div style={{ marginRight: "20px"}}>
//               <Image src={Theme2Preview} fluid style={{ width: "100%"}} />
//               <Form style={{padding:'20px 0'}}>
//                 <Form.Check
//                   type="radio"
//                   label="Theme 1"
//                   name="theme"
//                   id="theme2"
//                   value="theme2"
//                   checked={selectedTheme === "theme2"}
//                   onChange={handleThemeChange}
//                 />
//               </Form>
//             </div>
//             <div style={{ marginRight: "20px"}}>
//               <Image src={Theme3Preview} fluid style={{ width: "100%"}} />
//               <Form style={{padding:'20px 0'}}>
//                 <Form.Check
//                   type="radio"
//                   label="Theme 2"
//                   name="theme"
//                   id="theme3"
//                   value="theme3"
//                   checked={selectedTheme === "theme3"}
//                   onChange={handleThemeChange}
//                 />
//               </Form>
//             </div>
//             <div style={{ marginRight: "20px"}}>
//               <Image src={Theme4Preview} fluid style={{ width: "100%"}} />
//               <Form style={{padding:'20px 0'}}>
//                 <Form.Check
//                   type="radio"
//                   label="Theme 3"
//                   name="theme"
//                   id="theme4"
//                   value="theme4"
//                   checked={selectedTheme === "theme4"}
//                   onChange={handleThemeChange}
//                 />
//               </Form>
//             </div>
//             <div style={{ marginRight: "20px"}}>
//               <Image src={Theme5Preview} fluid style={{ width: "100%"}} />
//               <Form style={{padding:'20px 0'}}>
//                 <Form.Check
//                   type="radio"
//                   label="Theme 4"
//                   name="theme"
//                   id="theme5"
//                   value="theme5"
//                   checked={selectedTheme === "theme5"}
//                   onChange={handleThemeChange}
//                 />
//               </Form>
//             </div>
//             <div style={{ marginRight: "20px"}}>
//               <Image src={Theme6Preview} fluid style={{ width: "100%"}} />
//               <Form style={{padding:'20px 0'}}>
//                 <Form.Check
//                   type="radio"
//                   label="Theme 5"
//                   name="theme"
//                   id="theme6"
//                   value="theme6"
//                   checked={selectedTheme === "theme6"}
//                   onChange={handleThemeChange}
//                 />
//               </Form>
//             </div>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handleSaveTemplate}>
//             Save
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default PptTemplates;


import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Typography, Box } from "@mui/material";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import Theme2Preview from "../../../assets/ppt_template_2.png";
import Theme3Preview from "../../../assets/ppt_template_3.png";
import Theme4Preview from "../../../assets/ppt_template_4.png";
import Theme5Preview from "../../../assets/ppt_template_5.png";
import Theme6Preview from "../../../assets/ppt_template_6.png";
import ppt_template_image1 from "../../../assets/ppt_template_image1.png";
import ppt_template_image2 from "../../../assets/ppt_template_image2.png";
import ppt_template_image3 from "../../../assets/ppt_template_image3.png";
import ppt_template_image4 from "../../../assets/ppt_template_image4.png";
import CloseIcon from '@mui/icons-material/Close';
import { button_bg_color_for_New_Project, settingsModal_bg_color_dm, uploadFiles_button_default_bg_color, uploadFiles_button_hovered_bg_color } from "../../../constants/colors";
import CommonToast from "../../Common_Components/CommonToast";
import sourceUrl from "../../../api/Source.api";
import { trackSearchInteraction } from "../../../config/analytics";
import { useNavigate } from "react-router-dom";
import { containsMediaFile } from "../../../contexts/WorkspaceUtils";

const PptTemplates = () => {
  const { setTemplateId,
     showPptSettings, 
     setShowPptSettings,
     setCurrentThread,
     currentThread,
     setSending,
     setWaitThread,
     setThreadFiles,
     setHistoryFiles,
     selectedOneClickButtonFromWebtrackNotificationMsg,
     setSelectedOneClickButtonFromWebtrackNotificationMsg,
     notificationTextFromWebTracking,
     setNotificationTextFromWebTracking,
     clearThread,
     setThreadId,
     setIswebTrackClicked,
     sendMessage,
     setCurrentWebsiteLinkedId,
     stopMessageIdRef, setVisibleStopGenerationButton,
     userMediaSettings,
     } = useWorkspace();
  const [showModal, setShowModal] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState("theme2");
  const theme = localStorage.getItem('theme');
  const [pptSettingsSaved,setPptSettingsSaved] = useState(false);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (showPptSettings) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [showPptSettings]);

  useEffect(() => {
    switch (selectedTheme) {
      case 'theme2':
        setTemplateId(2);
        break;
      case 'theme3':
        setTemplateId(3);
        break;
      case 'theme4':
        setTemplateId(4);
        break;
      case 'theme5':
        setTemplateId(5);
        break;
      case 'theme6':
        setTemplateId(6);
        break;
      default:
        break;
    }
  }, [selectedTheme, setTemplateId]);

  const createThreadId = async () => {
    try {
      const response = await fetch(`${sourceUrl}/threads`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });
      const responseData = await response.json();
      if (responseData.status) {
        const newThreadId = responseData.data.match(/(.*)/)[1];
        setThreadId(newThreadId);
        localStorage.setItem('threadId', newThreadId);
        return newThreadId;
      } else {
        throw new Error("Thread creation failed");
      }
    } catch (error) {
      console.error("Thread creation error:", error);
      return null;
    }
  };

  const handleSubmit = async (input) => {
    setIswebTrackClicked(false);
    trackSearchInteraction(input);
    setCurrentWebsiteLinkedId(null);
    clearThread();
    setThreadFiles([]);
    setHistoryFiles([]);
    localStorage.removeItem("current_thread_id");
    localStorage.removeItem("current_history_id");
    const newThreadId = await createThreadId();
    setThreadId(newThreadId);
    setVisibleStopGenerationButton(true)
    setSending(true);
    setWaitThread(true);
    if (input?.trim()) {
      const latestEntry = { sender: "user", content: input };
      // setCurrentThread((prevThread) => {
      //   const updatedThread = prevThread[prevThread.length - 1]?.sender === "user"
      //     ? prevThread.slice(0, -1) // Remove the last entry if it's from the user
      //     : prevThread;
      //   return [...updatedThread, latestEntry];
      // })
      if(currentThread[currentThread.length - 1]?.sender !== "user"){
        setCurrentThread((prevThread) => [...prevThread, latestEntry]);
      }
      
      const entryResponse = await sendMessage(latestEntry).catch(console.error);
      // if(findUrl(entryResponse.content)){
      //   setHasLink(true)
      //   await extractLinks(entryResponse.content)
      //   }
      if (entryResponse) {
        const url = findUrl(entryResponse.content);
        if (url && url.includes("dailylimitexceeded")) {
          const fileFormat = getFileFormat(url);
          const responseMessage = {
            content: `Hi there,\n\nIt looks like you’ve reached your daily limit for generating `+ fileFormat +` files...`,
            sender: 'modalX',
          };
          setCurrentThread((prevThread) => [...prevThread, responseMessage]);
        }else if(stopMessageIdRef.current.includes(entryResponse.unique_id)){
          setCurrentThread((prevThread)=>[...prevThread ])
          setVisibleStopGenerationButton(false);
        }
         else {
          // setCurrentThread((prevThread) => [...prevThread, entryResponse]);
          if(containsMediaFile(entryResponse.content)){
            setCurrentThread((prevThread) => [...prevThread, entryResponse]);
          }else{
            setCurrentThread((prevThread) => [...prevThread, entryResponse]);
            setVisibleStopGenerationButton(false);
          }
        }
        setSending(false);
      } else {
        setCurrentThread((prevThread) => [...prevThread, { content: "Unable to process your query!..", sender: 'modalX' }]);
        setSending(false);
        setVisibleStopGenerationButton(false);
      }
    }
  
    setWaitThread(false);
  };

  const findUrl = (msg) => {
    const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
    const match = urlRegex.exec(msg);
    return match ? match[1] : null;
  };

  const getFileFormat = (url) => {
    // Extract the file extension from the URL
    const parts = url.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1]; // Return the last part as the file format
    } else {
      return ''; // Return empty string if no file extension found
    }
  };

  const handleThemeSelect = (theme) => {
    setSelectedTheme(theme);
  };

  const handleClose = () => {
    setShowModal(false);
    setShowPptSettings(false);
  };

  const handleCloseGenerate = () => {
    setShowModal(false);
    setShowPptSettings(false);
    setSelectedOneClickButtonFromWebtrackNotificationMsg('');
    setNotificationTextFromWebTracking('');
  }

  useEffect(()=>{
    if(userMediaSettings){
      setTemplateId(userMediaSettings[3]?.settings?.templateId)
    }
  },[userMediaSettings])

  const handleSave = () => {
    // Implement save functionality here
    console.log('Settings saved');
    setPptSettingsSaved(true);
    setShowPptSettings(false);
  };

  const handleSaveAndGenerate = () => {
    setIswebTrackClicked(false);
    setCurrentWebsiteLinkedId(null);
    navigate("/workspace");
    setPptSettingsSaved(true);
    setShowPptSettings(false);
    handleSubmit(notificationTextFromWebTracking);
    setSelectedOneClickButtonFromWebtrackNotificationMsg('');
    setNotificationTextFromWebTracking('');
  }

  const handleCloseToast = () => {
    setPptSettingsSaved(false);
  };

  const handleSaveTemplate = () => {
    console.log("Selected theme:", selectedTheme);
    setShowModal(false);
  };

  const themes = [
    { src: ppt_template_image1, label: "Theme 1", value: "theme2", title1 : 'Minimal Pitch Deck Theme' },
    { src: ppt_template_image2, label: "Theme 2", value: "theme3",title1 : 'Photography Portfolio Theme' },
    { src: ppt_template_image3, label: "Theme 3", value: "theme4",title1 : 'All-hands Presentation Theme' },
    { src: ppt_template_image4, label: "Theme 4", value: "theme5",title1 : 'Brand Launch Presentation Theme' },
    // { src: Theme6Preview, label: "Theme 5", value: "theme6" }
  ];

  return (
    <>
      <CommonToast 
        open={pptSettingsSaved}
        onClose={handleCloseToast}
        content="PPT settings saved"
        bgColor="#282D51"
        fontColor="#fff"
        fontSize="14px"
        fontWeight="500"
        autoHideDuration={3000}
        iconType = 'success'
      />
      <Dialog
        open={showModal}
        onClose={selectedOneClickButtonFromWebtrackNotificationMsg === ''?handleClose:handleCloseGenerate}
        maxWidth="lg" // increase maxWidth
        PaperProps={{
          style: {
            width: '720px', // Fixed width
            height: '636px', // Fixed height
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: theme === 'dark' ? settingsModal_bg_color_dm : '#fff',
            borderRadius: '10px',
          },
        }}
      >
        <DialogTitle
          style={{
            fontSize: '20px',
            fontWeight: '600',
            color: theme === 'dark' ? '#FFFFFF' : '',
            position: 'relative',
            display : "flex",
            justifyContent : 'space-between',
            alignContent : 'center'
            
          }}
        >
          Select PPT Template
          <span
            style={{
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '1px',
              backgroundColor: '#FFFFFF',
              opacity: 0.2, // Set the opacity for the border
            }}
          ></span>
          <Box
           onClick={selectedOneClickButtonFromWebtrackNotificationMsg === ''?handleClose:handleCloseGenerate}
           sx={{
            cursor : "pointer"
           }}

          ><CloseIcon/>

          </Box>
        </DialogTitle>

        <DialogContent dividers sx={{ maxHeight: "calc(100vh - 200px)", overflowY: "scroll", backgroundColor: theme === 'light' ? '#dee2e6' : '', p: 2,
        '&::-webkit-scrollbar': {
          width : '10px',
          // height : '60px'
        },
        '&::-webkit-scrollbar-thumb': {
          bgcolor: 'rgba(255, 255, 255, 0.2)',
          borderRadius : '5px',
          height : '60px'
          
        }, 

        }}>
          <FormControl component="fieldset">
            <div 
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)', // Two columns
              gap: '28px', // Space between items
            }}
            
            >

            {themes.map((theme, index) => (
              <div key={index}>
              <div
                
                onClick={() => handleThemeSelect(theme.value)}
                style={{
                  marginBottom: '12px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  border: selectedTheme === theme.value ? '3px solid orange' : '3px solid transparent',
                  borderRadius: '10px',
                  padding: '5px',
                  
                }}
              >
                <img src={theme.src} alt={theme.label} style={{ width: '100%', borderRadius: '7px' }} />
                
                
                {/* <Typography variant="body1" sx={{ color:'#FFFFFF', marginTop: '10px' }}>
                  {theme.label}
                </Typography> */}
              </div>
              <div style={{textAlign : "center"}}>
                <Typography sx={{
                  fontFamily : 'Inter',
                  fontSize : '16px',
                  fontWeight : 400,
                  color : '#FFFFFF',
                  marginBottom :  '-12px'
                }}>{theme.title1}</Typography>
              </div>
              

              </div>

              
            ))}

            </div>

          </FormControl>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              mt: 2,
              pt: 2,
              position: 'relative',
              width: '100%',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '1px',
                backgroundColor: theme === 'dark' ? '#FFFFFF' : '#ccc',
                opacity: 0.2, // Set the opacity for the border
              },
            }}
          >
            <Button
              onClick={selectedOneClickButtonFromWebtrackNotificationMsg === ''?handleClose:handleCloseGenerate}
              sx={{
                mr: 1,
                backgroundColor: theme === 'dark' ? uploadFiles_button_default_bg_color : '',
                '&:hover': {
                  backgroundColor: uploadFiles_button_hovered_bg_color,
                },
                color: '#17182C',
                fontSize: '16px',
                fontWeight: '600',
                textTransform: 'none'
              }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              onClick={selectedOneClickButtonFromWebtrackNotificationMsg === ''?handleSave:handleSaveAndGenerate}
              sx={{
                backgroundColor: theme === 'dark' ? button_bg_color_for_New_Project : '',
                '&:hover': {
                  backgroundColor: '#FFD9AA',
                },
                color: '#17182C',
                fontSize: '16px',
                fontWeight: '600',
                textTransform: 'none'
              }}
            >
              {selectedOneClickButtonFromWebtrackNotificationMsg === ''?'Save':'Save and generate ppt'}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PptTemplates;




