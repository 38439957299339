import React, { useState } from "react";
import { Box, Button, CardMedia, IconButton, Typography, useMediaQuery } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  New_Project_Button_bg_color,
  SelectedChat_bg_color,
} from "../../Provider/Color_Provider";
import Linkidin_Image from "../../assets/LandingPage_Items/Testimonials/linkedin.png";
import Avatar_Image from "../../assets/LandingPage_Items/Testimonials/Avatar.png";
import {
  button_bg_color_for_New_Project,
  button_bg_color_for_New_Project_dm,
  button_bg_color_for_New_Project_lm,
  darkTheme_bg_color,
} from "../../constants/colors";
import Comp_Logo from "../../assets/LandingPage_Items/CaseStudies/Logo.png";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import UpArrowIcon from "../../assets/LandingPage_Items/CaseStudies/UpArrow_Icon.png";

const CaseStudies = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalBoxes = 6; // Total number of boxes in the carousel
  const visibleBoxes = 3; // Number of boxes visible at once
  const boxWidth = 386; // Width of each box in pixels (including margin)
  const isBelow991 = useMediaQuery("(max-width:991px)");

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < totalBoxes - visibleBoxes) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const features = [
    {
      id: "1",
      company: "Content Generation",
      percentage: "5X",
      type: "Sales Increase",
      description:
        "ModalX's X-to-X tools enhance engagement with styled social media posts and videos, driving a 68% rise in website traffic and a 5X sales increase",
    },
    {
      id: "2",
      company: "Research",
      percentage: "14",
      type: "Hours Saved Weekly",
      description:
        "ModalX's Tracking feature delivers real-time web insights, saving users 14 hours weekly on research by efficiently converting information into content",
    },
    {
      id: "3",
      company: "Social Media Automation",
      percentage: "4X",
      type: "Boost in Productivity",
      description:
        "ModalX's real-time web search and tracking feature boosts productivity 4X by streamlining information access, content creation, and platform publishing with hashtags and descriptions",
    },
    {
      id: "4",
      company: "Increase lead generation",
      percentage: "3X",
      type: "Business Leads",
      description:
        "Promptly distributing content as news breaks gives businesses a competitive edge. This boosts lead generation through social media, increasing business leads by 3X",
    },
  ];

  return (
    <Box
      style={{
        paddingTop: isBelow991?'40px':"80px",
        paddingBottom: "100px",
        // background: isBelow991?'':`linear-gradient(to bottom, #1e223d, ${darkTheme_bg_color})`,
        background: isBelow991?'':`linear-gradient(147deg, #1F233E 16.27%, #171A2D 75.09%)`,
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          fontFamily: "Inter",
          fontSize: isBelow991?'12px':"16px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "24px",
          color: "#F6BE6B",
        }}
      >
        Case Studies
      </Typography>

      <Box
        sx={{
          width: "100%",
          // height: "58px",
          // gap: "28px",
          opacity: "0px",
          marginTop: "48px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: isBelow991?'24px':"48px",
            fontWeight: 500,
            // lineHeight: "57.6px",
            letterSpacing: "-0.32",
            textAlign: "center",
            color: "#FFFFFF",
          }}
        >
          Real Results for Real Businesses
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={4}
        sx={{ marginTop: isBelow991?'40px':"120px",marginLeft:isBelow991?'16px':'30px',marginRight:isBelow991?'30px':'' }}
      >
        <Box
          overflow="auto"
          width={`${visibleBoxes * boxWidth + 162}px`}
          display="flex"
          position="relative"
          sx={{
            scrollBehavior: 'smooth', 
            '&::-webkit-scrollbar': {
              display: 'none', 
            },
            '-ms-overflow-style': 'none', 
            'scrollbar-width': 'none', 
          }}
        >
          <Box
            display="flex"
            transition="transform 0.5s ease-in-out" // Changed the transition to ease-in-out
            sx={{
              transform: `translateX(-${currentIndex * boxWidth}px)`,
              transitionProperty: "transform",
              transitionDuration: "0.5s",
              transitionTimingFunction: "ease-in-out", // Smooth transition
              columnGap:isBelow991 ? '70px': "81px",
            }}
          >
            {features.map((feature) => (
              <Box
                key={feature.id}
                sx={{
                  maxWidth: "386px",
                  // height: "348px",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  bgcolor: "#1F233E",
                  "&:hover": {
                    backgroundColor: "#282D51CC",
                    cursor: "pointer",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-evenly", // Ensures equal spacing between children
                    // height: '100%', // Adjust to the desired height
                    textAlign: "center",
                  }}
                >
                  {/* <div
                            style={{
                            width : '100%',
                            height: '24px',
                            opacity : '0px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center', // Vertically center the contents
                            // marginBottom: '16px', // Adjust margin as needed
                            backgroundColor : 'red',
                            marginTop : '34px'
                            }}
                        >
                            <div style={{
                                width : '24px',
                                height : '24px',
                                backgroundColor : 'white',
                                display :'flex',
                                justifyContent : 'center',
                                alignItems : 'center',
                               
                            }}>
                            <img
                            src={Comp_Logo}
                            alt="Company Logo"

                            
                            style={{ height: '100%' }} // Ensures the image is properly scaled within the div height
                            />
                                
                            </div>


                            <p
                            style={{
                                // fontSize: '16px',
                                // margin: '0 0 0 8px', // Remove default margin and add left margin for spacing
                                fontFamily : 'Inter',
                                fontSize : '16px',
                                fontWeight : 500,
                                lineHeight : '12px',
                                textAlign : 'center'
                            }}
                            >
                            {feature.company}
                            </p>
                        </div> */}
                  <Box
                    sx={{
                      maxWidth: "80%",
                      height: "24px",
                      opacity: "0px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center", // Vertically center the contents
                      // marginBottom: '16px', // Adjust margin as needed
                      marginTop: "34px",
                      
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "250px",
                        height: "24px",
                        opacity: "0px",
                        gap: "6px",
                        display: "flex",
                        // alignItems: "center",

                      }}
                    >
                      <Box
                        sx={{
                          width: "24px",
                          height: "24px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: "8px",
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={Comp_Logo}
                          alt="Company Logo"
                        />
                      </Box>

                      <Box
                        sx={{
                          maxWidth: "232px",
                          height: "24px",
                          display: "flex",
                          alignContent: "center",

                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontWeight: 500,
                            fontSize: "16px",
                            textAlign: "left",
                            color: "#FFFFFFCC",
                            opacity: "80%",
                          }}
                        >
                          {feature.company}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  {/* <div
                            style={{
                             width : '140px',   
                            height: '68px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            // color: button_bg_color_for_New_Project,
                            marginBottom: '16px', // Adjust margin as needed
                            backgroundColor : 'red'
                            }}
                        >
                            <div
                            style={{
                                width: '123px',
                                height: '40px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: 'auto',
                            }}
                            >
                            <img
                                src={UpArrowIcon}
                                alt="Up Arrow"
                                style={{ width: '17.44px' }}
                            />
                            <p
                                style={{
                                color: button_bg_color_for_New_Project,
                                fontSize: '40px', // Adjusted font size for better alignment
                                fontWeight: '600',
                                margin: '0', // Remove default margin for better centering
                                lineHeight: '40px', // Same as container height for vertical alignment
                                }}
                            >
                                {feature.percentage}%
                            </p>
                            </div>
                            <p>{feature.type}</p>
                        </div> */}

                  <Box
                    sx={{
                      width: "165px",
                      height: "68px",
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        width: "90px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginLeft: "9px",
                        margin:'auto'
                      }}
                    >
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          marginRight:'20px'
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={UpArrowIcon}
                          alt="Up Arrow"
                          sx={{
                            width: "15.44px",
                            height: "12.5",
                            transform: feature.id == 2 ? "rotate(180deg)" : "none",
                            color: "#F6BE6B",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "89px",
                          height: "40px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "40px",
                            fontWeight: 700,
                            lineHeight: "40px",
                            textAlign: "left",
                            color: "#F6BE6B",
                          }}
                        >
                          {feature.percentage}
                          <Typography
                            sx={{
                              fontFamily: "Inter",
                              fontSize: "40px",
                              fontWeight: 700,
                              lineHeight: "40px",
                              textAlign: "left",
                              color: "rgba(246, 190, 107, 0.6)",
                            }}
                            component="span"
                          >
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        width: "100%",
                        height: "16px",
                        marginTop: "12px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: 600,
                          lineHeight: "16px",
                          textAlign: "center",
                          color: "rgba(246, 190, 107, 0.8)",
                          
                        }}
                      >
                        {feature.type}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: "318px",
                      minHeight: "68px",
                      gap: "0px",
                      opacity: "0px",
                      marginTop: "25px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "16.8px",
                        textAlign: "center",
                        color: "#FFFFFFCC",
                        opacity: "80%",
                        padding : '0px 10px 0px 10px'
                        // bgcolor : 'red'
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "182px",
                      height: "40px",
                      marginTop: "35px",
                      gap: "0px",
                      opacity: "0px",
                      marginBottom: "34px",
                    }}
                  >
                    <Button
                      sx={{
                        textTransform: "none",
                        width: "182px",
                        height: "40px",
                        fontFamily: "Inter",
                        lineHeight: "20px",
                        textAlign: "center",
                        letterSpacing: "0.1px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: button_bg_color_for_New_Project_dm,
                        border: `1px solid ${button_bg_color_for_New_Project_dm}`,
                      }}
                    >
                      {/* button_bg_color_for_New_Project_lm */}
                      Read Case Study
                    </Button>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          maxWidth: "112px",
          height: "48px",
          display: "flex",
          justifyContent: "space-between",
          margin: "48px auto",
        }}
      >
        <IconButton
          onClick={handlePrevClick}
          disabled={currentIndex === 0}
          disableRipple
          sx={{
            bgcolor: "#1F233E",
            color: "white",
            "&:hover": {
              backgroundColor: "#1B1E36",
            },
            "&:disabled": {
              bgcolor: "#1F233E",
              color: "grey",
            },
            width: "48px",
            height: "49px",
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          onClick={handleNextClick}
          disabled={currentIndex >= totalBoxes - visibleBoxes}
          disableRipple
          sx={{
            bgcolor: "#1F233E",
            color: "white",
            "&:hover": {
              backgroundColor: "#1B1E36",
            },
            "&:disabled": {
              bgcolor: "#1F233E",
              color: "grey",
            },
            width: "48px",
            height: "49px",
          }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CaseStudies;
