import React, { useState } from "react";
import {
  Dialog,
  Typography,
  Box,
  Button,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCreditRecharge } from "../contexts/CreditRechageContext";
import sourceUrl from "../api/Source.api";
import { SubscribeToProClick, trackButtonEvent } from "../config/analytics";
import Icon from "../assets/FiletypeLogos.png";
import { useWorkspace } from "../contexts/WorkspaceContext";
import { useDailyLimitExceed } from "../contexts/DailyLimitExceedContext";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const LimitExceedModal = () => {
  const {dailyLimitExceed, setDailylimitExceed } = useDailyLimitExceed()
  const { setShowSignUpModal } = useAuth()
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const handleClose = () => setDailylimitExceed(false);
  const navigate = useNavigate();


  return (
    <>
      <Dialog
        open={dailyLimitExceed}
        close={handleClose}
        PaperProps={{
          sx: {
            width: isBelow991 ? "350px" :  "480px",
            gap: "0px",
            borderRadius: "8px",
            opacity: "0px",
            bgcolor: "#1F233E",
            color: "#ffffff",
          },
        }}
      >
        {/* Section 1 */}
        <Box
          sx={{
            borderRadius: "8px",
            backgroundColor: "#1F233E",
            padding: "20px 20px 0px 0px",
          }}
        >
          <Box
            sx={{
              justifyContent: "space-between",
              borderTop: "8px",
              margin: "0px",
              padding: "0px",
            }}
          >
            <Box
              sx={{
                width: "full",
                height: "28px",
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "279px",
                  height: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "20px",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                ></Typography>
              </Box>

              <Box sx={{ margin: "0px", padding: "0px" }}>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    color: "rgba(255, 255, 255, 1)",
                    padding: "0px",
                  }}
                >
                  <CloseIcon
                    sx={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ padding: isBelow991 ? "0px 20px 40px 20px" : "0px 40px 40px 40px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "0px",
              padding: "0px",
            }}
          >
            <img
              src={Icon}
              alt="loading"
              style={{ width: "60px", height: "60px" }}
            />
          </Box>

          <Box sx={{ marginTop: "20px" }}>
            <Typography
              sx={{
                width:isBelow991 ? "310px" :  "400px",
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "22.4px",
                color: "rgba(255, 255, 255)",
                textAlign: "center",
              }}
            >
              Credit limit reached
            </Typography>
          </Box>

          {/* Section 2 */}
          <Box
            sx={{
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <Typography
              sx={{
                width:isBelow991 ? "310px" :  "400px",
                fontFamily: "Inter",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "normal",
                color: "rgba(255, 255, 255, 0.80)",
                textAlign: "center",
              }}
            >
              You have reached the daily credit limit for video, image,
              presentation, and audio generation.
            </Typography>
          </Box>

          {/* Section 3 */}
          <Box
            sx={{
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                width:isBelow991 ? "310px" :  "400px",
                fontFamily: "Inter",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "normal",
                color: "rgba(255, 255, 255, 0.80)",
                textAlign: "center",
              }}
            >
              You can still continue your chat conversation.
            </Typography>
          </Box>

          {/* Section 4 */}
          <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "rgba(246, 190, 107, 1)",
              color: "black",
              width: isBelow991 ? "310px" : "400px",
              marginTop: isBelow991 ? "20px" : "40px",
              marginLeft: "0px",
              padding: "10px 24px 10px 24px",
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "20px",
              letterSpacing: "0.1px",
              textAlign: "center",
              textTransform: "none",
              borderRadius:"8px",
              "&:hover": {
                bgcolor: "#ffd9aa",
              },
            }}
            onClick={()=>{setShowSignUpModal(true); setDailylimitExceed(false); navigate("/");}}
          >
            Sign up for free
          </Button>

          {/* Section 4 */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1F233E",
              color: "rgba(246, 190, 107, 1)",
              width: isBelow991 ? "310px" : "400px",
              marginTop: "20px",
              marginLeft: "0px",
              padding: "10px 24px 10px 24px",
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "20px",
              letterSpacing: "0.1px",
              textAlign: "center",
              textTransform: "none",
              borderRadius:"8px",
              border:"1px solid rgba(246, 190, 107, 1)",
              "&:hover": {
                bgcolor: "rgba(40, 45, 81, 1)",
              },
            }}
            onClick={handleClose}
          >
            Continue chat for free
          </Button></Box>
        </Box>
      </Dialog>
    </>
  );
};

export default LimitExceedModal;
