import React, { createContext, useContext, useState,useEffect, useRef } from "react";
import { sendMessage, uploadFiles, checkUrlStatus, InvokeMatrixApi,upload_FilesOnly, getUserInfo, getChallenges, submitChallenge, UserCreaditStatus, FetchUserSettings } from "./WorkspaceUtils";
import ImageFormat from "../components/workspace/settingBar/ImageSettings/ImageFormat";
import sourceUrl from "../api/Source.api";
import axios from "axios";
import { fetchAvatars, fetchBackgrounds } from "../components/workspace/settingBar/synthesiaSettings/videoSettingsAPI";

const WorkspaceContext = createContext();
export const useWorkspace = () => useContext(WorkspaceContext);

const WorkspaceProvider = ({ children }) => {
  // threadHistory
  const [threads, setThreads] = useState([]);
  // [{ sender: enum("modalX", "user"), content: content, file(optional):{url: url, urlStatus: enum("processing", "processed")} }, ...]
  const [currentThread, setCurrentThread] = useState([]);
  // boolean to check if the thread is waiting for a response
  const [waitThread, setWaitThread] = useState(false);
  // threadSettings {synthesiaSettings: {}, synthesiaAvatar: {}, synthesiaVoice: {}}
  const [threadSettings, setThreadSettings] = useState({});
  // [{ type:enum("link", "file"), name: string, file(if type file): File, link(if type link):weblink, status: enum("uploading", "uploaded", "upload failed"), fileId: string }, ...]
  const [threadFiles, setThreadFiles] = useState([]);
  // "string" Current thread ID for sending messages
  const [threadId, setThreadId] = useState(null);
  const [currentHistoryId,setCurrentHistoryId] = useState(null);
  const [editClick,setEditClick] = useState(false);
  const [input, setInput] = useState("");
  const stopMessageIdRef = useRef([]);
  const [stopMessageIDRef,setStopMessageIDRef] = useState([]);
  const [currentStopGenerationMessageId, setCurrentStopGenerationMessageId] = useState(null);
  const [visibleStopGenerationButton, setVisibleStopGenerationButton] = useState(false)

  // Image Settings starts==============================
  const [imageFormat,setImageFormat] = useState('png');
  const [imageModel,setImageModel] = useState('3d-model');
  const [imageRatio,setImageRatio] = useState('1:1');
  // Image settings ends =================================

  // ====================Video settings starts ============================
  const [selectedVideoGenerationPlatform,setSelectedVideoGenerationPlatform] = useState("voice-over");
  const [videoAvatar,setVideoAvatar] = useState(null)
  const [videoBackground,setVideoBackground] = useState(null);
  const [videoVoice,setVideoVoice] = useState(null) ;
  const [videoLogo,setVideoLogo] = useState(null);
  const [videoAvatarLanguage,setVideoAvatarLanguage] = useState(null);
  // ====================Video settings ends ============================

  // ==================== Audio settings starts ============================
  const [audioLanguage,setAudioLanguage] = useState(null);
  const [audioVoice,setAudioVoice] = useState(null);
  const [audioVoiceObject,setAudioVoiceObject] = useState(null);
  // ==================== Audio settings ends ==============================

  const [sending,setSending]  = useState(false);

  const [templateId, setTemplateId] = useState(1);

  const [historyFiles,setHistoryFiles] = useState([]);

  const [Today,setToday] = useState([]);
  const [Yesterday,setYesterday] = useState([]);
  const [ThisWeek,setThisWeek] = useState([]);
  const [ThisMonth,setThisMonth] = useState([]);
  const [PrevMonth,setPrevMonth] = useState([]);

  const [showLeftsideBar,setShowLeftsideBar] = useState(true);
  const [showRightsideBar,setShowRightsideBar] = useState(true);

  const [showFirstVisitModal, setShowFirstVisitModal] = useState(false);
  const [showVideoSettings,setShowVideoSettings] = useState(false);
  const [showImageSettings,setShowImageSettings] = useState(false);
  const [showPptSettings,setShowPptSettings] = useState(false);
  const [showAudioSettings,setShowAudioSettings] = useState(false);
  const [subscribe, setSubscribe] = useState(null);
  const [subscribeType, setSubscribeType] = useState(null);
  const [shareModalforSocialMedia ,  setShareModalforSocialMedia] = useState(false)
 

  const [suggestedPrompt,setSuggestedPrompt] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);
  const [showSettingBar, setShowSettingBar] = useState(true);
  const [showSuggested , setShowSuggested] = useState(true)
  const [languages, setLanguages] = useState([]);
  const [voices, setVoices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [backgrounds, setBackgrounds] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [avatars, setAvatars] = useState([]);
  const [avatarsloading, setAvatarsLoading] = useState(true);
  const [voiceloading, setVoiceLoading] = useState(true);
  const [Audiolanguages, setAudioLanguages] = useState([]);
  const [audioSettingVoices, setAudioSettingVoices] = useState([]);
  const [audioSettingVoicesloading, setAudioSettingVoicesLoading] = useState(true);
  const [audioSettinglanguagesloading, setAudioSettingLanguagesLoading] = useState(true);
  const [openWebsiteTrackModal, setOpenWebsiteTrackModal] = useState(false);
  const [freshUser, setFreshUser] = useState('loading');
  const [onBoardingData,setOnBoardingData] = useState(null);

  // Web tracking

  const [iswebTrackClicked,setIswebTrackClicked] = useState(false);
  const [selectedWeb,setSelectedWeb] = useState("");
  const [reloadForSummary, setReloadForSummary] = useState(true);
  const [currentWebsiteLinkedId, setCurrentWebsiteLinkedId] = useState(null);
  const [selectedOneClickButtonFromWebtrackNotificationMsg,setSelectedOneClickButtonFromWebtrackNotificationMsg] = useState('');
  const [notificationTextFromWebTracking,setNotificationTextFromWebTracking] = useState('');
  const [webTrackingLoading,setWebTrackingLoading] = useState(false);
  const [currentWebs,setCurrentWebs] = useState([]);
  const [refetchClicked,setRefetchClicked] = useState(false);
  const [webTrackingWebsiteLoading,setWebTrackingWebsiteLoading] = useState(false);

  // ====================VideoGen(Video With Caption) settings starts ============================

  const [genVideoAudioSettingsLoading, setGenVideoAudioSettingsLoading] = useState(true);
  const [ genVideoSettingVoices, setGenVideoSettingVoices] = useState([])
  const [ genVideoLanguage, setGenVideoLanguage] = useState([])
  const [selectedGenVideoLanguage, setSelectedGenVideoLanguage] = useState(null)
  const [ genVideoVoice, setGenVideoVoice] = useState(null)
  const [ genVideoVoicesLoading, setGenVideoVoicesLoading] = useState(true)
  const [ selectedGenVideoVoice, setSelectedGenVideoVoice] = useState(null)
  const [ minDimensionPixels, setMinDImensionPixels] = useState(null)
  // const [selectCaptionFontSizeForVideoGen, setSelectCaptionFontSizeForVideoGen] = useState(null);
  // const [selectCaptionFontWeightForVideoGen, setSelectCaptionFontWeightForVideoGen] =useState(null);
  const [selectedAspectRatioForVideoGen, setSelectedAspectRatioForVideoGen] = useState(null);
  const [selectedaspectRatioForVideoGen, setSelectedaspectRatioForVideoGen] = useState(null);
  const [selectedHeightForVideoGen, setSelectedHeightForVideoGen] = useState(null);
  const [selectedWidthForVideoGen, setSelectedWidthForVideoGen] = useState(null);
  const [selectedCaptionFontForVideoGen, setSelectedCaptionFontForVideoGen] = useState("Inter");
  const [CaptionFontForVideoGen, setCaptionFontForVideoGen] = useState(null);
  const [showCaption, setShowCaption] = useState(true);
  const [captionTextColor, setCaptionTextColor] = useState({ r: 255, g: 255, b: 255, a: 1 });
  const [captionBackgoundColor, setCaptionBackgoundColor] = useState({r: 0,g: 0,b: 0,a: 0.5,});
  const [selectedColorForCaptionText, setSeletedColorForCaptionText] = useState("#ffffff");
  const [selectedColorForCaptionTextBackground,setSelectedColorForCaptionTextBackground] = useState("FFFFFF");
  const [isBoldForCaption, setIsBoldForCaption] = useState(false);
  const [selectedAlignmentForCaption, setSelectedAlignmentForCaption] = useState("center");
  const [selectedVarticalAlignmentForCaption, setSelectedVarticalAlignmentForCaption] = useState("BOTTOM");
  const [captionFontSize, setCaptionFontSize] = useState("50");

  // ====================VideoGen(Video With Caption) settings end ============================

  // const [hasLink, setHasLink] = useState(false);
  // const [arrayForLinksAvalible, setArrayForLinksAvalible] = useState([]);
  const [updateWebList,setUpdateWebList] = useState(false);
  const [userInfo, setUserInfo] = useState(null)
  const [showTutorialModal, setShowTutorialModal] = useState(false)
  const [userMediaSettings, setUserMediaSettings] = useState(null)
  const[allVoiceforSettingShow, setAllVoiceforSettingShow] = useState(null)
  const[autoTypingInPromptCompleted, setAutoTypingInPromptCompleted] = useState(null)

  // useEffect(() => {
  //   // Check if the user has already visited
  //   const hasVisited = localStorage.getItem("hasVisitedWorkspace");

  //   if (!hasVisited) {
  //     // If not, show the modal
  //     setShowFirstVisitModal(true);
  //     // Mark as visited
  //     localStorage.setItem("hasVisitedWorkspace", "true");
  //   }
  // }, []);

  useEffect(() => {
    const fetchOnboardingStatus = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${sourceUrl}/user/onboardingStatus`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            // 'User-Agent': 'Mozilla/5.0',
          },
        });

        const { status } = response?.data?.data;

        // Update modal and hasVisitedWorkspace based on status
        if (status) {
          setShowFirstVisitModal(true); // If status is true, show video settings modal
        }
        setFreshUser(!status);
        setOnBoardingData(response?.data?.data);
        localStorage.setItem("hasVisitedWorkspace", status ? "true" : "false");
      } catch (error) {
        setFreshUser(true);
        console.error("Error fetching onboarding status:", error);
      }
    };

    fetchOnboardingStatus();
  }, []);

  useEffect( ()=>{
    const isLoggedIn = localStorage.getItem('isLoggedIn');
  async function getSubscriptionStatus(){
      const res = await UserCreaditStatus(setSubscribe, setSubscribeType)
    }
    if(isLoggedIn){
      getSubscriptionStatus()
    }
  },[subscribe])

  useEffect( ()=>{
    const token = localStorage.getItem('token');
  async function fetchUserInfo(){
    if(token){
      if(userInfo){
        await getUserInfo(setUserInfo)
      }
      if(backgrounds){
        await fetchBackgrounds(setBackgrounds)
      }
      if(avatars){
        await fetchAvatars(setAvatars, setAvatarsLoading)
      }
      }
   }
   fetchUserInfo()
  },[])

  useEffect(()=>{
    const token = localStorage.getItem('token');
    async function FetchUserAllSettings(){
      if(token){
       const All_Settings = await FetchUserSettings()
      //  console.log(All_Settings, "All Settings");
       if(All_Settings){
        setUserMediaSettings(All_Settings)
       }else{
        setUserMediaSettings(null)
       }
      }
     }
     FetchUserAllSettings()
  },[])

  const clearThread = () => {
    // Clear current thread
    setCurrentThread([]);
    // clear thread id
    setThreadId(null);
    // clear thread settings
    setThreadSettings({});
    // clear thread files
    setThreadFiles([]);
    // clear history files
    setHistoryFiles([]);
    // clear current history id
    setCurrentHistoryId(null);
  };
  

  const value = {
    threads,
    setThreads,
    currentThread,
    setCurrentThread,
    threadSettings,
    setThreadSettings,
    threadFiles,
    setThreadFiles,
    threadId,
    setThreadId,
    currentHistoryId,
    setCurrentHistoryId,
    waitThread,
    setWaitThread,
    clearThread,
    templateId,
    setTemplateId,
    sending,
    setSending,
    historyFiles,
    setHistoryFiles,
    Today,
    setToday,
    Yesterday,
    setYesterday,
    ThisWeek,
    setThisWeek,
    ThisMonth,
    setThisMonth,
    PrevMonth,
    setPrevMonth,
    showLeftsideBar,
    setShowLeftsideBar,
    showRightsideBar,
    setShowRightsideBar,
    imageFormat,
    setImageFormat,
    imageModel,
    setImageModel,
    imageRatio,
    setImageRatio,
    selectedVideoGenerationPlatform,
    setSelectedVideoGenerationPlatform,
    videoAvatar,
    setVideoAvatar,
    videoBackground,
    setVideoBackground,
    videoVoice,
    setVideoVoice,
    videoAvatarLanguage,
    setVideoAvatarLanguage,
    audioLanguage,
    setAudioLanguage,
    audioVoice,
    setAudioVoice,
    showFirstVisitModal,
    setShowFirstVisitModal,
    showVideoSettings,
    setShowVideoSettings,
    showImageSettings,
    setShowImageSettings,
    showPptSettings,
    setShowPptSettings,
    showAudioSettings,
    setShowAudioSettings,
    shareModalforSocialMedia,
    setShareModalforSocialMedia,
    editClick,
    setEditClick,
    suggestedPrompt,
    setSuggestedPrompt,
    showSidebar,
     setShowSidebar,
     showSettingBar,
    setShowSettingBar,
    showSuggested ,
     setShowSuggested,
     iswebTrackClicked,
     setIswebTrackClicked,
     selectedWeb,
     setSelectedWeb,
     selectedOneClickButtonFromWebtrackNotificationMsg,
     setSelectedOneClickButtonFromWebtrackNotificationMsg,
     notificationTextFromWebTracking,
     setNotificationTextFromWebTracking,
     webTrackingLoading,
     setWebTrackingLoading,
     webTrackingWebsiteLoading,
     setWebTrackingWebsiteLoading,
    sendMessage: async (latestEntry,onboardingData) =>
      await sendMessage(
        latestEntry,
        threadId,
        threadFiles,
        threadSettings,
        setThreadId,
        templateId,
        historyFiles,
        imageFormat,
        imageModel,
        imageRatio,
        videoAvatar,
        videoBackground,
        videoVoice,
        videoAvatarLanguage,
        audioLanguage,
        audioVoice,
        onboardingData,
        selectedGenVideoVoice,
        selectedGenVideoLanguage,
        minDimensionPixels,
        selectedHeightForVideoGen,
        selectedWidthForVideoGen,
        // selectCaptionFontSizeForVideoGen,
        // selectCaptionFontWeightForVideoGen,
        isBoldForCaption,
        showCaption,
        captionBackgoundColor,
        captionTextColor,
        selectedAlignmentForCaption,
        selectedVarticalAlignmentForCaption,
        selectedCaptionFontForVideoGen,
        captionFontSize,
        selectedVideoGenerationPlatform,
        setCurrentStopGenerationMessageId
      ),
    submitChallenge: async (latestEntry,onboardingData) =>
      await submitChallenge(
        latestEntry,
        threadId,
        threadFiles,
        threadSettings,
        setThreadId,
        templateId,
        historyFiles,
        imageFormat,
        imageModel,
        imageRatio,
        videoAvatar,
        videoBackground,
        videoVoice,
        videoAvatarLanguage,
        audioLanguage,
        audioVoice,
        onboardingData,
        selectedGenVideoVoice,
        selectedGenVideoLanguage,
        minDimensionPixels,
        selectedHeightForVideoGen,
        selectedWidthForVideoGen,
        // selectCaptionFontSizeForVideoGen,
        // selectCaptionFontWeightForVideoGen,
        isBoldForCaption,
        showCaption,
        captionBackgoundColor,
        captionTextColor,
        selectedAlignmentForCaption,
        selectedVarticalAlignmentForCaption,
        selectedCaptionFontForVideoGen,
        captionFontSize,
        selectedVideoGenerationPlatform
      ),
    getChallenges: async (latestEntry,isValidDetails,onboardingData) => 
      await getChallenges(
        latestEntry,
        isValidDetails,
        onboardingData,
        threadId,
        setThreadId
      ),
    uploadFiles: async (fileObj) =>
      await uploadFiles(fileObj, threadId, setThreadId),
    upload_FilesOnly: async (fileObj) =>
      await upload_FilesOnly(fileObj, threadId, setThreadId),
    checkUrlStatus: async (url) => await checkUrlStatus(url, threadId),
    InvokeMatrixApi: async (jsonRespData,respData,reqType,endPoint,reqBody,emailId) => 
     await InvokeMatrixApi(
      jsonRespData,
      respData,
      reqType,
      threadId,
      endPoint,
      reqBody,
      emailId
     ),
     subscribe,
     setSubscribe,
     languages,
     setLanguages,
     voices,
     setVoices,
     categories,
     setCategories,
     backgrounds,
     setBackgrounds,
     selectedCategory,
     setSelectedCategory,
     avatars,
     setAvatars,
     avatarsloading,
     setAvatarsLoading,
     voiceloading,
     setVoiceLoading,
     Audiolanguages,
    setAudioLanguages,
    audioSettingVoices,
    setAudioSettingVoices,
    audioSettingVoicesloading,
    setAudioSettingVoicesLoading,
    audioSettinglanguagesloading,
    setAudioSettingLanguagesLoading,
    openWebsiteTrackModal,
    setOpenWebsiteTrackModal,
    freshUser,
    setFreshUser,
    onBoardingData,
    setOnBoardingData,
    reloadForSummary, setReloadForSummary,
    currentWebsiteLinkedId, setCurrentWebsiteLinkedId,
    genVideoAudioSettingsLoading, setGenVideoAudioSettingsLoading,
    genVideoSettingVoices, setGenVideoSettingVoices, 
    genVideoLanguage, setGenVideoLanguage,
    selectedGenVideoLanguage, setSelectedGenVideoLanguage,
    genVideoVoice, setGenVideoVoice,
    genVideoVoicesLoading, setGenVideoVoicesLoading,
    selectedGenVideoVoice, setSelectedGenVideoVoice,
    minDimensionPixels, setMinDImensionPixels,
    // selectCaptionFontSizeForVideoGen, setSelectCaptionFontSizeForVideoGen,
    // selectCaptionFontWeightForVideoGen, setSelectCaptionFontWeightForVideoGen,
    selectedAspectRatioForVideoGen, setSelectedAspectRatioForVideoGen,
    selectedaspectRatioForVideoGen, setSelectedaspectRatioForVideoGen,
    selectedHeightForVideoGen, setSelectedHeightForVideoGen,
    selectedWidthForVideoGen, setSelectedWidthForVideoGen,
    selectedCaptionFontForVideoGen, setSelectedCaptionFontForVideoGen,
    CaptionFontForVideoGen, setCaptionFontForVideoGen,
    showCaption, setShowCaption,
    captionTextColor, setCaptionTextColor,
    selectedColorForCaptionText, setSeletedColorForCaptionText,
    selectedColorForCaptionTextBackground,setSelectedColorForCaptionTextBackground,
    isBoldForCaption, setIsBoldForCaption,
    selectedAlignmentForCaption, setSelectedAlignmentForCaption,
    selectedVarticalAlignmentForCaption, setSelectedVarticalAlignmentForCaption,
    captionBackgoundColor, setCaptionBackgoundColor,
    captionFontSize, setCaptionFontSize,
    // hasLink, setHasLink,
    // arrayForLinksAvalible, setArrayForLinksAvalible,
    audioVoiceObject,setAudioVoiceObject,
    updateWebList,setUpdateWebList,
    userInfo, setUserInfo,
    currentWebs,setCurrentWebs,
    refetchClicked,setRefetchClicked,
    input, setInput,
    stopMessageIdRef,
    stopMessageIDRef,
    setStopMessageIDRef,
    currentStopGenerationMessageId, setCurrentStopGenerationMessageId,
    visibleStopGenerationButton, setVisibleStopGenerationButton,
    subscribeType, setSubscribeType,
    showTutorialModal, setShowTutorialModal,
    userMediaSettings, setUserMediaSettings,
    allVoiceforSettingShow, setAllVoiceforSettingShow,
    autoTypingInPromptCompleted, setAutoTypingInPromptCompleted,
  };
  
  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceProvider;
