// import { useUI } from "../contexts/UIContext";
// // import ModalXDarkSVG from "../assets/modalX_Dark.svg";
// // import ModalXLightSVG from "../assets/modalX_Light.svg";

// const GetModalX_FlowDiagram = () => {
//   const { currentTheme } = useUI(); // Get the current theme from context
//   // const ModalXLightSVG = require("../assets/ModalX_-_Black_Gradient-removebg.png")
//   const ModalXLightSVG = require("../assets/ModalX_WorkFlowDiagram.png")
//   const ModalXDarkSVG = require("../assets/Modal_X_-_B_W-removebg.png")
//   const ModalxWorkFlowDiagram_900 = require("../assets/ModalX_WorkFlowDiagram(900).png");
//   // Determine the SVG source based on the current theme
//   const svgSrc = currentTheme === "light" ? ModalXDarkSVG : ModalXLightSVG;

//   return svgSrc;
// };

// export default GetModalX_FlowDiagram;


import React, { useState, useEffect, useRef } from "react";
import { useUI } from "../contexts/UIContext";
import { useMediaQuery } from "react-responsive";
import { Box, Typography, CardMedia } from "@mui/material";
import modalxIcon from "../assets/updatedModalxLogo.png";
import { useWorkspace } from "../contexts/WorkspaceContext";
import { trackSearchInteraction } from "../config/analytics";
import Suggested from "./workspace/mobileResponsive/Suggested";
import NewProjectUI from "./NewProjectUI";

const GetModalX_FlowDiagram = () => {

  // const isXsOrSm = useMediaQuery({ maxHeight: 1000 });
  // const { currentTheme } = useUI();
  // const [svgSrc, setSvgSrc] = useState('');

  // const ModalXLightSVG = require("../assets/ModalX_WorkFlowDiagram.png");
  // const ModalXDarkSVG = require("../assets/Modal_X_-_B_W-removebg.png");
  // const ModalxWorkFlowDiagram_900 = require("../assets/ModalX_WorkFlowDiagram(900).png");

  // useEffect(() => {
  //   if (isXsOrSm) {
  //     setSvgSrc(currentTheme === "light" ? ModalXDarkSVG : ModalXLightSVG);
  //   } else {
  //     setSvgSrc(currentTheme === "light" ? ModalXDarkSVG : ModalXLightSVG);
  //   }
  // }, [isXsOrSm, currentTheme]);

  // return <img src={svgSrc} alt="Modal Diagram" style={{ maxWidth: '609px', height: 'auto',maxHeight:'700px'}} />;
  // const currentData = [
  //   {
  //     id: 1,
  //     title: "Generate an explainer video for the feature",
  //     image: image,
  //     click: () => regenerateResponse(datas[0].title),
  //   },
  //   {
  //     id: 2,
  //     title: "Create performance creatives for social media posts",
  //     image: video,
  //     click: () => regenerateResponse(datas[1].title),
  //   },
  //   {
  //     id: 3,
  //     title: "Create carousel slides about the feature for Linkedin",
  //     image: presentation,
  //     click: () => regenerateResponse(datas[2].title),
  //   },
  //   {
  //     id: 4,
  //     title: "Create an audio ad for Spotify highlighting offers",
  //     image: Music,
  //     click: () => regenerateResponse(datas[3].title),
  //   },
    // {
    //   id: 5,
    //   title:"Find the fastest growing product categories in ShareWise’s market",
    //   image: search,
    //   click: () => regenerateResponse(datas[4].title),
    // },
    // {
    //   id: 6,
    //   title: "Summarize ShareWise’s quarterly report in a presentation",
    //   image: presentation,
    //   click: () => regenerateResponse(datas[5].title),
    // },
  // ]
  // const [currentIndex, setCurrentIndex] = useState(0);
  // const [datas, setDatas] = useState(currentData);
  // const [isDragging, setIsDragging] = useState(false);
  // const [startX, setStartX] = useState(0);
  // const [offsetX, setOffsetX] = useState(0);
  // const sliderRef = useRef(null);
  // const {
  //   setSending,
  //   setWaitThread,
  //   setCurrentThread,
  //   sendMessage,
  //   currentThread,
  //   setShowCreditRecharge,
  //   showSuggested,
  //   setShowSuggested,
  // } = useWorkspace();

 

  // const findUrl = (msg) => {
  //   const urlRegex =
  //     /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
  //   const match = urlRegex.exec(msg);
  //   return match ? match[1] : null;
  // };

  // const getFileFormat = (url) => {
  //   const parts = url.split(".");
  //   if (parts.length > 1) {
  //     return parts[parts.length - 1];
  //   } else {
  //     return "";
  //   }
  // };

  // const handleSubmit = async (msg) => {
  //   const input = msg;
  //   trackSearchInteraction(input);
  //   setSending(true);
  //   setWaitThread(true);
  //   if (input.trim()) {
  //     const latestEntry = { sender: "user", content: input };
  //     setCurrentThread((prevThread) => [...prevThread, latestEntry]);
  //     const entryResponse = await sendMessage(latestEntry).catch(console.error);
  //     if (entryResponse) {
  //       if (
  //         entryResponse &&
  //         entryResponse.content &&
  //         findUrl(entryResponse.content) &&
  //         findUrl(entryResponse.content).includes("dailylimitexceeded")
  //       ) {
  //         // Determine the file format from the URL (assuming findUrl() returns the URL)
  //         const fileFormat = getFileFormat(findUrl(entryResponse.content));

  //         // Prepare the response message
  //         const responseMessage = {
  //           content:
  //             `Hi there,\n\nIt looks like you’ve reached your daily limit for generating ` +
  //             fileFormat +
  //             ` files. We’re really sorry about that! We know how important uninterrupted access is for you.\n\nTo keep enjoying all the great benefits and have a seamless experience, we’d love for you to consider upgrading to our premium plan.\n\nThank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.\n\nWarm regards,\n\nThe ModalX Team`,
  //           sender: "modalX",
  //         };

  //         setCurrentThread((prevThread) => [...prevThread, responseMessage]);
  //         setShowCreditRecharge(true);
  //       } else {
  //         setCurrentThread((prevThread) => [...prevThread, entryResponse]);
  //       }
  //       setSending(false);
  //     } else {
  //       setCurrentThread((prevThread) => [
  //         ...prevThread,
  //         { content: "Unable to process your query!..", sender: "modalX" },
  //       ]);
  //       setSending(false);
  //     }
  //   }
  //   setWaitThread(false);
  // };

  // const regenerateResponse = (title) => {
  //   setShowSuggested(false);
  //   handleSubmit(title);
  // };

  const isBelow991 = useMediaQuery({ maxWidth: 991 });
  const isHeightAbove800 = useMediaQuery({ minHeight: 800 });
  const userEmail = localStorage.getItem("userEmail");
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  // const visibleSlides = 2;

  // const handleStart = (e) => {
  //   setIsDragging(true);
  //   const clientX = e.type === "touchstart" ? e.touches[0].clientX : e.clientX;
  //   setStartX(clientX);
  //   setOffsetX(0);
  // };

  // const handleMove = (e) => {
  //   if (!isDragging) return;
  //   const clientX = e.type === "touchmove" ? e.touches[0].clientX : e.clientX;
  //   const moveX = clientX - startX;
  //   setOffsetX(moveX);
  // };

  // const handleEnd = () => {
  //   if (!isDragging) return;

  //   setIsDragging(false);

  //   if (offsetX > 100 && currentIndex > 0) {
  //     setCurrentIndex(currentIndex - 1);
  //   } else if (offsetX < -100 && currentIndex < datas.length - visibleSlides) {
  //     setCurrentIndex(currentIndex + 1);
  //   }

  //   setOffsetX(0);
  // };

  // Listen for touch and mouse events globally when dragging starts
  // useEffect(() => {
  //   if (isDragging) {
  //     // Add event listeners for both mouse and touch
  //     window.addEventListener("mousemove", handleMove);
  //     window.addEventListener("mouseup", handleEnd);
  //     window.addEventListener("touchmove", handleMove);
  //     window.addEventListener("touchend", handleEnd);
  //   } else {
  //     // Remove the event listeners when dragging stops
  //     window.removeEventListener("mousemove", handleMove);
  //     window.removeEventListener("mouseup", handleEnd);
  //     window.removeEventListener("touchmove", handleMove);
  //     window.removeEventListener("touchend", handleEnd);
  //   }

  //   // Cleanup event listeners on component unmount
  //   return () => {
  //     window.removeEventListener("mousemove", handleMove);
  //     window.removeEventListener("mouseup", handleEnd);
  //     window.removeEventListener("touchmove", handleMove);
  //     window.removeEventListener("touchend", handleEnd);
  //   };
  // }, [isDragging, offsetX]);

  function removeAfterAtSymbol(userEmail) {
    return userEmail?.split('@')[0];
  }

  const { userInfo } = useWorkspace();

  const name = removeAfterAtSymbol(userEmail);

  return (
    <>
      <Box sx={{ margin:"0px", padding:"0px", marginTop: isBelow991 ? "45px" : "35px" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: isBelow991 ? "column" :"row",
            justifyContent: "center",
            alignItems:"center",
            columnGap: "24px",
            rowGap: isBelow991 && "12px",
            overflowY: 'auto'
          }}
        >
          <Box
            sx={{
              width: isBelow991 ? "60px" : "64px",
              height: isBelow991 ? "60px" : "64px",
            }}
          >
            <CardMedia component="img" image={modalxIcon} alt="loading" />
          </Box>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: isBelow991 ? "20px" : "28px" ,
              fontWeight: 600,
              textAlign: "center",
              // "@media (min-height: 750px) and (max-height: 800px)": {
              //   marginTop: "26px",
              // },
              // "@media (min-height: 700px) and (max-height: 749px)": {
              //   marginTop: "16px",
              // },
              // "@media (min-height: 600px) and (max-height: 700px)": {
              //   marginTop: "6px",
              // },
            }}
          >
          {isBelow991 ? (
                          <>
                            {isLoggedIn ? `Hi ${userInfo?.firstName || name}, ` : 'Hi ,'}
                            <br />
                            {"how can I assist you today?"}
                          </>
                        ) : (<>
                                {isLoggedIn? `Hi ${userInfo?.firstName ? userInfo.firstName : name}, how can I assist you today?` :`Hi , how can I assist you today?`}
                             </>
                        )
          }
          </Typography>
        </Box>
        {/* <Suggested/> */}
        <NewProjectUI/>
      </Box>

      
    </>
  );
};

export default GetModalX_FlowDiagram;

