import React, { useEffect, useState, useCallback } from "react";
import sourceUrl from "../../api/Source.api";
import EnableSubscription from "./EnableSubscription";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  Tooltip,
  Typography,
  Dialog,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { green } from "@mui/material/colors";
import { AddAndDeleteFree } from "../../contexts/WorkspaceUtils";

function Subscription({setError}) {
  const [data, setData] = useState(null);
  const [searchTermActivated, setSearchTermActivated] = useState("");
  const [searchTermFullAccess, setSearchTermFullAccess] = useState("");
  const [filteredActivatedUsers, setFilteredActivatedUsers] = useState([]);
  const [filteredFullAccessUsers, setFilteredFullAccessUsers] = useState([]);
  const token = localStorage.getItem("token");
  const [hoveredManually, setHoveredManually] = useState(null);
  const [hoveredFullAcess, setHoveredFullAcess] = useState(null);
  const [deteteEmailManually, setDeteteEmailManually] = useState(false);
  const [deteteEmailFullAccess, setdDteteEmailFullAccess] = useState(false);
  const [deteteEmailId, setDeleteEmailId] = useState("");
  // Fetch Subscription Data
  // const fetchSubscriptionData = async () => {
  //   try {
  //     const response = await fetch(`${sourceUrl}/admin/subscription`, {
  //       method: 'GET',
  //       headers: {
  //         accept: 'application/json, text/plain, */*',
  //         authorization: `Bearer ${token}`,
  //       },
  //     });

  //     const result = await response.json();
  //     setData(result);
  //     setFilteredActivatedUsers(result.data.manuallyActivatedUsers);
  //     setFilteredFullAccessUsers(result.data.fullAccessRequired);
  //   } catch (error) {
  //     console.error('Failed to fetch subscription data:', error);
  //   }
  // };

  const fetchSubscriptionData = async () => {
    try {
      const response = await fetch(`${sourceUrl}/admin/subscription`, {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      if (result?.data) {
        setData(result);
        setFilteredActivatedUsers(result.data.manuallyActivatedUsers || []);
        setFilteredFullAccessUsers(result.data.fullAccessRequired || []);
      } else {
        console.warn("Response does not contain expected data:", result);
      }
    } catch (error) {
      console.error("Failed to fetch subscription data:", error);
    }
  };

  useEffect(() => {
    fetchSubscriptionData();
  }, []);

  // Debounced Search Functionality
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const handleSearchActivated = useCallback(
    debounce((term) => {
      if (!data) return;

      const { manuallyActivatedUsers } = data.data;

      // Filter manually activated users based on the search term
      setFilteredActivatedUsers(
        manuallyActivatedUsers.filter((user) =>
          user.toLowerCase().includes(term.toLowerCase())
        )
      );
    }, 300),
    [data]
  );

  const handleSearchFullAccess = useCallback(
    debounce((term) => {
      if (!data) return;

      const { fullAccessRequired } = data.data;

      // Filter full access required users based on the search term
      setFilteredFullAccessUsers(
        fullAccessRequired.filter((user) =>
          user.toLowerCase().includes(term.toLowerCase())
        )
      );
    }, 300),
    [data]
  );

  const handleActivatedSearchChange = (e) => {
    const value = e.target.value;
    setSearchTermActivated(value);
    handleSearchActivated(value);
  };

  const handleFullAccessSearchChange = (e) => {
    const value = e.target.value;
    setSearchTermFullAccess(value);
    handleSearchFullAccess(value);
  };

  const handleDeleteManuallyShowModal = (user) => {
    setDeleteEmailId(user)
    setDeteteEmailManually(true);
  };

  const handelCloseDeleteManuallyModal =async () => {
    const response =await AddAndDeleteFree(deteteEmailId, false)
    if(response.status){
      fetchSubscriptionData()
    }
    setDeteteEmailManually(false);
  };

  const handleDeleteFullAccessShowModal =async (user) =>{
    setDeleteEmailId(user)
    setdDteteEmailFullAccess(true)

  }

  const handleCloseFullAccessShowModal =async () =>{
    const response =await AddAndDeleteFree(deteteEmailId, false)
    if(response.status){
      fetchSubscriptionData()
    }else{
      console.error('Failed to delete user:', response)
      setError()
    }
    setdDteteEmailFullAccess(false)
  }

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div style={{ marginBottom: "50px" }}>
        <EnableSubscription fetchSubscriptionData={fetchSubscriptionData} />
      </div>

      {/* Search Inputs */}
      <div style={{ marginBottom: "20px" }}></div>

      {/* Display Filtered Users */}
      <div style={{ display: "flex", gap: "100px" }}>
        <div>
          <h3>Exclusive Access for Existing Users</h3>
          <input
            type="text"
            placeholder="Search Manually Activated Users..."
            value={searchTermActivated}
            onChange={handleActivatedSearchChange}
            style={{
              padding: "10px",
              minWidth: "300px",
              marginBottom: "10px",
              height: "40px",
              borderRadius: "8px",
              border: "none",
              display: "block",
            }}
          />
          <ul
            className="custom-scrollbar"
            style={{
              backgroundColor: "",
              height: "300px",
              overflowY: "scroll",
              marginTop: "20px",
              marginLeft: "-20px",
              width: "400px",
            }}
          >
            {/* Custom scrollbar styles */}
            <style>
              {`
                /* Custom scrollbar styles for the container */
                .custom-scrollbar::-webkit-scrollbar {
                    width: 10px;
                }

                .custom-scrollbar::-webkit-scrollbar-thumb {
                    background-color: rgba(255, 255, 255, 0.2);
                    border-radius: 5px;
                    height: 60px;
                }

                /* Optionally, hide scrollbar in Firefox */
                .custom-scrollbar {
                    scrollbar-width: thin; /* For Firefox */
                    scrollbar-color: rgba(255, 255, 255, 0.2) transparent; /* Thumb color and track color */
                }
                `}
            </style>
            {filteredActivatedUsers.map((user, index) => (
              <ListItem
                key={index}
                onMouseEnter={() => setHoveredManually(index)}
                onMouseLeave={() => setHoveredManually(null)}
                sx={{
                  padding: "5px 10px",
                  marginBottom: "10px",
                  backgroundColor: hoveredManually === index ? "#282D51" : "",
                  transition: "background-color 0.3s ease",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "#282D51",
                  },
                }}
              >
                <ListItemText primary={`${index + 1}. ${user}`} />

                {/* Render buttons only on hover */}
                {hoveredManually === index && (
                  <Box>
                    <Box onClick={()=>handleDeleteManuallyShowModal(user)}>
                      <DeleteIcon />
                    </Box>
                    <Dialog
                      open={deteteEmailManually}
                      close={handelCloseDeleteManuallyModal}
                      PaperProps={{
                        sx: {
                          width: "100%", // Increase the width as needed
                          borderRadius: "8px", // Adjust if you want to change the border radius
                        },
                      }}
                    >
                      <Box
                        sx={{
                          maxWidth: "600px",
                          // height: "200px",
                          borderRadius: "8px",
                          border: "1px solid rgba(255, 255, 255, 0.2)",
                          bgcolor: "#171A2D",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            padding: "0px 20px 0px 20px",
                            display: "flex",
                            justifyContent: "center",
                            margin: "auto",
                            marginTop: "20px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Inter",
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "20px",
                              textAlign: "left",
                              color: "#FFFFFF",
                            }}
                          >
                            Are you sure to delete the access of{" "}
                            <Typography component="span">{user}</Typography>
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            marginTop: "20px",
                            width: "100%",
                            height: "72px",
                            display: "flex",
                            justifyContent: "right",
                          }}
                        >
                          <Box
                            sx={{
                              width: "226px",
                              height: "40px",
                              marginRight: "16px",
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button
                              onClick={()=>{ setDeteteEmailManually(false);}}
                              sx={{
                                width: "103px",
                                height: "40px",
                                textTransform: "none",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: 600,
                                textAlign: "center",
                                bgcolor: "#FFFFFF",
                                color: "#17182C",
                                "&:hover": {
                                  backgroundColor: "#CDCFDF",
                                },
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={handelCloseDeleteManuallyModal}
                              sx={{
                                width: "103px",
                                height: "40px",
                                textTransform: "none",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: 600,
                                textAlign: "center",
                                bgcolor: "#DD2025",
                                color: "#FFFFFF",
                                "&:hover": {
                                  backgroundColor: "#E6E0E91F",
                                },
                              }}
                            >
                              Delete
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Dialog>
                  </Box>
                )}
              </ListItem>
            ))}
          </ul>
        </div>

        <div>
          <h3>Exclusive Access for Unregistered</h3>
          <input
            type="text"
            placeholder="Search Full Access Required Users..."
            value={searchTermFullAccess}
            onChange={handleFullAccessSearchChange}
            style={{
              padding: "10px",
              minWidth: "300px",
              height: "40px",
              borderRadius: "8px",
              border: "none",
              display: "block",
            }}
          />
          <ul
            className="custom-scrollbar"
            style={{
              height: "300px",
              overflowY: "scroll",
              marginTop: "20px",
              marginLeft: "-20px",
              width: "400px",
            }}
          >
            {/* Custom scrollbar styles */}
            <style>
              {`
                /* Custom scrollbar styles for the container */
                .custom-scrollbar::-webkit-scrollbar {
                    width: 10px;
                }

                .custom-scrollbar::-webkit-scrollbar-thumb {
                    background-color: rgba(255, 255, 255, 0.2);
                    border-radius: 5px;
                    height: 60px;
                }

                /* Optionally, hide scrollbar in Firefox */
                .custom-scrollbar {
                    scrollbar-width: thin; /* For Firefox */
                    scrollbar-color: rgba(255, 255, 255, 0.2) transparent; /* Thumb color and track color */
                }
                `}
            </style>
            {filteredFullAccessUsers.map((user, index) => (
              <ListItem
                key={index}
                onMouseEnter={() => setHoveredFullAcess(index)}
                onMouseLeave={() => setHoveredFullAcess(null)}
                sx={{
                  padding: "5px 10px",
                  marginBottom: "10px",
                  backgroundColor: hoveredFullAcess === index ? "#282D51" : "",
                  transition: "background-color 0.3s ease",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "#282D51",
                  },
                }}
              >
                <ListItemText primary={`${index + 1}. ${user}`} />

                {/* Render buttons only on hover */}
                {hoveredFullAcess === index && (
                  <Box>
                    <Box onClick={()=>{handleDeleteFullAccessShowModal(user)}}>
                      <DeleteIcon />
                    </Box>
                    <Dialog
                      open={deteteEmailFullAccess}
                      close={handleCloseFullAccessShowModal}
                      PaperProps={{
                        sx: {
                          width: "100%", // Increase the width as needed
                          borderRadius: "8px", // Adjust if you want to change the border radius
                        },
                      }}
                    >
                      <Box
                        sx={{
                          maxWidth: "600px",
                          // height: "200px",
                          borderRadius: "8px",
                          border: "1px solid rgba(255, 255, 255, 0.2)",
                          bgcolor: "#171A2D",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            padding: "0px 20px 0px 20px",
                            display: "flex",
                            justifyContent: "center",
                            margin: "auto",
                            marginTop: "20px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Inter",
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "20px",
                              textAlign: "left",
                              color: "#FFFFFF",
                            }}
                          >
                            Are you sure to delete the access of{" "}
                            <Typography component="span">{user}</Typography>
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            marginTop: "20px",
                            width: "100%",
                            height: "72px",
                            display: "flex",
                            justifyContent: "right",
                          }}
                        >
                          <Box
                            sx={{
                              width: "226px",
                              height: "40px",
                              marginRight: "16px",
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button
                                 onClick={()=>{setdDteteEmailFullAccess(false)}}
                              sx={{
                                width: "103px",
                                height: "40px",
                                textTransform: "none",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: 600,
                                textAlign: "center",
                                bgcolor: "#FFFFFF",
                                color: "#17182C",
                                "&:hover": {
                                  backgroundColor: "#CDCFDF",
                                },
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={handleCloseFullAccessShowModal}
                              sx={{
                                width: "103px",
                                height: "40px",
                                textTransform: "none",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: 600,
                                textAlign: "center",
                                bgcolor: "#DD2025",
                                color: "#FFFFFF",
                                "&:hover": {
                                  backgroundColor: "#E6E0E91F",
                                },
                              }}
                            >
                              Delete
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Dialog>
                  </Box>
                )}
              </ListItem>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Subscription;
